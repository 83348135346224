
import React, { useState } from 'react'
import Cookies from 'js-cookie';
import { BiHelpCircle, BiSolidAddToQueue } from 'react-icons/bi';
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import "../css/helpcenter.css"
import { useEffect } from 'react';
import { message, Popover } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';



function Ticketsid() {
    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const userid = Cookies.get("id")
    const token = Cookies.get("Token")
    const [tickets, settickets] = useState([])
    const [reply, setreply] = useState("")
    const { id } = useParams();
    const navigate = useNavigate()

    useEffect(() => {
        if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
            const intervalId = setInterval(() => {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                const urlencoded = new URLSearchParams();
                urlencoded.append("_id", id);

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow"
                };

                fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallticketsbyid", requestOptions)
                    .then(response => response.json())
                    .then(result => settickets(result))
                    .catch(error => console.log('error', error));
            }, 1000); // Runs every 1 second

            return () => clearInterval(intervalId); // Cleanup on component unmount
        }
    }, [token, id]); // Dependencies array to rerun on token or id change



    return (
        <div>
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                <Navbar />
                <div className='deshboardmain'>
                    <Sidemanu />
                    <div className='adminkitmainbody'>
                        <div className='header'>
                            <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><BiHelpCircle style={{ width: '50px', height: '50px' }} />Help Center</h1>
                        </div>





                        {tickets.length !== 0 && <>
                            <div className='header' style={{ marginTop: '50px' }} >
                                <h2>Tickets Id : {tickets.ticketID}</h2>
                            </div>
                            <div className='questionbox2 newquestionbox2'>
                                <div className='questionbox211'>
                                    <h4 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center', margin: '0px', padding: '0px' }}>{tickets.priority}</h4>

                                </div>
                                <h4 className='newquestionbox2 marginbottom' style={{paddingBottom:"10px",  display: 'flex', alignItems: 'center', margin: '0px', padding: '0px' }}>name: {tickets.name}</h4>
                                <h4 className='newquestionbox2 marginbottom' style={{paddingBottom:"10px", display: 'flex', alignItems: 'center', margin: '0px', padding: '0px' }}>email: {tickets.email}</h4>
                                <h4 className='newquestionbox2 marginbottom' style={{paddingBottom:"10px", display: 'flex', alignItems: 'center', margin: '0px', padding: '0px' }}>Subject: {tickets.subject}</h4>
                                {tickets.attachment !== "nil" && <>    <h4 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center', margin: '0px', padding: '0px' }}><button className='button button-style' onClick={() => {
                                    const link = document.createElement('a');
                                    link.href = tickets.attachment;
                                    link.setAttribute('download', ``);
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                }}>Click Here to download media</button></h4></>}

                                <div style={{ marginTop: '50px' }}></div>
                                <h4 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center', margin: '0px', padding: '0px' }}>Message : </h4>
                                <p style={{ display: 'flex', textAlign: 'left' }}>

                                    <span dangerouslySetInnerHTML={{ __html: tickets.Message.replace(/\n/g, "<br />") }} />
                                </p>
                                <div style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>

                                    {tickets.status === "open" ? (<>
                                        <button className='button' style={{background:"#dcd2fc",borderRadius:"14px",color:"#6e4f9f",padding:"0px 30px",fontSize:"15px"}} onClick={async () => {
                                            const hide = message.loading("Action in progress", 0)
                                            var myHeaders = new Headers();
                                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                                            var urlencoded = new URLSearchParams();
                                            urlencoded.append("_id", tickets._id);

                                            var requestOptions = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: urlencoded,
                                                redirect: 'follow'
                                            };


                                            await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/updatestatusallticketsbyid", requestOptions)
                                                .then(response => response.json())
                                                .then(result => {
                                                    settickets(result)

                                                })
                                                .catch(error => console.log('error', error));





                                            await setTimeout(() => {
                                                hide(); // Call hide to stop the loading message
                                                message.success("Action completed successfully");
                                            }, 2000);

                                        }}>Close This Ticket</button>

                                    </>) : (<>
                                        <button className='button' style={{background:"#dcd2fc",borderRadius:"14px",color:"#6e4f9f",padding:"0px 30px",fontSize:"15px"}} onClick={async () => {
                                            const hide = message.loading("Action in progress", 0)
                                            var myHeaders = new Headers();
                                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                                            var urlencoded = new URLSearchParams();
                                            urlencoded.append("_id", tickets._id);

                                            var requestOptions = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: urlencoded,
                                                redirect: 'follow'
                                            };


                                            await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/updatestatusallticketsbyid2", requestOptions)
                                                .then(response => response.json())
                                                .then(result => {
                                                    settickets(result)

                                                })
                                                .catch(error => console.log('error', error));





                                            await setTimeout(() => {
                                                hide(); // Call hide to stop the loading message
                                                message.success("Action completed successfully");
                                            }, 2000);

                                        }}>Open This Ticket</button>

                                    </>)}




                                    <button className='dltbtn button-style-2' onClick={async () => {
                                        const hide = message.loading("Action in progress", 0)
                                        var myHeaders = new Headers();
                                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                                        var urlencoded = new URLSearchParams();
                                        urlencoded.append("_id", tickets._id);

                                        var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: urlencoded,
                                            redirect: 'follow'
                                        };


                                        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/dlttickets", requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                navigate("/dashboard/tickets")

                                            })
                                            .catch(error => console.log('error', error));





                                        await setTimeout(() => {
                                            hide(); // Call hide to stop the loading message
                                            message.success("Action completed successfully");
                                        }, 2000);

                                    }}>Delete This Ticket</button></div>
                            </div>



                            <div className='header' style={{ marginTop: '50px' }} >
                                <h2>Replies </h2>
                            </div>
                            <div className='questionbox2-chat'>


                                {tickets.reply.map((value) => {
                                    return (<>

                                        {value.replyby === "user" ? (<>
                                            <div style={{ display: 'flex', justifyContent: "left", width: '100%', }}>
                                                <div class="sharp-corner-box reciever ::reciever">
                                                    <p style={{ display: 'flex', textAlign: 'left' }}>

                                                        <span dangerouslySetInnerHTML={{ __html: value.contant.replace(/\n/g, "<br />") }} />
                                                    </p>
                                                </div>
                                                <div style={{ color: "#6E4E9F" ,padding:"5px" }}>{value.date}</div>
                                            </div> </>) : (<>
                                                <div style={{ display: 'flex', justifyContent: "right", width: '100%', }}>
                                                    <div style={{ color: "#6E4E9F",padding:"5px"  }}>{value.date}</div>
                                                    <div class="sharp-corner-box2 sender">
                                                        <p style={{ display: 'flex', textAlign: 'left' }}>

                                                            <span dangerouslySetInnerHTML={{ __html: value.contant.replace(/\n/g, "<br />") }} />
                                                        </p>
                                                    </div>
                                                </div>
                                            </>)}
                                    </>)
                                })}



                                {tickets.status === "open" ? (<>
                                    <div style={{ marginTop: '50px' }}></div>
                                    <h4 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center', marginBottom: '5px',paddingLeft:"5px", padding: '0px' }}>Reply : </h4>
                                    <form onSubmit={async (e) => {
                                        e.preventDefault()
                                        const hide = message.loading("Action in progress", 0)

                                        var myHeaders = new Headers();
                                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                                        var urlencoded = new URLSearchParams();
                                        urlencoded.append("_id", tickets._id);
                                        urlencoded.append("contant", reply);

                                        var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: urlencoded,
                                            redirect: 'follow'
                                        };


                                        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/replybyadmin", requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                settickets(result)
                                                setreply("")
                                            })
                                            .catch(error => console.log('error', error));


                                        await setTimeout(() => {
                                            hide(); // Call hide to stop the loading message
                                            message.success("Action completed successfully");
                                        }, 2000);

                                    }} style={{ width: '100%', padding: '0', display: 'flex', flexDirection: 'column', gap: '10px' }}>  
                                    <textarea value={reply} onChange={(e) => setreply(e.target.value)} required style={{ width: '97.5%',border:"none", height: '100px',borderRadius:"10px",padding:"10px",background:"white"}} placeholder='Type your Replay' /> <button className='button button-style-2'>Send</button></form>
                                </>) : (<>

                                    <p style={{ color: 'gray' }}>Ticket is Closed you need to open the ticket in order to reply </p>
                                </>)}
                            </div>
                        </>}
                    </div>
                </div>
            </>}
        </div>
    )
}

export default Ticketsid
