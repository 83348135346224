import React, { useState } from 'react'
import Cookies from 'js-cookie';
import { BiHelpCircle, BiSolidAddToQueue } from 'react-icons/bi';
import Navbar from '../../components/navbar';
import Sidemanu from '../../components/sidemanu';
import "../../css/teamtickets.css"
import { useEffect } from 'react';
import { Badge, Image, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FaRegUserCircle, FaTasks } from 'react-icons/fa';
import { imageDb } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import axios from 'axios';
import { ImUpload2 } from 'react-icons/im';
import ReactQuill from 'react-quill';


function Teamticks() {
    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const id = Cookies.get("id")
    const token = Cookies.get("Token")
    const [tickets, settickets] = useState([])
    const [ticketsread, setticketsread] = useState([])
    const [admins, setadmin] = useState([])

    const [popup, setpopup] = useState(false)
    const [popup3, setpopup3] = useState(false)
    const [loading, setloading] = useState(false)



    const [subject, setsubject] = useState("")
    const [to, setto] = useState([])
    const [about, setabout] = useState("")
    const [files, setfiles] = useState([]);
    const [priority, setpriority] = useState("");



    const navigate = useNavigate()
    const apiurlforbackend = process.env.REACT_APP_APIURL


    const getticketsunread = async (idd) => {

        let data = JSON.stringify({
            "id": idd,
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + "/getallteamticketsbyidandunseen",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                settickets(response.data);
            })
            .catch((error) => {
                console.log(error);
            });


    }

    const getticketsread = async (idd) => {

        let data = JSON.stringify({
            "id": idd,
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + "/getallteamticketsbyid",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                setticketsread(response.data);
            })
            .catch((error) => {
                console.log(error);
            });


    }


    const getadmin = async () => {



        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: apiurlforbackend + "/getalladmin",
            headers: {
                'Content-Type': 'application/json'
            },

        };

        await axios.request(config)
            .then((response) => {
                setadmin(response.data);
            })
            .catch((error) => {
                console.log(error);
            });




    }

    useEffect(() => {
        if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {

            const interval = setInterval(() => {
                getticketsunread(id);
                getticketsread(id)
            }, 1000);


          
            getadmin()
            return () => clearInterval(interval);
        }

    }, [])



    const handleFileUpload = (e) => {
        const fileList = e.target.files; // FileList object
        const filesArray = Array.from(fileList); // Convert to an array

        setfiles((prevFiles) => {
            // Filter files: only allow CSV and PDF, and avoid duplicates
            const newFiles = filesArray.filter(
                (newFile) =>
                    (newFile.type === "application/pdf" || newFile.type === "text/csv" ||
                        newFile.name.endsWith(".csv")) && // Ensure CSV and PDF files only
                    !prevFiles.some(
                        (existingFile) =>
                            existingFile.name === newFile.name && existingFile.size === newFile.size
                    )
            );

            // Return the updated files list
            return [...prevFiles, ...newFiles];
        });
    };






    const handleFileDrop = (e) => {
        e.preventDefault();

        const fileList = e.dataTransfer.files; // FileList object
        const filesArray = Array.from(fileList); // Convert to an array

        setfiles((prevFiles) => {
            // Filter files: only allow CSV and PDF, and avoid duplicates
            const newFiles = filesArray.filter(
                (newFile) =>
                    (newFile.type === "application/pdf" || newFile.type === "text/csv" ||
                        newFile.name.endsWith(".csv")) && // Ensure CSV and PDF files only
                    !prevFiles.some(
                        (existingFile) =>
                            existingFile.name === newFile.name && existingFile.size === newFile.size
                    )
            );

            // Return the updated files list
            return [...prevFiles, ...newFiles];
        });
    };




    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'align': [] }],

            ['clean'] // removes formatting
        ],
    };


    const addnewkit = async () => {
        await setloading(true)



        let filestosend = []

        for (const file of files) {

            const isImage = file.type

            const imgRef = await ref(imageDb, `teamticketfiles/${file.name}`)
            await uploadBytes(imgRef, file)
            const filefirebaseurl = await getDownloadURL(imgRef);

            const data = { type: isImage, url: filefirebaseurl }

            await filestosend.push(data)

        }


        let data = JSON.stringify({
            "givenby": id,
            "to": to,
            "attachment": filestosend,
            "priority": priority,
            "content": about,
            "subject": subject


        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + "/addteamtickets",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                getticketsread(id)
                setpopup3(false)
                setpopup(false)
                setsubject("")
                setto([])
                setfiles([])
                setpriority("")
                setabout("")
            })
            .catch((error) => {
                console.log(error);
            });


        await setloading(true)

    }


    const getdateandtime = (timestamp) => {

        const date = new Date(timestamp);

        // Get the date in a readable format
        const formattedDate = date.toLocaleDateString(); // This will give you the date in the format "1/17/2025"
        const formattedTime = date.toLocaleTimeString(); // This will give you the time in the format "10:29:32 AM"

        return (`Date: ${formattedDate}  ${formattedTime}`)
    }

    return (
        <div>
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                <Navbar />
                <div className='deshboardmain'>
                    <Sidemanu />
                    <div className='adminkitmainbody'>
                        <div className='header'>
                            <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><FaTasks style={{ width: '30px', height: '30px' }} />Task Management System</h1>


                            <button onClick={() => setpopup(true)} className='button' style={{ padding: "0px 20px", height: '40px' }}>Assign New Task</button>
                        </div>




                        {popup === true && <>
                            <div onClick={() => {
                                setpopup(false)

                            }
                            } className='popupbg'></div>

                            {popup3 ? (<>

                                <div className='popup'>





                                    <div className='header' >
                                        <h4>Assign&#160; this Task to:</h4>

                                        {/* {dooption2()}

{searchdone2 === false && <>
    <form onSubmit={handleInputChange2} style={{ width: "fit-content" }}>
        <AutoComplete
            type="number"
            style={{ width: 200 }}
            options={options2}
            placeholder="Search by Email/Name"
            filterOption={(inputValue, options) =>
                options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                //  console.log(kitss) 
            }
            onChange={(inputValue) => setSearchTerm2(inputValue)}
        />
        <button>Search</button>
    </form>

</>}
{searchdone2 === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm2}</h3> <button onClick={clearsearch2}><AiOutlineClose /> Clear</button>  </div>
</>} */}
                                    </div>


                                    <div className='userbox'>


                                        {admins.map((item, index) =>
                                            <>{item._id !== id && <>
                                                <div
                                                    onClick={() => {

                                                        if (to.includes(item._id)) {
                                                            // Remove item._id from the array
                                                            setto(prevState => prevState.filter(id => id !== item._id));
                                                        } else {
                                                            // Add item._id to the array
                                                            setto(prevState => [...prevState, item._id]);
                                                        }

                                                    }}

                                                    className={to.includes(item._id) ? 'userboxxinside slecteddd' : 'userboxxinside'}

                                                >

                                                    <img alt='' src={item.profilepic} width={"25%"} />
                                                    <div>
                                                        <h5>Name: {item.name}</h5>
                                                        <h5>email: {item.email}</h5>

                                                        {item.phone !== "-" ? (<><h5>phone:{item.phone}</h5></>) : (<></>)}
                                                    </div>
                                                </div>
                                            </>}</>

                                        )}







                                    </div>





                                    <div className='bownpopupbutton'>
                                        <button onClick={() => {
                                            setpopup3(false)


                                        }
                                        } style={{ border: '1px solid red', color: 'red' }} >Go Back</button>





                                        <button
                                            onClick={() => {
                                                if (to.length > 0) {
                                                    addnewkit()
                                                } else {
                                                    message.error("Please Select Admin");
                                                }
                                            }}
                                            style={{ backgroundColor: '#4180b7' }}
                                        >
                                            ASSIGN
                                        </button>




                                    </div>

                                </div>

                            </>) : (<>
                                <div className='popup'>



                                    <form className='eventform'
                                        onSubmit={(e) => {
                                            e.preventDefault()
                                            if (about === "") {
                                                alert("Please Enter Content for Ticket")
                                            } else {
                                                setpopup3(true)

                                            }
                                        }}
                                    >

                                        <div style={{ fontWeight: 'bold' }}>
                                            <label>Subject</label>
                                            <input value={subject} onChange={(e) => setsubject(e.target.value)} required placeholder='Subject' />
                                        </div>

                                        <div>
                                            <label>Priority</label>
                                            <select onChange={(e) => setpriority(e.target.value)} required >
                                                <option disabled selected>Please select Ticket Priority</option>
                                                <option value={"low"}>Low</option>
                                                <option value={"medium"}>Medium</option>
                                                <option value={"high"}>High</option>




                                            </select>
                                        </div>





                                        <div >
                                            <label>Content</label>
                                            <div className='contantdiv'>


                                                <div className='divofevent'>
                                                    <div>
                                                        <h5 style={{ margin: '0', padding: "0" }}>Attachment</h5 >
                                                        <p style={{ margin: '0', padding: "0" }}>
                                                            You can attach any file. Supported formats include images, documents, and others. Please ensure the file is not too large.
                                                        </p>
                                                    </div>


                                                    <div className="file-drop-zone"

                                                        style={{ display: "flow", width: "65%" }}
                                                        onDrop={handleFileDrop}
                                                        onDragOver={(e) => e.preventDefault()}

                                                    >

                                                        <label className="custom-file-upload" style={{ backgroundColor: 'white', height: "fit-content" }}>

                                                            <div className='clickablediv' style={{ height: "fit-content" }}>


                                                                <ImUpload2 style={{ width: '100px', height: '100px' }} />
                                                                <h4>Click or drag file to this area to upload</h4>


                                                            </div>
                                                            <input
                                                                multiple="off"
                                                                className='inputbuttontouploadfile'
                                                                type="file"
                                                                accept=".png, .jpg, .jpeg, .gif"  // Allow image file types
                                                                onChange={handleFileUpload}
                                                            />


                                                        </label>

                                                    </div>


                                                    {/* <div className="message-bubble">
                                                         <div style={{ display: 'flex', flexWrap: 'wrap' }}>


                                                            {files.map((file, index) => {
                                                                // Check if the file is an image by its extension (for example, .jpg, .png, etc.)
                                                                const isImage = file.type.startsWith('image/');

                                                                return (
                                                                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                                        {isImage ? (
                                                                            <>
                                                                                <img src={file.url} width={"100px"} height={"80px"} />
                                                                            </>
                                                                        ) : (
                                                                            <a href={file.url} target="_blank">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="85px" viewBox="0 0 1024 1024" className="icon" version="1.1" >
                                                                                    <path d="M719.8 651.8m-10 0a10 10 0 1 0 20 0 10 10 0 1 0-20 0Z" fill="#E73B37" />
                                                                                    <path d="M512.1 64H172v896h680V385.6L512.1 64z m278.8 324.3h-280v-265l280 265zM808 916H216V108h278.6l0.2 0.2v296.2h312.9l0.2 0.2V916z" fill="#fff" />


                                                                                    <path d="M403.5 641.8h277v16h-277z" fill="#39393A" />
                                                                                    <path d="M280.6 641.8h91.2v16h-91.2z" fill="#E73B37" />
                                                                                    <path d="M279.9 753.7h326.5v16H279.9z" fill="#39393A" />
                                                                                    <path d="M655.8 753.7h73.9v16h-73.9z" fill="#E73B37" />
                                                                                    <text x="50%" y="50%" fill="#000000" fontSize="70" fontWeight="bold" textAnchor="middle" alignmentBaseline="middle">
                                                                                        {file.type}
                                                                                    </text>
                                                                                </svg>
                                                                            </a>

                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div> 
                                                          </div> */}

                                                </div>




                                                <div  >
                                                    <ReactQuill
                                                        require="true"
                                                        value={about}
                                                        onChange={setabout}
                                                        modules={modules}
                                                        placeholder="Add a description"
                                                    // theme="snow" // snow is the default theme
                                                    />
                                                </div>
                                            </div>
                                        </div>











                                        <div className='buttonnnnn' style={{ flexDirection: 'row', justifyContent: 'space-between !important', width: '100%' }}>
                                            <button onClick={() => {
                                                setpopup(false)

                                            }
                                            } style={{ border: '1px solid red', color: 'red', background: 'none' }} >CANCEL</button>



                                            <button
                                                // onClick={sendkittobackend}
                                                style={{ backgroundColor: '#4180b7' }}>SUBMIT</button>




                                        </div>
                                    </form>


                                </div>


                            </>)}













                        </>}




                        {(tickets.length > 0 && admins.length > 0) && <>
                            <div className='header' >
                                <h2>New Unread Tickets </h2>
                            </div>
                            <div className='ticketcantainer' >

                                {tickets.map((value, index) => {

                                    return (<>
                                        <div className='ticketcantainerinside'>

                                            <div className='rebinforp2'>
                                                {value.priority}
                                            </div>

                                            <div className='ticketcantainerinside_header'><div><h4>Genrated By: </h4> <p> {admins.find(value2 => value.givenby === value2._id).name}</p></div>   <div><h4>To: </h4>  <p>{value.to.map((name2, index) => <>{admins.find(value2 => name2 === value2._id).name} {index !== value.to.length - 1 && <>,</>}</>)}</p></div>    </div>

                                            <div className='ticketcantainerinside_header'><div><h4>Subject: </h4> <p> {value.subject}</p></div>       </div>



                                            <p style={{ display: 'flex', textAlign: 'left', margin: '0', alignItems: 'center', gap: '10px' }}>
                                                <h4 >Message: </h4>
                                                {/* <span dangerouslySetInnerHTML={{ __html: value.content.replace(/\n/g, "<br />") }} /> */}
                                                <span dangerouslySetInnerHTML={{ __html: value.content }} />
                                            </p>


                                            <div className='dltbtndiv' style={{ justifyContent: 'space-between' }}>



                                                <button className='button' onClick={async () => {



                                                    let data = JSON.stringify({
                                                        "id": value._id,
                                                        "userid": id
                                                    });

                                                    let config = {
                                                        method: 'post',
                                                        maxBodyLength: Infinity,
                                                        url: apiurlforbackend + '/markteamticketseen',
                                                        headers: {
                                                            'Content-Type': 'application/json'
                                                        },
                                                        data: data
                                                    };

                                                    await axios.request(config)
                                                        .then((response) => {
                                                            console.log(JSON.stringify(response.data));
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                        });



                                                    await navigate("/dashboard/teams/tickets/" + value._id)
                                                }}>Read</button> </div>


                                        </div>
                                    </>)
                                })}
                            </div>
                        </>}



                        <div style={{ marginTop: "50px" }}></div>

                        {(ticketsread.length > 0 && admins.length > 0) && <>
                            {/* <div className='header' >
                                <h2>Replied </h2>
                            </div> */}
                            <div className='ticketcantainer' >

                                {ticketsread.map((value, index) => {

                                    let totalunreadmessages = 0
                                    let show = false

                                    for (let msg of value.messages) {
                                        if (msg.by !== id && !msg.seenby.includes(id)) {

                                            // await setRead(index);
                                            // await setReadc(false);

                                            totalunreadmessages++



                                            // check = true
                                            // indexofmessage = index

                                        }
                                    }





                                    if (totalunreadmessages > 0) {
                                        show = true

                                    }

                                    let classnameee



                                    return (<>

                                        {show && <>
                                            <div className='ticketcantainerinside Repliedtickets'>

                                                {value.Status && <>    <div class="ribbon left" style={{ zIndex: '12' }}>Completed with unread message</div></>}


                                                <div className='rebinforp2'>
                                                    {value.priority}
                                                </div>

                                                <div className='ticketcantainerinside_header'><div><h4>Genrated By: </h4> <p> {admins.find(value2 => value.givenby === value2._id).name}</p></div>   <div><h4>To: </h4>  <p>{value.to.map((name2, index) => <>{admins.find(value2 => name2 === value2._id).name} {index !== value.to.length - 1 && <>,</>}</>)}</p></div>    </div>

                                                <div className='ticketcantainerinside_header'><div><h4>Subject: </h4> <p> {value.subject}</p></div>       </div>



                                                <p style={{ display: 'flex', textAlign: 'left', margin: '0', alignItems: 'center', gap: '10px' }}>
                                                    <h4 >Message: </h4>
                                                    {/* <span dangerouslySetInnerHTML={{ __html: value.content.replace(/\n/g, "<br />") }} /> */}
                                                    <span dangerouslySetInnerHTML={{ __html: value.content }} />
                                                </p>


                                                <div className='lastmessage'><h5>New Message..</h5>

                                                    <Badge count={totalunreadmessages} overflowCount={10} >
                                                        <div onClick={()=>{
                                                            navigate("/dashboard/teams/tickets/" + value._id)
                                                        }} className='messagebubbble'>


                                                            <FaRegUserCircle style={{ width: '50px', height: '50px', color: '#6E4E9F' }} />
                                                            <div className="message-bubble rec">
                                                                <span className="date-text2">{admins.find(value2 => value.messages[value.messages.length - 1].by === value2._id).name}</span>
                                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>


                                                                    {value.messages[value.messages.length - 1].file.map((file, index) => {
                                                                        // Check if the file is an image by its extension (for example, .jpg, .png, etc.)
                                                                        const isImage = file.type.startsWith('image/');

                                                                        return (
                                                                            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                                                {isImage ? (
                                                                                    <>
                                                                                        <Image src={file.url} width={"100px"} height={"80px"} />
                                                                                    </>
                                                                                ) : (

                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="85px" viewBox="0 0 1024 1024" version="1.1" style={{ color: '#fff' }} >
                                                                                        <path d="M719.8 651.8m-10 0a10 10 0 1 0 20 0 10 10 0 1 0-20 0Z" fill="#E73B37" />
                                                                                        <path d="M512.1 64H172v896h680V385.6L512.1 64z m278.8 324.3h-280v-265l280 265zM808 916H216V108h278.6l0.2 0.2v296.2h312.9l0.2 0.2V916z" fill="#fff" />


                                                                                        <path d="M403.5 641.8h277v16h-277z" fill="#fff" />
                                                                                        <path d="M280.6 641.8h91.2v16h-91.2z" fill="#E73B37" />
                                                                                        <path d="M279.9 753.7h326.5v16H279.9z" fill="#fff" />
                                                                                        <path d="M655.8 753.7h73.9v16h-73.9z" fill="#E73B37" />
                                                                                        <text x="50%" y="50%" fill="#fff" fontSize="70" fontWeight="bold" textAnchor="middle" alignmentBaseline="middle">
                                                                                            {file.type}
                                                                                        </text>
                                                                                    </svg>


                                                                                )}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>  <p className="message-text" style={{ color: "#fff" }}> <span dangerouslySetInnerHTML={{ __html: value.messages[value.messages.length - 1].content.replace(/\n/g, "<br />") }} /></p>
                                                                <span className="date-text">{value.messages[value.messages.length - 1].date} {value.messages[value.messages.length - 1].time} </span>
                                                            </div>


                                                        </div>
                                                    </Badge>

                                                </div>

                                                <div className='dltbtndiv' style={{ justifyContent: 'space-between' }}>



                                                    <button className='button' onClick={async () => {

                                                        navigate("/dashboard/teams/tickets/" + value._id)
                                                    }}>Details</button>


                                                </div>


                                            </div>
                                        </>}
                                    </>)
                                })}
                            </div>
                        </>}




                        <div style={{ marginTop: "50px" }}></div>

                        {(ticketsread.length > 0 && admins.length > 0) && <>
                            {/* <div className='header' >
                                <h2>Closed Tickets </h2>
                            </div> */}
                            <div className='ticketcantainer' >

                                {ticketsread.map((value, index) => {

                                    let totalunreadmessages = 0
                                    let show = false

                                    for (let msg of value.messages) {
                                        if (msg.by !== id && !msg.seenby.includes(id)) {

                                            // await setRead(index);
                                            // await setReadc(false);

                                            totalunreadmessages++



                                            // check = true
                                            // indexofmessage = index

                                        }
                                    }

                                    if (totalunreadmessages === 0 && !value.Status) {
                                        show = true

                                    }


                                    return (<>

                                        {show && <>
                                            <div className='ticketcantainerinside cloedtickets'>

                                                {/* <div class="ribbon left">Task Completed</div> */}

                                                <div className='rebinforp2' >
                                                    {value.priority}
                                                </div>

                                                <div className='ticketcantainerinside_header'><div><h4>Genrated By: </h4> <p> {admins.find(value2 => value.givenby === value2._id).name}</p></div>   <div><h4>To: </h4>  <p>{value.to.map((name2, index) => <>{admins.find(value2 => name2 === value2._id).name} {index !== value.to.length - 1 && <>,</>}</>)}</p></div>    </div>

                                                <div className='ticketcantainerinside_header'><div><h4>Subject: </h4> <p> {value.subject}</p></div>       </div>



                                                <p style={{ display: 'flex', textAlign: 'left', margin: '0', alignItems: 'center', gap: '10px' }}>
                                                    <h4 >Message: </h4>
                                                    {/* <span dangerouslySetInnerHTML={{ __html: value.content.replace(/\n/g, "<br />") }} /> */}
                                                    <span dangerouslySetInnerHTML={{ __html: value.content }} />
                                                </p>


                                                <div className='dltbtndiv' style={{ justifyContent: 'space-between' }}>



                                                    <button className='button' onClick={async () => {

                                                        navigate("/dashboard/teams/tickets/" + value._id)
                                                    }}>Details</button> </div>


                                            </div>
                                        </>}
                                    </>)
                                })}
                            </div>
                        </>}


                        <div style={{ marginTop: "50px" }}></div>

                        {(ticketsread.length > 0 && admins.length > 0) && <>
                            {/* <div className='header' >
                                <h2>Closed Tickets </h2>
                            </div> */}
                            <div className='ticketcantainer' >

                                {ticketsread.map((value, index) => {

                                    let totalunreadmessages = 0
                                    let show = false

                                    for (let msg of value.messages) {
                                        if (msg.by !== id && !msg.seenby.includes(id)) {

                                            // await setRead(index);
                                            // await setReadc(false);

                                            totalunreadmessages++



                                            // check = true
                                            // indexofmessage = index

                                        }
                                    }





                                    if (totalunreadmessages === 0) {
                                        show = true

                                    }

                                    return (<>

                                        {(value.Status && show) && <>
                                            <div className='ticketcantainerinside cloedtickets'>

                                                <div class="ribbon left">Task Completed</div>

                                                <div className='rebinforp2'>
                                                    {value.priority}
                                                </div>

                                                <div className='ticketcantainerinside_header'><div><h4>Genrated By: </h4> <p> {admins.find(value2 => value.givenby === value2._id).name}</p></div>   <div><h4>To: </h4>  <p>{value.to.map((name2, index) => <>{admins.find(value2 => name2 === value2._id).name} {index !== value.to.length - 1 && <>,</>}</>)}</p></div>    </div>

                                                <div className='ticketcantainerinside_header'><div><h4>Subject: </h4> <p> {value.subject}</p></div>       </div>



                                                <p style={{ display: 'flex', textAlign: 'left', margin: '0', alignItems: 'center', gap: '10px' }}>
                                                    <h4 >Message: </h4>
                                                    {/* <span dangerouslySetInnerHTML={{ __html: value.content.replace(/\n/g, "<br />") }} /> */}
                                                    <span dangerouslySetInnerHTML={{ __html: value.content }} />
                                                </p>


                                                <div className='dltbtndiv' style={{ justifyContent: 'space-between' }}>



                                                    <button className='button' onClick={async () => {

                                                        navigate("/dashboard/teams/tickets/" + value._id)
                                                    }}>Details</button> </div>


                                            </div>
                                        </>}
                                    </>)
                                })}
                            </div>
                        </>}

                        {/* {(ticketsread.length > 0 && admins.length > 0) && <>
                            <div className='questionbox' style={{ marginTop: "50px", border: 'none' }}>

                                {ticketsread.map((value, index) => {

                                    return (<>
                                        <div className='question'>
                                            <h4 style={{ display: 'flex', margin: "0" }}><h4 style={{ width: '30px' }}>{index + 1} -</h4> {value.subject}</h4>
                                            <div style={{ textAlign: 'left' }}>

                                                <h6 style={{ margin: '0', marginRight: '10px', color: 'gray' }} >BY : {admins.find(value2 => value.givenby === value2._id).name} </h6 >
                                                <h6 style={{ margin: '0', marginRight: '10px', color: 'gray' }} >To : {value.to.map((name2) => <>{admins.find(value2 => name2 === value2._id).name}, </>)} </h6 >
                                                <h6 style={{ margin: '0', marginRight: '10px', color: 'gray' }} > {getdateandtime(value.timestamp)} </h6 >


                                            </div>
                                            <p style={{ display: 'flex', textAlign: 'left' }}>
                                                <h4 style={{ width: '30px' }}></h4>
                                                <span dangerouslySetInnerHTML={{ __html: value.content }} />
                                            </p>    <div className='dltbtndiv'> <button className='button' onClick={async () => {

                                                navigate("/dashboard/teams/tickets/" + value._id)
                                            }}>Details</button>


                                            </div>
                                            <div className='rebinforp'>
                                                <div className='rebinforp2'>
                                                    {value.priority}
                                                </div>
                                            </div>
                                        </div>
                                    </>)
                                })}
                            </div>
                        </>} */}
                    </div>
                </div>
            </>}
        </div>
    )
}

export default Teamticks
