import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';

import { BiSolidAddToQueue } from "react-icons/bi"
import { AiFillDelete, AiOutlineMail, AiTwotoneInteraction } from "react-icons/ai"
import { MdAssignmentReturn, MdAttachEmail, MdEditSquare, MdOutlinePayment, MdOutlinePayments, MdOutlinePendingActions } from "react-icons/md"
import { loadStripe } from '@stripe/stripe-js';
import { AiOutlineClose } from "react-icons/ai"
import { IoIosArrowDropdown } from "react-icons/io";
import Cookies from 'js-cookie';
import { AutoComplete, Button, Dropdown, Input, message, Popconfirm } from 'antd';
import Papa from 'papaparse';
import { FaKitMedical } from 'react-icons/fa6';
import { GiFirstAidKit } from 'react-icons/gi';
import { ImUpload2, ImUserTie } from 'react-icons/im'
import { TbGitBranchDeleted, TbReportMoney } from "react-icons/tb";


import axios from "axios"
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';



const KITS = (prams) => {


    const fileInputRef = useRef(null);
    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const loginid = Cookies.get("id")
    const token = Cookies.get("Token")
    const token2 = Cookies.get("Token2")
    const apiurlforbackend = process.env.REACT_APP_APIURL
    const [searchParams] = useSearchParams();

    const pageinurl = searchParams.get('page')
    const search = searchParams.get('search')

    const navigate = useNavigate()
    const [kit, setkit] = useState([])
    const [kit2, setkit2] = useState([])

    const [kitids, setkitids] = useState([])
    const [page, setpage] = useState(1)
    const [page2, setpage2] = useState(1)

    const [totalPages, settotalPages] = useState(1)
    const [totalPages2, settotalPages2] = useState(1)

    const [practitioner, setpractitioner] = useState([])
    const [practitioner2, setpractitioner2] = useState([])

    const [practitionertoassign, setpractitionertoassign] = useState([])
    const [practitionertoassign2, setpractitionertoassign2] = useState([])


    const [kitIDtoassign, setkitIDtoassign] = useState([])

    const [practitionertoassignID, setpractitionertoassignID] = useState("")
    const [assignall, setassignall] = useState(false)
    const type = `${prams.kittype}`


    const [popup, setpopup] = useState(false)
    const [popup_2, setpopup_2] = useState(false)
    const [popup2, setpopup2] = useState(false)
    const [popup3, setpopup3] = useState(false)
    const [addkit2, setaddkit2] = useState("")
    const [addkit2p, setaddkit2p] = useState("")
    const [addkit, setaddkit] = useState([])
    const [kitdetails, setkitdetails] = useState([])







    const setkitbyres = async (response) => {

        setkit(response.data.data);
        setkit2(response.data.data);
        setpractitioner(response.data.practitioner);
        setpractitioner2(response.data.practitioner);
        setpage(response.data["current page"])
        setpage2(response.data["current page"])
        settotalPages(response.data["total pages"])
        settotalPages2(response.data["total pages"])


    }




    const getkits = async (page) => {


        let tokentosend
        if (token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") {
            tokentosend = "dskgfsdgfkgsdfkjg35464154845674987dsf@53"
        } else {
            tokentosend = token
        }

        let data = JSON.stringify({
            "pagenumber": page,
            "type": type,
            "token": tokentosend,
            "loginid": loginid
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + '/getkitsbytype',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                setkitbyres(response)


            })
            .catch((error) => {
                console.log(error);
            });


    }



    const getkitsids = async () => {



        let tokentosend
        if (token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") {
            tokentosend = "dskgfsdgfkgsdfkjg35464154845674987dsf@53"
        } else {
            tokentosend = token
        }
        let data = JSON.stringify({

            "type": type,
            "token": tokentosend,
            "loginid": loginid
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + '/getallkitids',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                setkitids(response.data.kitID);

            })
            .catch((error) => {
                console.log(error);
            });


    }


    const getpractoassign = async () => {


        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: apiurlforbackend + '/getpracinfotoassign',
            headers: {
                'Content-Type': 'application/json'
            },

        };

        await axios.request(config)
            .then((response) => {
                setpractitionertoassign(response.data);
                setpractitionertoassign2(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    useEffect(() => {
        getkits(page)
        getkitsids()
        if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") { getpractoassign() }
    }, [])



    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageToShow = 3; // Show maximum 3 pages on each side of the current page
        const startPage = Math.max(1, page - maxPageToShow);
        const endPage = Math.min(totalPages, page + maxPageToShow);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <span
                    key={i}
                    onClick={() => handlePageClick(i)}
                    className={page === i ? 'activeeee' : ''}
                >
                    {i}
                </span>
            );
        }
        return pageNumbers;
    };



    const handlePageClick = async (pageNumber) => {
        await setkit([])
        await window.scrollTo(0, 0);
        await getkits(pageNumber)

        await renderPageNumbers()
    };





    const Addkit = async (e) => {
        await e.preventDefault()





        await setaddkit([...addkit, { Kit: addkit2, Kitprice: addkit2p, Kittype: type }]);
        setaddkit2("")
        setaddkit2p("")



    }

    const RemoveItemByIndex = (index) => {

        const updatedAddkit = addkit.filter((_, i) => i !== index);
        setaddkit(updatedAddkit);
    };



    const sendkittobackend = async () => {


        const hide = message.loading("Action in progress", 0)


        let data = JSON.stringify({
            "type": type,
            "pagenumber": page,
            "KITDATA": addkit,
            "assignto": practitionertoassignID,
            "assignall": assignall
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + '/addkit',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then(async (response) => {
                setkitbyres(response)
                await setpopup(false)
                await setpopup2(false)
                await setpopup3(false)
                await setassignall(false)
                await setaddkit([])

            })
            .catch((error) => {
                console.log(error);
            });


        await setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
        }, 0);
    }


    const assignkittopra = async () => {

        const hide = message.loading("Action in progress", 0)



        let data = JSON.stringify({
            "type": type,
            "pagenumber": page,
            "kitid": kitdetails._id,
            "kitID": kitIDtoassign._id,
            "userid": practitionertoassignID
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + '/assignkittoprac',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                setkitbyres(response)
                setpopup(false)
                setpopup2(false)
                setpopup3(false)

            })
            .catch((error) => {
                console.log(error);
            });



        await setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
        }, 0);


    }




    const [searchTerm, setSearchTerm] = useState('');

    var options = kitids;



    var sno1 = 1
    var sno2 = 1 + (15 * (page - 1))





    const [searchTerm2, setSearchTerm2] = useState('');
    var options2 = [];

    const dooption2 = () => {
        practitionertoassign2.map((value, index) => {


            options2.push({ value: value.name })
            options2.push({ value: value.email })


        })


    }



    const handleInputChange2 = (e) => {
        e.preventDefault()






        const intttt = (searchTerm2)


        const filteredSuggestions = practitionertoassign.filter((item) =>

            item.name.toLowerCase().includes(intttt.toLowerCase()) || item.email.toLowerCase().includes(intttt.toLowerCase())



        );


        setpractitionertoassign(filteredSuggestions);

        setsearchdone2(true)

    };




    const [searchdone2, setsearchdone2] = useState(false)

    const clearsearch2 = () => {


        setpractitionertoassign(practitionertoassign2)


        setsearchdone2(false)

    }





    const findpracemail = () => {

        var emailll
        practitioner.map((value) => {

            if (value._id === kitdetails.assignedto) {
                emailll = value.email

            }


        })
        if (token !== "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
            emailll = loginemail

        }

        return emailll



    }

    const findpracname = () => {

        var nameee
        practitioner.map((value) => {

            if (value._id === kitdetails.assignedto) {
                nameee = value.name

            }


        })

        if (token !== "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
            nameee = loginname

        }
        return nameee



    }



    const kitdetailsKitprice = () => {
        var paymenttt
        if (kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf") {
            paymenttt = kitdetails.Kitprice2
        } else {
            paymenttt = kitdetails.Kitprice2
        }

        return paymenttt
    }

    const kitdetailsKitpricestatus = () => {
        var paymenttt
        if (kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf") {
            paymenttt = "Payment Completed"
        } else {
            paymenttt = "Payment Pending"
        }

        return paymenttt
    }
















    const items = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={async () => {


                    const stripe = await loadStripe("pk_live_51MsenqJSzdsymN5jGOTIP3q4qBmD4PDra9chWFQYDC6RCchx2jLlIgdDRrUnhI24QhZeNeAqEo9tc6l3oiR4SWc3000yjqG8qW");

                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                    var urlencoded = new URLSearchParams();
                    urlencoded.append("KitID", kitdetails._id);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: 'follow'
                    };


                    const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/create-checkout-session", requestOptions)

                    const session = await response.json();

                    const result = stripe.redirectToCheckout({
                        sessionId: session.id
                    });

                    if (result.error) {
                        console.log(result.error);
                    }




                }}>
                    Pay The Ammount Of Kit
                </a>
            ),
            icon: <MdOutlinePayment style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={async (e) => {
                    e.preventDefault()


                    const hide = message.loading("Action in progress", 0)

                    let data = JSON.stringify({
                        "pagenumber": page,
                        "type": type,
                        "id": kitdetails._id
                    });

                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: apiurlforbackend + '/kitpricepaymentdoneover',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };

                    await axios.request(config)
                        .then((response) => {
                            setkitbyres(response)
                            setpopup2(false)
                        })
                        .catch((error) => {
                            console.log(error);
                        });


                    await setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success("Action completed successfully");
                    }, 2000);



                }}>


                    Mark as paid

                </a>


            ),

            disabled: kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf",
            icon: < MdOutlinePayment style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" >


                    Payment is Due

                </a>


            ),

            disabled: true,
            icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

        },

    ];


    const items1 = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={async () => {

                    const hide = message.loading("Action in progress", 0)
                    const stripe = await loadStripe("pk_live_51MsenqJSzdsymN5jGOTIP3q4qBmD4PDra9chWFQYDC6RCchx2jLlIgdDRrUnhI24QhZeNeAqEo9tc6l3oiR4SWc3000yjqG8qW");

                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                    var urlencoded = new URLSearchParams();
                    urlencoded.append("KitID", kitdetails._id);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: 'follow'
                    };


                    const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/create-checkout-session", requestOptions)

                    const session = await response.json();

                    const result = stripe.redirectToCheckout({
                        sessionId: session.id
                    });

                    if (result.error) {
                        console.log(result.error);
                    }



                    await setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success("Action completed successfully");
                    }, 2000);
                }}>
                    Pay The Ammount Of Kit
                </a>
            ),
            disabled: kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf",
            icon: <MdOutlinePayment style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

        },


    ];

    const items2 = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer"
                >
                    Pay The Ammount Of Kit
                </a>
            ),
            disabled: true,
            icon: <MdOutlinePayment style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={async (e) => {
                    e.preventDefault()


                    const hide = message.loading("Action in progress", 0)

                    let data = JSON.stringify({
                        "pagenumber": page,
                        "type": type,
                        "id": kitdetails._id
                    });

                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: apiurlforbackend + '/kitpricepaymentdoneover',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };

                    await axios.request(config)
                        .then((response) => {
                            setkitbyres(response)
                            setpopup2(false)
                        })
                        .catch((error) => {
                            console.log(error);
                        });


                    await setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success("Action completed successfully");
                    }, 2000);



                }}>


                    Mark as paid

                </a>


            ),
            disabled: kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf",

            icon: <MdOutlinePayment style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={async (e) => {

                    e.preventDefault()
                    const hide = message.loading("Action in progress", 0)

                    let data = JSON.stringify({
                        "pagenumber": page,
                        "type": type,
                        "id": kitdetails._id,
                        "emailtype": "payment ack"
                    });

                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: apiurlforbackend + '/sendemailtoprac',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };

                    await axios.request(config)
                        .then((response) => {
                            setkitbyres(response)
                            setpopup2(false)
                        })
                        .catch((error) => {
                            console.log(error);
                        });


                    await setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success("Action completed successfully");
                    }, 2000);


                }}>


                    Send Payment Acknowledgment

                </a>


            ),

            disabled: kitdetails.ackpayment,
            icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

        },

    ];



    const itemsstatus1 = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer"
                    onClick={async (e) => {
                        e.preventDefault()


                        const hide = message.loading("Action in progress", 0)

                        let data = JSON.stringify({
                            "pagenumber": page,
                            "type": type,
                            "id": kitdetails._id,
                            "emailtype": "kit ack"
                        });

                        let config = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: apiurlforbackend + '/sendemailtoprac',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            data: data
                        };

                        await axios.request(config)
                            .then((response) => {
                                setkitbyres(response)
                                setpopup2(false)
                            })
                            .catch((error) => {
                                console.log(error);
                            });


                        await setTimeout(() => {
                            hide(); // Call hide to stop the loading message
                            message.success("Action completed successfully");
                        }, 2000);


                    }}>
                    Send Kit Received Acknowledgment
                </a>
            ),
            disabled: (kitdetails.ack),
            icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer"


                    onClick={async (e) => {
                        e.preventDefault()


                        const hide = message.loading("Action in progress", 0)

                        let data = JSON.stringify({
                            "pagenumber": page,
                            "type": type,
                            "id": kitdetails._id,
                            "emailtype": "payment ack and kit ack"
                        });

                        let config = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: apiurlforbackend + '/sendemailtoprac',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            data: data
                        };

                        await axios.request(config)
                            .then((response) => {
                                setkitbyres(response)
                                setpopup2(false)
                            })
                            .catch((error) => {
                                console.log(error);
                            });


                        await setTimeout(() => {
                            hide(); // Call hide to stop the loading message
                            message.success("Action completed successfully");
                        }, 2000);

                    }}
                >


                    Send Kit Received Acknowledgment with Payment information

                </a>


            ),
            disabled: (kitdetails.ack && kitdetails.ackpayment),

            icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" >


                    Results are Pending

                </a>


            ),

            disabled: true,
            icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

        },

    ];

    const itemsstatus2 = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer"
                    onClick={async (e) => {
                        e.preventDefault()


                        const hide = message.loading("Action in progress", 0)

                        let data = JSON.stringify({
                            "pagenumber": page,
                            "type": type,
                            "id": kitdetails._id,
                            "emailtype": "kit ack"
                        });

                        let config = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: apiurlforbackend + '/sendemailtoprac',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            data: data
                        };

                        await axios.request(config)
                            .then((response) => {
                                setkitbyres(response)
                                setpopup2(false)
                            })
                            .catch((error) => {
                                console.log(error);
                            });


                        await setTimeout(() => {
                            hide(); // Call hide to stop the loading message
                            message.success("Action completed successfully");
                        }, 2000);


                    }}>
                    Send Kit Received Acknowledgment
                </a>
            ),
            disabled: (kitdetails.ack),
            icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer"


                    onClick={async (e) => {
                        e.preventDefault()


                        const hide = message.loading("Action in progress", 0)

                        let data = JSON.stringify({
                            "pagenumber": page,
                            "type": type,
                            "id": kitdetails._id,
                            "emailtype": "payment ack and kit ack"
                        });

                        let config = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: apiurlforbackend + '/sendemailtoprac',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            data: data
                        };

                        await axios.request(config)
                            .then((response) => {
                                setkitbyres(response)
                                setpopup2(false)
                            })
                            .catch((error) => {
                                console.log(error);
                            });


                        await setTimeout(() => {
                            hide(); // Call hide to stop the loading message
                            message.success("Action completed successfully");
                        }, 2000);

                    }}
                >


                    Send Kit Received Acknowledgment with Payment information

                </a>


            ),
            disabled: (kitdetails.ack && kitdetails.ackpayment),

            icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={async (e) => {
                    e.preventDefault()


                    const hide = message.loading("Action in progress", 0)

                    let data = JSON.stringify({
                        "pagenumber": page,
                        "type": type,
                        "id": kitdetails._id,
                        "emailtype": "results"
                    });

                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: apiurlforbackend + '/sendemailtoprac',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };

                    await axios.request(config)
                        .then((response) => {
                            setkitbyres(response)
                            setpopup2(false)
                        })
                        .catch((error) => {
                            console.log(error);
                        });


                    await setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success("Action completed successfully");
                    }, 2000);

                }}>
                    Send Results To Practitioner
                </a>


            ),

            disabled: kitdetails.ackresult,
            icon: <AiOutlineMail style={{ width: '20px', height: '20px', color: '#4885B9' }} />,

        },

    ];




    const handleInputChange = async (e) => {
        e.preventDefault()

        setkit([])
        let tokentosend
        if (token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") {
            tokentosend = "dskgfsdgfkgsdfkjg35464154845674987dsf@53"
        } else {
            tokentosend = token
        }
        let data = JSON.stringify({

            "type": type,
            "search": searchTerm,
            "token": tokentosend,
            "loginid": loginid
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiurlforbackend + '/searchkits',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then(async (response) => {
                await setkit(response.data.data)
                await setpractitioner(response.data.practitioners)
                await setpage(1)
                await settotalPages(1)
                await renderPageNumbers()

            })
            .catch((error) => {
                console.log(error);
            });

        setsearchdone(true)

    };




    const [searchdone, setsearchdone] = useState(false)

    const clearsearch = async () => {


        await setkit(kit2)
        await setpractitioner(practitioner2)
        await setpage(page2)
        await settotalPages(totalPages2)
        await renderPageNumbers()

        setsearchdone(false)

    }












    const [csvFile, setCSVFile] = useState(null);
    const [csvData, setCsvData] = useState([]);




    const handleFileUpload = async (event) => {
        const file = event.target.files[0];

        setCSVFile(file); // Set the uploaded file state

        Papa.parse(file, {
            complete: async (result) => {
                const parsedData = result.data;
                setCsvData(parsedData); // Set the parsed data state

                // Prepare the new kit data
                const newKits = parsedData.map((value) => ({
                    Kit: value["Kit ID"],
                    Kitprice: 0,
                    Kittype: type,
                }));

                // Update the state with the new kits
                setaddkit((prevAddKit) => [...prevAddKit, ...newKits]);
            },
            header: true,
            skipEmptyLines: true,
        });
    };



    const handleFileDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile && droppedFile.type === 'text/csv') {
            setCSVFile(droppedFile);
            Papa.parse(droppedFile, {
                complete: async (result) => {
                    const parsedData = result.data;
                    setCsvData(parsedData); // Set the parsed data state

                    // Prepare the new kit data
                    const newKits = parsedData.map((value) => ({
                        Kit: value["Kit ID"],
                        Kitprice: 0,
                        Kittype: type,
                    }));

                    // Update the state with the new kits
                    setaddkit((prevAddKit) => [...prevAddKit, ...newKits]);
                },
                header: true,
                skipEmptyLines: true,
            });


        } else {
            alert('Please drop a valid CSV file.');
        }
    };


    const handleClearInput = () => {
        // setFiles([]); // Clear the files state
        fileInputRef.current.value = ''; // Clear the file input value
        setCSVFile(null)
    };


    return (<>
        {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") && <>


            <Navbar />
            <div className='deshboardmain'>
                <Sidemanu />
                <div className='adminkitmainbody'>


                    <div className='header'>
                        <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><FaKitMedical style={{ width: '35px', height: '35px' }} />{type} Kits</h1>
                        {searchdone === false && <>
                            <form onSubmit={handleInputChange}>


                                <AutoComplete

                                    style={{ width: 200 }}
                                    options={options}
                                    placeholder="Search by Kit ID"
                                    onChange={(inputValue) => setSearchTerm(inputValue)}
                                    filterOption={(inputValue, options) =>
                                        options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1

                                    }
                                />
                                <button>Search</button>
                            </form>
                        </>}
                        {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
                        </>}
                    </div>




                    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>    <div className='addbutton'><button onClick={() => {
                        setpopup(true)

                    }}><BiSolidAddToQueue style={{ width: '20px', height: '20px' }} />Add Kit</button></div></>}





                    {popup === true && <>
                        <div onClick={() => {
                            setpopup(false)

                        }
                        } className='popupbg'></div>


                        <div className='popup'>


                            {popup_2 ? (<>
                                <div className="file-drop-zone"


                                    onDrop={handleFileDrop}
                                    onDragOver={(e) => e.preventDefault()}

                                >

                                    <label className="custom-file-upload" >

                                        <div className='clickablediv'>


                                            <ImUpload2 style={{ width: '100px', height: '100px' }} />
                                            <h4>Click or drag file to this area to upload</h4>


                                        </div>
                                        <input
                                            multiple="off"
                                            ref={fileInputRef}
                                            className='inputbuttontouploadfile'
                                            type="file"
                                            accept=".csv"
                                            onChange={handleFileUpload}
                                        />

                                    </label>

                                </div>

                                <div className='filename'> {csvFile && (<> <p>Selected CSV File: {csvFile.name}</p><AiFillDelete onClick={handleClearInput} className='hovar' style={{ width: '30px', height: '30px', color: 'red' }} /></>)}</div>


                            </>) : (<>

                            </>)}
                            <form onSubmit={Addkit}>

                                <input onChange={(e) => setaddkit2(e.target.value.toUpperCase())} value={addkit2} required placeholder='Enter the Kit ID' />

                                <input pattern="(\d).{1,10000}" onChange={(e) => setaddkit2p(e.target.value)} value={addkit2p} required placeholder='Enter the Kit Price' />

                                <button >ADD</button>

                                {popup_2 ? (<><Button danger onClick={() => setpopup_2(!popup_2)} style={{ margin: '10px', color: "red", background: "none", border: "1px solid red" }} type='button'>Close Upload Area</Button></>) : (<> <button onClick={() => setpopup_2(!popup_2)} style={{ margin: '10px' }} type='button'>UPLOAD CSV</button></>)}
                            </form>


                            {addkit.length !== 0 && <>

                                <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                                    <thead className='tablephead'>
                                        <tr>
                                            <th>S NO.</th>
                                            <th>Kit ID</th>
                                            <th>Kit Price</th>
                                            <th>Kit Type</th>
                                            <th></th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {console.log(addkit)}
                                        {addkit.map((kit, index) => (

                                            <tr>
                                                <td>{sno1++}</td>

                                                <td>{kit.Kit} </td>
                                                <td>&pound;{kit.Kitprice} </td>
                                                <td> {kit.Kittype} </td>
                                                <td className='assignbuttom' ><AiFillDelete className='hovar' onClick={() => RemoveItemByIndex(index)} style={{ width: '30px', height: '30px', color: 'red' }} /></td>

                                            </tr>
                                        ))}










                                    </tbody>
                                </table>
                            </>}





                            <div className='bownpopupbutton'>
                                <button onClick={() => {
                                    setpopup(false)

                                }
                                } style={{ border: '1px solid red', color: 'red' }} >CANCEL</button>


                                <button onClick={() => {
                                    if (addkit.length > 0) {
                                        setpopup3(true)
                                        setassignall(true)
                                    } else {
                                        message.error("Please Enter KITS to Assign")
                                    }
                                }} style={{ backgroundColor: '#4180b7' }}>ASSIGN ALL</button>

                                <button
                                    onClick={sendkittobackend}
                                    style={{ backgroundColor: '#4180b7' }}>SUBMIT</button>




                            </div>

                        </div>
















                    </>}



                    {popup2 === true && <>
                        <div onClick={() => {
                            setpopup2(false)

                        }
                        } className='popupbg'></div>
                        <div className='popup'>



                            <div className='header' >
                                <h2>Kits Details</h2>
                            </div>
                            <div className='kitdetailsmain' >




                                <div className='kitdetails'>
                                    <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <GiFirstAidKit style={{ width: '20px', height: '20px', margin: "0 10px" }} /> KIT ID</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {kitdetails.kitid} </h3>
                                </div>


                                <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Practitioner Name</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>  {findpracname()}  {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>  <MdEditSquare className='hovar' style={{ width: '20px', height: '20px', margin: "0 10px" }} onClick={() => {
                                    setpopup3(true)

                                    setkitIDtoassign(kitdetails)


                                }} /></>}</h3></div>


                                <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdAttachEmail style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Practitioner Email</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>   {findpracemail()} </h3>
                                </div>


                                <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdOutlinePendingActions style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Status</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>   {kitdetails.status}

                                    {kitdetails.assignedto ? (<>
                                        {kitdetails.status !== "Kit Not received in lab yet." ? (<>
                                            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                                                <Dropdown menu={{
                                                    items:

                                                        kitdetails.status === "Results Published" ? itemsstatus2 : itemsstatus1



                                                }} placement="bottomRight" arrow>
                                                    <IoIosArrowDropdown className='hovar' style={{ width: '20px', height: '20px', margin: "0 10px" }} />
                                                </Dropdown></>}
                                        </>) : (<></>)}
                                    </>) : (<></>)}

                                </h3></div>


                                <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <MdOutlinePayments style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Payment</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}> € {kitdetailsKitprice()}

                                    {kitdetails.assignedto ? (<>
                                        <Dropdown menu={{
                                            items:
                                                kitdetails.Kitprice === "fdgbhjbdgfhjdfgsbf" ? token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ? items2 : items1 : token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" ? items : items1


                                        }} placement="bottomRight" arrow>
                                            <IoIosArrowDropdown className='hovar' style={{ width: '20px', height: '20px', margin: "0 10px" }} />
                                        </Dropdown>
                                    </>) : (<></>)}

                                </h3>
                                </div>


                                <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <TbReportMoney style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Payment Status</h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>    {kitdetailsKitpricestatus()}  </h3>
                                </div>



                                {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                                    <div className='kitdetails'><h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <TbGitBranchDeleted style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Action </h3>

                                        <Popconfirm
                                            title="Delete the KIT"
                                            description="Are you sure to delete this KIT?"
                                            onConfirm={async () => {


                                                let data = JSON.stringify({
                                                    "type": type,
                                                    "pagenumber": page,
                                                    "kitid": kitdetails._id,

                                                });

                                                let config = {
                                                    method: 'post',
                                                    maxBodyLength: Infinity,
                                                    url: apiurlforbackend + '/dltkitsss',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    data: data
                                                };

                                                await axios.request(config)
                                                    .then((response) => {
                                                        setkitbyres(response)
                                                        setpopup(false)
                                                        setpopup2(false)
                                                        setpopup3(false)

                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                    });





                                            }}

                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <h3 className='hovar' style={{ display: "flex", color: 'red', alignItems: 'center' }}>  Detele This Kit <AiFillDelete style={{ width: '20px', height: '20px', margin: "0 10px" }} /></h3>
                                        </Popconfirm>
                                    </div>

                                </>}
                            </div>



                            <div className='bownpopupbutton'>
                                <button onClick={() => {
                                    setpopup2(false)

                                }
                                } style={{ border: '1px solid red', color: 'black' }} >cancel</button>



                            </div>

                        </div>
                    </>}



                    {popup3 === true && <>
                        <div onClick={() => {
                            setpopup3(false)
                            setassignall(false)

                        }} className='popupbg'></div>
                        <div className='popup'>





                            <div className='header' >
                                <h4>Assign&#160;
                                    {assignall ? (<>All</>) : (<>
                                        ({kitIDtoassign.kitid})
                                    </>)}
                                    &#160; Kits to:</h4>

                                {dooption2()}

                                {searchdone2 === false && <>
                                    <form onSubmit={handleInputChange2} style={{ width: "fit-content" }}>
                                        <AutoComplete
                                            type="number"
                                            style={{ width: 200 }}
                                            options={options2}
                                            placeholder="Search by Email/Name"
                                            filterOption={(inputValue, options) =>
                                                options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                //  console.log(kitss) 
                                            }
                                            onChange={(inputValue) => setSearchTerm2(inputValue)}
                                        />
                                        <button>Search</button>
                                    </form>

                                </>}
                                {searchdone2 === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm2}</h3> <button onClick={clearsearch2}><AiOutlineClose /> Clear</button>  </div>
                                </>}
                            </div>


                            <div className='userbox'>


                                {practitionertoassign.map((item, index) =>

                                    <div
                                        onClick={() => setpractitionertoassignID(item._id)}

                                        className={practitionertoassignID === item._id ? 'userboxxinside slecteddd' : 'userboxxinside'}>

                                        <img alt='' src={item.profilepic} width={"25%"} />
                                        <div>
                                            <h5>Name: {item.name}</h5>
                                            <h5>email: {item.email}</h5>

                                            {item.phone !== "-" ? (<><h5>phone:{item.phone}</h5></>) : (<></>)}
                                        </div>
                                    </div>


                                )}







                            </div>





                            <div className='bownpopupbutton'>
                                <button onClick={() => {
                                    setpopup3(false)
                                    setassignall(false)

                                }
                                } style={{ border: '1px solid red', color: 'red' }} >CANCEL</button>





                                <button
                                    onClick={() => {
                                        if (practitionertoassignID !== "") {
                                            assignall ? sendkittobackend() : assignkittopra();
                                        } else {
                                            message.error("Please Select Practitioner");
                                        }
                                    }}
                                    style={{ backgroundColor: '#4180b7' }}
                                >
                                    ASSIGN
                                </button>




                            </div>

                        </div>
                    </>}




                    {kit.length !== 0 && <>
                        <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                            <thead className='tablephead'>
                                <tr>
                                    {/* <th><input type='checkbox' /></th> */}
                                    <th>S NO.</th>
                                    <th>Kit ID</th>
                                    {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && <>     <th>Assigned to</th></>}

                                    <th>Status</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>


                                {kit.map((item, index) => (<>

                                    <tr>
                                        {/* <td><input type='checkbox' /></td> */}
                                        <td>{sno2++}</td>
                                        <td>{item.kitid}</td>
                                        {(token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" || token2 === "afdksjfjsdgfjgsdugcsduygfcsdvcfgsdcfgtysdftd") && <>
                                            {item.assignedto ? (
                                                <td style={{ display: "flex", flexDirection: 'column', textAlign: 'left', }}>
                                                    {practitioner.map((item2, index) => (<>

                                                        {item2._id === item.assignedto && <>

                                                            <td style={{ border: "none", width: '50%' }}>{item2.name} </td>
                                                            <td style={{ border: "none", width: '50%' }}>{item2.email}</td>
                                                        </>}


                                                    </>))}
                                                </td>
                                            ) : (
                                                <td className='hovar' style={{ color: '#4180B7', display: "flex", alignItems: 'center', justifyContent: "center" }} onClick={() => {
                                                    setpopup3(true)
                                                    setkitIDtoassign(item)

                                                }} >< MdAssignmentReturn style={{ width: '30px', height: '30px', color: '#4180B7' }} /><h5> Assign</h5></td>

                                            )}


                                        </>}




                                        <td>{item.status}</td>




                                        <td>
                                            <button className='button'
                                                onClick={() => {
                                                    setpopup2(true)
                                                    setkitdetails(item)
                                                }}

                                            > Details</button>
                                        </td>


                                    </tr>

                                </>))}






                            </tbody>
                        </table>




                    </>}

                    {kit.length === 0 && <>
                        {totalPages === 0 ? (<>
                            <img alt='' src='/empty2.gif' width={"35%"} />
                        </>) : (<> <img alt='' src='/empty.gif' width={"40%"} /> </>)}
                    </>}
                    <div className="pagination">
                        {page > 1 && (
                            <span onClick={() => handlePageClick(page - 1)}>&lt;&lt;</span>
                        )}
                        {renderPageNumbers()}
                        {page < totalPages && (
                            <span onClick={() => handlePageClick(page + 1)}>&gt;&gt;</span>
                        )}
                    </div>
                </div>


            </div>
        </>}
    </>)
}

export default KITS