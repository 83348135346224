import React, { useState, useEffect } from 'react'

import Navbar from '../components/navbar';
import { AiFillDelete, AiOutlineMail, AiTwotoneInteraction } from "react-icons/ai"
import axios from 'axios'
import { message } from 'antd';


function Ackpage() {


    const [addkit2, setaddkit2] = useState("")
    const [addkit2p, setaddkit2p] = useState("")
    const [color, setcolor] = useState("")
    const [addkit, setaddkit] = useState([])

    const [messagetoshow, setmessagetoshow] = useState("")
    const [loading, setloading] = useState(false)




    const Addkit = async (e) => {
        await e.preventDefault()


        await setaddkit([...addkit, { Kit: addkit2, Name: addkit2p }]);
        setaddkit2("")
        setaddkit2p("")



    }


    const RemoveItemByIndex = (index) => {
        // Create a new array without the item at the specified index
        const updatedAddkit = addkit.filter((_, i) => i !== index);
        setaddkit(updatedAddkit);
    };




    const sendnewkittobackend = async () => {
        // await setloading(true)

        let data = JSON.stringify({
            "data": addkit
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_APIURL}/kitrecieved`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                setmessagetoshow(response.data.message);
                if (response.status === 200) {
                    setcolor("#6E4E9F")
                  
                }else if  (response.status === 202) {
                    setcolor("red")

                }
                setaddkit([])
            })
            .catch((error) => {
                console.log(error);
            });


        await setloading(false)

    }


    var sno = 1
    return (<>


        <Navbar />
        <div className='deshboardmain' style={{ flexDirection: "column",justifyContent:"flex-start" }}>
            <div className='popup noteee'>

                {loading ? (<>

                    <img alt='' src='/empty.gif' width={"40%"} />
                </>) : (<>

                    <form onSubmit={Addkit}>

                        <input onChange={(e) => setaddkit2(e.target.value)} value={addkit2} required placeholder='Enter the Kit ID' />

                        <input onChange={(e) => setaddkit2p(e.target.value)} value={addkit2p} required placeholder='Enter Patient Name' />

                        <button >Add</button>
                    </form>


                    {addkit.length !== 0 && <>

                        <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
                            <thead className='tablephead'>
                                <tr>
                                    <th>S NO.</th>
                                    <th>Kit ID</th>
                                    <th>Patient Name</th>

                                    <th></th>


                                </tr>
                            </thead>
                            <tbody>
                                {console.log(addkit)}
                                {addkit.map((kit, index) => (

                                    <tr>
                                        <td>{sno++}</td>

                                        <td>{kit.Kit} </td>
                                        <td>{kit.Name} </td>

                                        <td className='assignbuttom' ><AiFillDelete className='hovar' onClick={() => RemoveItemByIndex(index)} style={{ width: '30px', height: '30px', color: 'red' }} /></td>

                                    </tr>
                                ))}










                            </tbody>
                        </table>
                    </>}

                    <div className='bownpopupbutton'>
                        <button onClick={() => {

                            setaddkit([])
                            setaddkit2("")
                        }
                        } style={{ border: '1px solid red', color: 'black' }} >Clear</button>


                        <button onClick={sendnewkittobackend} style={{ backgroundColor: '#4180b7' }}>Submit</button>
                    </div>
                </>)}
            </div>

            {messagetoshow !== "" && <>
                <div className='popup noteeee' style={{color:color}}>
                    <strong>NOTE:</strong>
                    <p>{messagetoshow}</p>
                </div>

            </>}
        </div>

    </>)
}

export default Ackpage