import React from 'react'
import Navbar from '../components/navbar';
import "../css/adminreport.css"

import "../css/adminkit.css"

import Uploadresults from '../components/uploadresults';

function Bucket() {


  return (<>
    <Navbar />
    <div className='deshboardmain' style={{ justifyContent: 'center' }}>


      <Uploadresults />

    </div>



  </>)
}









export default Bucket