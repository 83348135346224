
import React, { useRef, useState } from 'react'
import Cookies from 'js-cookie';
import { BiHelpCircle, BiSolidAddToQueue } from 'react-icons/bi';
import Navbar from '../../components/navbar';
import axios from 'axios';
import Sidemanu from '../../components/sidemanu';
import "../../css/helpcenter.css"
import { useEffect } from 'react';
import { Image, message, Popconfirm, Popover } from 'antd';
import { Await, useNavigate, useParams } from 'react-router-dom';
import { FaRegUserCircle } from 'react-icons/fa';
// import Swiper from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { imageDb } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";


function Teamticketsdetails() {
  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const userid = Cookies.get("id")
  const token = Cookies.get("Token")
  const [tickets, settickets] = useState([])
  const [reply, setreply] = useState("")
  const { id } = useParams();
  const navigate = useNavigate()
  const apiurlforbackend = process.env.REACT_APP_APIURL
  const [files, setfiles] = useState([]);
  const [messagecontent, setmessagecontent] = useState("");
  const [admins, setadmin] = useState([])
  const [mainloding, setmainloding] = useState(false)
  const [loadingsendmsg, setloadingsendmsg] = useState(false)



  useEffect(() => {
    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {


      getadmin()


      const interval = setInterval(() => {
        getticketdetails()
      }, 1000);


      return () => clearInterval(interval);

    }
  }, [token, id]); // Dependencies array to rerun on token or id change

  const getticketdetails = () => {

    let data = JSON.stringify({
      "id": id
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiurlforbackend + '/getteamticketsbyid',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        settickets(response.data);


      })
      .catch((error) => {
        console.log(error);
      });

  }

  useEffect(() => {
    // Check if there are tickets and messages and if mainloding is false
    if (!(tickets.length === 0) && tickets.messages.length > 0 && !mainloding) {
      // Scroll to the bottom of the messages
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

      // Set mainloding to true to prevent additional scrolls
      setmainloding(true);
    }
  }, [tickets]);

  const getadmin = async () => {



    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: apiurlforbackend + "/getalladmin",
      headers: {
        'Content-Type': 'application/json'
      },

    };

    await axios.request(config)
      .then((response) => {
        setadmin(response.data);
      })
      .catch((error) => {
        console.log(error);
      });




  }


  const handleFileUpload = (e) => {
    const fileList = e.target.files; // FileList object
    const filesArray = Array.from(fileList); // Convert to an array

    setfiles((prevFiles) => {
      // Filter files: only allow CSV and PDF, and avoid duplicates
      const newFiles = filesArray.filter(
        (newFile) =>

          !prevFiles.some(
            (existingFile) =>
              existingFile.name === newFile.name && existingFile.size === newFile.size
          )
      );

      // Return the updated files list
      return [...prevFiles, ...newFiles];
    });
  };






  const handleFileDrop = (e) => {
    e.preventDefault();


    const fileList = e.dataTransfer.files; // FileList object
    const filesArray = Array.from(fileList); // Convert to an array


    setfiles((prevFiles) => {
      // Filter files: only allow CSV and PDF, and avoid duplicates
      const newFiles = filesArray.filter(
        (newFile) =>

          !prevFiles.some(
            (existingFile) =>
              existingFile.name === newFile.name && existingFile.size === newFile.size
          )
      );

      // Return the updated files list
      return [...prevFiles, ...newFiles];
    });
  };




  const messagesEndRef = useRef(null); // Reference to the bottom of the message list





  const handlePaste = (e) => {
    const clipboardData = e.clipboardData;
    const items = clipboardData.items;

    // Check if the pasted content is a file
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (file) {
          // Check if the file is either a CSV or PDF

          console.log(file);

          setfiles((prevFiles) => {
            // Filter files: avoid duplicates based on name and size
            const newFiles = prevFiles.filter(
              (existingFile) =>
                !(existingFile.name === file.name && existingFile.size === file.size)
            );

            // Return the updated files list, adding the new file if it's unique
            return [...newFiles, file];
          });

        }
        return;
      }
    }

    // If no file was found in the paste, you can handle text normally (optional)
    // const text = clipboardData.getData('text');
    // You can set text to the message content here if needed
    // setMessageContent(text);
  };





  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {

      e.preventDefault();
      document.getElementById('submittt').click()

    }
  };


  const [read, setRead] = useState(0);
  const [readc, setReadc] = useState(false);

  const checkingMessages = async () => {

    // if (message.by !== userid && !message.seenby.includes(userid) && readc) {

    //   await setRead(index);
    //   await setReadc(false);
    //   console.log("sfvsd")
    // }
    let check = false
    let indexofmessage

    await tickets.messages.forEach(async (message, index) => {
      // console.log(index);

      if (message.by !== userid && !message.seenby.includes(userid) && !check) {

        // await setRead(index);
        // await setReadc(false);
        console.log(index)
        check = true
        indexofmessage = index

      }


    });
    setRead(indexofmessage)
    setReadc(true)

  };


  const markmessageread = async () => {


    await tickets.messages.forEach(async (message, index) => {
      // console.log(index);

      if (message.by !== userid && !message.seenby.includes(userid)) {


        let data = JSON.stringify({
          "userid": userid,
          "ticketid": id,
          "messageindex": index
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: apiurlforbackend + '/messagereaddd',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };

        await axios.request(config)
          .then((response) => {
            console.log(response.data)


          })
          .catch((error) => {
            console.log(error);
          });



      }


    });

  }


  useEffect(() => {
    if (!(tickets.length === 0) && !readc) {
      // tickets.messages.forEach((value, index) => {
      //   if (readc) {
      checkingMessages();

      //   }
      // });
    } else if (!(tickets.length === 0)) {
      markmessageread();

    }
  }, [tickets]);


  // const textarea = document.querySelector('.input-box');

  // textarea.addEventListener('input', () => {
  //   // Reset height to 'auto' to shrink if content is removed
  //   textarea.style.height = 'auto';
  //   // Set the height to scrollHeight so it grows with the content
  //   textarea.style.height = `${textarea.scrollHeight}px`;
  // });


  return (
    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
        <Navbar />
        <div className='deshboardmain'>
          <Sidemanu />
          <div className='adminkitmainbody'>
            <div className='header'>
              <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><BiHelpCircle style={{ width: '50px', height: '50px' }} />Task Details</h1>
            </div>





            {tickets.length !== 0 && <>
              <div className='header' style={{ marginTop: '50px' }} >
                <h2>Task Id : {tickets.ticketID}</h2>
              </div>
              {/* <div className='questionbox2'>
                <div className='questionbox211'>
                  <h4 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center', margin: '0px', padding: '0px' }}>{tickets.priority}</h4>

                </div>
                <h4 style={{ color: '#4180B7', display: 'flex', alignItems: 'center', margin: '0px', padding: '0px' }}>Genrated By: {admins.find(value2 => tickets.givenby === value2._id).name}</h4>
                <h4 style={{ color: '#4180B7', display: 'flex', alignItems: 'center', margin: '0px', padding: '0px' }}>To : {tickets.to.map((value) => <>{admins.find(value2 => value === value2._id).name},</>)}</h4>
                <h4 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center', margin: '0px', padding: '0px' }}>Subject: {tickets.subject}</h4>

                {tickets.attachment === "no file" && <>    <h4 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center', margin: '0px', padding: '0px' }}><button className='button' onClick={() => {
                  const link = document.createElement('a');
                  link.href = tickets.attachment;
                  link.setAttribute('download', ``);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}>Click Here to download attachment</button></h4></>}

                <div style={{ marginTop: '50px' }}></div>
                <h4 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center', margin: '0px', padding: '0px' }}>Message : </h4>
                <p style={{ display: 'flex', textAlign: 'left' }}>

                  <span dangerouslySetInnerHTML={{ __html: tickets.content.replace(/\n/g, "<br />") }} />
                </p>
                <div style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>

                  {!tickets.Status ? (<>
                    <button className='button' onClick={async () => {
                      const hide = message.loading("Action in progress", 0)
                      var myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                      var urlencoded = new URLSearchParams();
                      urlencoded.append("id", tickets._id);
                      urlencoded.append("status", !tickets.Status);

                      var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: 'follow'
                      };


                      await fetch(apiurlforbackend + "/changeteamtitckstatus", requestOptions)
                        .then(response => response.json())
                        .then(result => {

                          settickets(result)

                        })
                        .catch(error => console.log('error', error));





                      await setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success("Action completed successfully");
                      },);

                    }}>Close This Ticket</button>

                  </>) : (<>
                    <button className='button' onClick={async () => {
                      const hide = message.loading("Action in progress", 0)
                      var myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                      var urlencoded = new URLSearchParams();
                      urlencoded.append("id", tickets._id);
                      urlencoded.append("status", !tickets.Status);

                      var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: 'follow'
                      };


                      await fetch(apiurlforbackend + "/changeteamtitckstatus", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                          settickets(result)

                        })
                        .catch(error => console.log('error', error));





                      await setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success("Action completed successfully");
                      },);

                    }}>Open This Ticket</button>

                  </>)}



                 
                </div>
              </div> */}


              <div className='ticketcantainer' >


                <div className='ticketcantainerinside'>

                  <div className='rebinforp2'>
                    {tickets.priority}
                  </div>

                  <div className='ticketcantainerinside_header'><div><h4>Genrated By: </h4> <p> {admins.find(value2 => tickets.givenby === value2._id).name}</p></div>   <div><h4>To: </h4>  <p>{tickets.to.map((name2, index) => <>{admins.find(value2 => name2 === value2._id).name} {index !== tickets.to.length - 1 && <>,</>}</>)}</p></div>    </div>

                  <div className='ticketcantainerinside_header'><div><h4>Subject: </h4> <p> {tickets.subject}</p></div>       </div>



                  <p style={{ display: 'flex', textAlign: 'left', margin: '0', alignItems: 'center', gap: '10px' }}>
                    <h4 >Message: </h4>
                    {/* <span dangerouslySetInnerHTML={{ __html: value.content.replace(/\n/g, "<br />") }} /> */}
                    <span dangerouslySetInnerHTML={{ __html: tickets.content }} />
                  </p>


                  <div className='dltbtndiv' style={{ justifyContent: 'space-between' }}>



                    <button className='button' onClick={async () => {
                      const hide = message.loading("Action in progress", 0)
                      var myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                      var urlencoded = new URLSearchParams();
                      urlencoded.append("id", tickets._id);
                      urlencoded.append("status", !tickets.Status);

                      var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: 'follow'
                      };


                      await fetch(apiurlforbackend + "/changeteamtitckstatus", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                          settickets(result)

                        })
                        .catch(error => console.log('error', error));





                      await setTimeout(() => {
                        hide(); // Call hide to stop the loading message
                        message.success("Action completed successfully");
                      },);

                    }}
                    >{tickets.Status? (<>Open Again</>):(<>Mark As Completed</>)}   </button>


                    <Popconfirm
                      title="Delete the file"
                      description="Are you sure to delete this file?"
                      onConfirm={async () => {

                        navigate("/dashboard/teams/tickets")

                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                        var urlencoded = new URLSearchParams();
                        urlencoded.append("_id", tickets._id);

                        var requestOptions = {
                          method: 'POST',
                          headers: myHeaders,
                          body: urlencoded,
                          redirect: 'follow'
                        };


                        await fetch(apiurlforbackend + "/dltteamtickets", requestOptions)
                          .then(response => response.json())
                          .then(result => {
                            navigate("/dashboard/teams/tickets")

                          })
                          .catch(error => console.log('error', error));







                      }}

                      okText="Yes"
                      cancelText="No"
                    >
                      <button className='dltbtn' style={{ backgroundColor: '#fff' }}>Delete This Ticket</button>
                    </Popconfirm>
                  </div>


                </div>

              </div>



              <div className='header' style={{ marginTop: '20px' }} >

              </div>
              <div className='Messagearea'>

                <div className='Messageareashow'>





                  {tickets.messages.map((value, index) => {


                    let classname = "message-container"
                    let classname2 = "rec"
                    let classname3 = "messagebubbble2"




                    if (value.by === userid) {
                      classname = "message-container sideee";
                      classname2 = "send"
                      classname3 = "messagebubbble3"
                    } else {
                      classname = "message-container";
                      classname2 = "rec"
                      classname3 = "messagebubbble2" // or any default class name
                    }




                    return (<>

                      {index === read && <><div className='newmessagesline'>  <h4>NEW UNREAD MESSAGES</h4> </div></>}
                      <div className={classname} >


                        <div  className={classname3}>


                          {classname2 === "rec" && <>  <FaRegUserCircle style={{ width: '50px', height: '50px', color: '#6E4E9F' }} /></>}
                          <div className={`message-bubble ${classname2}`}>
                            <span className="date-text2">{admins.find(value2 => value.by === value2._id).name}</span>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>


                              {value.file.map((file, index) => {
                                // Check if the file is an image by its extension (for example, .jpg, .png, etc.)
                                const isImage = file.type.startsWith('image/');

                                return (
                                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    {isImage ? (
                                      <>
                                        <Image src={file.url} width={"100px"} height={"80px"} />
                                      </>
                                    ) : (

                                      <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="85px" viewBox="0 0 1024 1024" version="1.1" style={{ color: '#fff' }} >
                                        <path d="M719.8 651.8m-10 0a10 10 0 1 0 20 0 10 10 0 1 0-20 0Z" fill="#E73B37" />
                                        <path d="M512.1 64H172v896h680V385.6L512.1 64z m278.8 324.3h-280v-265l280 265zM808 916H216V108h278.6l0.2 0.2v296.2h312.9l0.2 0.2V916z" fill="#fff" />


                                        <path d="M403.5 641.8h277v16h-277z" fill="#fff" />
                                        <path d="M280.6 641.8h91.2v16h-91.2z" fill="#E73B37" />
                                        <path d="M279.9 753.7h326.5v16H279.9z" fill="#fff" />
                                        <path d="M655.8 753.7h73.9v16h-73.9z" fill="#E73B37" />
                                        <text x="50%" y="50%" fill="#fff" fontSize="70" fontWeight="bold" textAnchor="middle" alignmentBaseline="middle">
                                          {file.type}
                                        </text>
                                      </svg>


                                    )}
                                  </div>
                                );
                              })}
                            </div>  <p className="message-text" style={{ color: "#fff" }}> <span dangerouslySetInnerHTML={{ __html: value.content.replace(/\n/g, "<br />") }} /></p>
                            <span className="date-text">{value.date} {value.time} </span>
                          </div>
                          {classname2 === "send" && <>   <FaRegUserCircle style={{ width: '50px', height: '50px', color: '#BCC2FE' }} /></>}


                        </div>
                      </div >


                      {((index === tickets.messages.length - 1) && loadingsendmsg === true) && <>
                        <div className="message-container sideee" >


                          <div className={classname3}>


                            {classname2 === "rec" && <>  <FaRegUserCircle style={{ width: '50px', height: '50px', color: '#6E4E9F' }} /></>}
                            <div className={`message-bubble ${classname2}`}>
                              <span className="date-text2">{admins.find(value2 => userid === value2._id).name}</span>
                              <div style={{ display: 'flex', flexWrap: 'wrap' }}>



                              </div>  <p className="message-text" style={{ color: "#fff" }}> <img src='/empty.gif' width={"100px"} /></p>

                            </div>
                            {classname2 === "send" && <>   <FaRegUserCircle style={{ width: '50px', height: '50px', color: '#BCC2FE' }} /></>}


                          </div>
                        </div >
                      </>}

                    </>)
                  })}
                  <div ref={messagesEndRef} />


                </div>

                {!tickets.Status ? (<>



                  <div className="input-container"

                    onDrop={handleFileDrop}
                    onDragOver={(e) => e.preventDefault()}


                  >
                    <div style={{ display: 'flex', padding: '5px' }}>

                      {files.map((file, index) => {
                        // Check if the file is an image by its extension (for example, .jpg, .png, etc.)
                        const isImage = file.type.startsWith('image/');

                        return (
                          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            {isImage ? (
                              <>
                                <Popconfirm
                                  title="Delete the file"
                                  description="Are you sure to delete this file?"
                                  onConfirm={() => {
                                    setfiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
                                  }}

                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <img style={{ border: '1px solid gray', cursor: 'pointer' }} src={URL.createObjectURL(file)} height="60px" alt="file" />
                                </Popconfirm>
                              </>
                            ) : (

                              <>
                                <Popconfirm
                                  title="Delete the file"
                                  description="Are you sure to delete this file?"
                                  onConfirm={() => {
                                    setfiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
                                  }}

                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 1024 1024" className="icon" version="1.1" style={{ marginRight: '10px' }}>
                                    <path d="M719.8 651.8m-10 0a10 10 0 1 0 20 0 10 10 0 1 0-20 0Z" fill="#E73B37" />
                                    <path d="M512.1 64H172v896h680V385.6L512.1 64z m278.8 324.3h-280v-265l280 265zM808 916H216V108h278.6l0.2 0.2v296.2h312.9l0.2 0.2V916z" fill="#39393A" />


                                    <path d="M403.5 641.8h277v16h-277z" fill="#39393A" />
                                    <path d="M280.6 641.8h91.2v16h-91.2z" fill="#E73B37" />
                                    <path d="M279.9 753.7h326.5v16H279.9z" fill="#39393A" />
                                    <path d="M655.8 753.7h73.9v16h-73.9z" fill="#E73B37" />
                                    <text x="50%" y="50%" fill="#000000" fontSize="70" fontWeight="bold" textAnchor="middle" alignmentBaseline="middle">
                                      {file.type}
                                    </text>
                                  </svg>
                                </Popconfirm>
                              </>
                            )}
                          </div>
                        );
                      })}


                    </div>
                    <form
                      onSubmit={async (e) => {
                        e.preventDefault()
                        const textarea = document.querySelector('.input-box');


                        // Reset height to 'auto' to shrink if content is removed
                        textarea.style.height = '30px';
                        await setloadingsendmsg(true)
                        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
                        await setmessagecontent("")
                        await setfiles([])

                        await setRead(-1)
                        const date2 = Date.now()
                        const date = new Date(date2);

                        const formattedDate = date.toLocaleDateString(); // This will give you the date in the format "1/17/2025"
                        const formattedTime = date.toLocaleTimeString();

                        let filestosend = []

                        for (const file of files) {

                          const isImage = file.type

                          const imgRef = await ref(imageDb, `teamticketfiles/${file.name}`)
                          await uploadBytes(imgRef, file)
                          const filefirebaseurl = await getDownloadURL(imgRef);

                          const data = { type: isImage, url: filefirebaseurl }

                          await filestosend.push(data)

                        }


                        const messagetosent =
                        {

                          content: messagecontent,
                          date: formattedDate,
                          time: formattedTime,
                          by: userid,
                          file: filestosend,
                          seenby: []
                        }

                        let data = JSON.stringify({
                          "message": messagetosent,
                          "id": tickets._id
                        });

                        let config = {
                          method: 'post',
                          maxBodyLength: Infinity,
                          url: apiurlforbackend + '/messageinteamticketseen',
                          headers: {
                            'Content-Type': 'application/json'
                          },
                          data: data
                        };

                        await axios.request(config)
                          .then((response) => {
                            settickets(response.data);

                          })
                          .catch((error) => {
                            console.log(error);
                          });

                        await setloadingsendmsg(false)


                      }}>
                      <div className="icons">
                        <span className="icon" onClick={() => document.getElementById('fileInput').click()}>+</span>
                        <input
                          type="file"
                          id="fileInput"
                          multiple
                          style={{ display: 'none' }}
                          onChange={handleFileUpload}
                        />

                        <textarea onKeyDown={handleKeyDown} onPaste={handlePaste} value={messagecontent} onChange={(e) => {
                          setmessagecontent(e.target.value)
                          const textarea = document.querySelector('.input-box');


                          // Reset height to 'auto' to shrink if content is removed
                          textarea.style.height = '30px';

                          if (textarea.scrollHeight < 100) {
                            // Set the height to scrollHeight so it grows with the content
                            textarea.style.height = `${textarea.scrollHeight}px`;
                          } else {
                            textarea.style.height = `${100}px`;
                          }

                        }} className="input-box" placeholder="Type your message..." required={files.length === 0}></textarea>

                        <span type="submit" className="icon" onClick={() => document.getElementById('submittt').click()}>&#x27A4;</span>
                        <input
                          type="submit"
                          id="submittt"
                          style={{ display: 'none' }}

                        />
                      </div>
                    </form>
                  </div>

                </>) : (<>

                  <p style={{ color: 'gray' }}>Task is Completed you need to open the Task in order to reply </p>
                </>)}
              </div>
            </>}
          </div>
        </div>
      </>}
    </div >
  )
}

export default Teamticketsdetails
