import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import "../css/Practitioner.css"
import { HiUserAdd } from "react-icons/hi"
import { AiFillDelete, AiFillEdit, AiOutlineClose } from "react-icons/ai"
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { message, AutoComplete, Image, Popconfirm, Button } from 'antd';
import { FaDotCircle, FaPen } from "react-icons/fa";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { FaRegCircleXmark, FaUserDoctor } from 'react-icons/fa6';
import { ImUpload2 } from 'react-icons/im'
import { v4 } from 'uuid';
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import axios from "axios"
import { TiPlusOutline } from "react-icons/ti";


function Practitioner(prams) {
  var sno1 = 1
  var sno2 = 1

  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")


  const Navigate = useNavigate();
  const [popup, setpopup] = useState(false)

  const [popupdetails2, setpopupdetails2] = useState(false)
  const [popupedit, setpopupedit] = useState(false)
  const [popup2, setpopup2] = useState(false)
  const [changepasswordshow, setchangepasswordshow] = useState(false)
  const [practitionername, setpractitionername] = useState("")
  const [practitioneremail, setpractitioneremail] = useState("")
  const [idtochangepass, setidtochangepass] = useState("")
  const [practitionerpassword, setpractitionerpassword] = useState("")
  const [practitionercard, setpractitionercard] = useState(false)
  const [kit, setkit] = useState([])

  const [editpassword, seteditpassword] = useState("")


  const token = Cookies.get("Token")
  const type = `${prams.kittype}`
  const [page, setpage] = useState(1)
  const [page2, setpage2] = useState(1)
  const apiurlforbackend = process.env.REACT_APP_APIURL
  const [totalPages, settotalPages] = useState(1)
  const [totalPages2, settotalPages2] = useState(1)

  const [kitids, setkitids] = useState([])
  const [popupdetails, setpopupdetails] = useState(false)

  const [userinfo, setuserinfo] = useState({})
  const [loading, setloading] = useState(false)
  const [loading2, setloading2] = useState(false)
  const [loading3, setloading3] = useState(false)
  const [loading4, setloading4] = useState(false)

  const [practitioner, setpractitioner] = useState([])
  const [practitioner2, setpractitioner2] = useState([])

  const [admin, setadmin] = useState([])

  var sno2 = 1
  var sno1 = 1 + (15 * (page - 1))

  var options = kitids;





  const addnewPractitioner = async (e) => {
    e.preventDefault()

    await setloading(true)

    let data = JSON.stringify({
      "pagenumber": page,
      "name": practitionername,
      "email": practitioneremail,
      "password": practitionerpassword,
      "card": practitionercard,


    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiurlforbackend + '/addpractitioner',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios.request(config)
      .then((response) => {
        setpracbyres(response)

        if (response.status === 202) {
          message.error("Practitioner Already in List")
          setloading(false)
        } else {


          setpopup(false)
          setloading(false)
          setpractitioneremail('')
          setpractitionername('')
          setpractitionerpassword('')
        }

      })
      .catch((error) => {
        console.log(error);
      });


  }




  // useEffect(() => {


  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



  //   var requestOptions = {
  //     method: 'GET',
  //     headers: myHeaders,

  //     redirect: 'follow'
  //   };

  //   fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions)
  //     .then(response => response.json())
  //     .then(result => setpractitioner(result))


  //   var myHeaders2 = new Headers();
  //   myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



  //   var requestOptions2 = {
  //     method: 'GET',
  //     headers: myHeaders2,

  //     redirect: 'follow'
  //   };

  //   fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions2)
  //     .then(response => response.json())
  //     .then(result => setkit(result))








  // }, [])




  // var count = 0

  // const [searchTerm, setSearchTerm] = useState('');
  // var options = [];

  // const dooption = () => {
  //   practitioner.map((value, index) => {

  //     // options = [
  //     //   { value: 'Burns Bay Road' },

  //     // ];
  //     // const newvalue = toString(value.kitid)
  //     options.push({ value: value.name })
  //     options.push({ value: value.email })


  //   })


  // }



  // const handleInputChange = (e) => {
  //   e.preventDefault()






  //   const intttt = (searchTerm)

  //   // Filter the Kit array based on the user's input
  //   const filteredSuggestions = practitioner.filter((item) =>

  //     item.name.toLowerCase().includes(intttt.toLowerCase()) || item.email.toLowerCase().includes(intttt.toLowerCase())



  //   );


  //   setpractitioner(filteredSuggestions)
  //   setsearchdone(true)

  // };




  // const [searchdone, setsearchdone] = useState(false)

  // const clearsearch = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



  //   var requestOptions = {
  //     method: 'GET',
  //     headers: myHeaders,

  //     redirect: 'follow'
  //   };

  //   fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions)
  //     .then(response => response.json())
  //     .then(result => setpractitioner(result))


  //   var myHeaders2 = new Headers();
  //   myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



  //   var requestOptions2 = {
  //     method: 'GET',
  //     headers: myHeaders2,

  //     redirect: 'follow'
  //   };

  //   fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions2)
  //     .then(response => response.json())
  //     .then(result => setkit(result))


  //   setsearchdone(false)

  // }





  // const [userinfo, setuserinfo] = useState([])



  // const changecard = () => {


  //   const isSure = window.confirm("Are you certain you want to proceed? Initiating a card change will erase your existing card details, necessitating a subsequent login to securely add a new card.");
  //   if (isSure) {





  //     var myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  //     var urlencoded = new URLSearchParams();
  //     urlencoded.append("id", userinfo._id);

  //     var requestOptions = {
  //       method: 'POST',
  //       headers: myHeaders,
  //       body: urlencoded,
  //       redirect: 'follow'
  //     };

  //     fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileKitsinfochnagecard", requestOptions)
  //       .then(response => response.text())
  //       .then(result => {
  //         if (result === "cardremoved") {
  //           const allCookies = Cookies.get();
  //           for (const cookieName in allCookies) {
  //             Cookies.remove(cookieName);
  //           }
  //           // navigate('/')
  //         } else { message.error("Something wrong") }
  //       })
  //       .catch(error => console.log('error', error));









  //   }

  // }




  // // var coutppp = 0




  // // var padingres = 0

  // // const cout = () => {

  // //   coutppp = kit.filter(item => 'result' in item && item.result.length !== 0).length;
  // //   padingres = kit.length - coutppp;
  // // }


  // const [editname, seteditname] = useState("")
  // const [editemail, seteditemail] = useState("")
  // const [editaddres, seteditaddres] = useState("")
  // const [editphone, seteditphone] = useState("")
  // const [editabout, seteditabout] = useState("")
  // const [billingpostcode, setbillingpostcode] = useState("")

  const [img, setimg] = useState("/avatar.png")
  const [img2, setimg2] = useState("/avatar.png")


  // const subminupdatedata = async (e) => {
  //   // e.preventDefault()


  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  //   var urlencoded = new URLSearchParams();
  //   urlencoded.append("_id", userinfo._id);
  //   urlencoded.append("password", "admin");
  //   urlencoded.append("billingpostcode", billingpostcode);
  //   urlencoded.append("address", editaddres);
  //   urlencoded.append("phone", editphone);
  //   urlencoded.append("about", editabout);
  //   urlencoded.append("email", editemail);
  //   urlencoded.append("name", editname);

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: urlencoded,
  //     redirect: 'follow'
  //   };

  //   await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileedit", requestOptions)
  //     .then(response => response.json())
  //     .then(result => {



  //       setpopupedit(false)


  //     })
  //     .catch(error => console.log('error', error));





  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



  //   var requestOptions22 = {
  //     method: 'GET',
  //     headers: myHeaders,

  //     redirect: 'follow'
  //   };

  //   await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions22)
  //     .then(response => response.json())
  //     .then(result => setpractitioner(result))


  //   var myHeaders2 = new Headers();
  //   myHeaders2.append("Content-Type", "application/x-www-form-urlencoded");



  //   var requestOptions2 = {
  //     method: 'GET',
  //     headers: myHeaders2,

  //     redirect: 'follow'
  //   };

  //   await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallkits", requestOptions2)
  //     .then(response => response.json())
  //     .then(result => setkit(result))






  // }




  // const counttttkit = (type) => {
  //   var whatsreturn = "0"
  //   var total = 0

  //   kit.map((value) => {

  //     if (type === value.Kittype && userinfo._id === value.assignedto) {
  //       total = total + 1
  //     }


  //   })



  //   if (type === "100") {
  //     whatsreturn = "fs 100(" + total + ")"
  //   } else
  //     if (type === "210") {
  //       whatsreturn = "fs 210(" + total + ")"
  //     } else {
  //       whatsreturn = total
  //     }
  //   return (
  //     whatsreturn
  //   )
  // }



  // const Conuttttttt2 = () => {
  //   var whatreturn = "0"

  //   var total2 = 0



  //   kit.map((value) => {

  //     if ((value.result.length === 0 || value.result2.length === 0) && userinfo._id === value.assignedto && (value.Kittype === "100" || value.Kittype === "210")) {
  //       total2 = total2 + 1
  //     }
  //     if ((value.otherresults === "nil") && userinfo._id === value.assignedto && (value.Kittype !== "100" && value.Kittype !== "210")) {
  //       total2 = total2 + 1
  //     }


  //   })
  //   whatreturn = total2




  //   return whatreturn

  // }
  // const Conuttttttt = () => {
  //   var whatreturn = "0"
  //   var total = 0




  //   kit.map((value) => {

  //     if ((value.result || value.result2 || value.otherresults !== "nil") && userinfo._id === value.assignedto) {
  //       total = total + 1
  //     }


  //   })
  //   whatreturn = total



  //   return whatreturn

  // }

  const handleImgChange = (e) => {
    setimg(URL.createObjectURL(e.target.files[0]));
    setimg2(e.target.files[0])
  };

  const updateimage = async (e) => {
    e.preventDefault()
    setloading3(true)
    const Carimageid = v4();

    const imgRef = ref(imageDb, `profile/${Carimageid}`)
    await uploadBytes(imgRef, img2)
    const cvUrl = await getDownloadURL(imgRef);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded2 = new URLSearchParams();
    urlencoded2.append("profilepic", cvUrl);
    urlencoded2.append("_id", userinfo.practitioner._id);

    var requestOptions2 = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded2,
      redirect: 'follow'
    };


    await fetch(apiurlforbackend + "/updateimage", requestOptions2)
      .then(response => response.json())
      .then(result => {

        // setuserinfo(result.data.practitioners[0])
        setuserinfo(result.practitioners[0])
        message.success(result.message)
      })
      .catch(error => console.log('error', error));

    setloading3(false)
    setpopupdetails2(false)

  }








  const setpracbyres = async (response) => {

    setpractitioner(response.data.practitioners);
    setpractitioner2(response.data.practitioners);
    setpage(response.data["current page"])
    setpage2(response.data["current page"])
    settotalPages(response.data["total pages"])
    settotalPages2(response.data["total pages"])


  }




  const getprac = async (page) => {




    let data = JSON.stringify({
      "pagenumber": page,
      "type": type,

    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiurlforbackend + '/getpracbytypeall',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios.request(config)
      .then((response) => {
        setpracbyres(response)


      })
      .catch((error) => {
        console.log(error);
      });


  }



  const getkitsids = async () => {




    let data = JSON.stringify({

      "type": type,

    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiurlforbackend + '/getpracnameandemailall',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios.request(config)
      .then((response) => {
        setkitids(response.data.pracname);

      })
      .catch((error) => {
        console.log(error);
      });


  }


  const getadmin = () => {

    var myHeaders4 = new Headers();
    myHeaders4.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions4 = {
      method: 'GET',
      headers: myHeaders4,

      redirect: 'follow'
    };

    fetch(apiurlforbackend + "/getalladmin", requestOptions4)
      .then(response => response.json())
      .then(result => setadmin(result))



  }

  useEffect(() => {

    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      getprac(page)
      getkitsids()
      getadmin()
    }
  }, [])




  const [searchTerm, setSearchTerm] = useState('');
  const handleInputChange = async (e) => {
    e.preventDefault()

    setpractitioner([])


    let data = JSON.stringify({

      "type": type,
      "searchString": searchTerm,

    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiurlforbackend + '/searchpracbytypeall',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios.request(config)
      .then(async (response) => {

        await setpractitioner(response.data.practitioners)
        await setpage(1)
        await settotalPages(1)
        await renderPageNumbers()

      })
      .catch((error) => {
        console.log(error);
      });

    setsearchdone(true)

  };




  const [searchdone, setsearchdone] = useState(false)

  const clearsearch = async () => {



    await setpractitioner(practitioner2)
    await setpage(page2)
    await settotalPages(totalPages2)
    await renderPageNumbers()

    setsearchdone(false)

  }


  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageToShow = 3; // Show maximum 3 pages on each side of the current page
    const startPage = Math.max(1, page - maxPageToShow);
    const endPage = Math.min(totalPages, page + maxPageToShow);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <span
          key={i}
          onClick={() => handlePageClick(i)}
          className={page === i ? 'activeeee' : ''}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  };



  const handlePageClick = async (pageNumber) => {
    await setpractitioner([])
    // await window.scrollTo(0, 0);
    await getprac(pageNumber)

    await renderPageNumbers()
  };



















  const viewkitsbar = (kitsofprac) => {

    const kitlength = kitsofprac.length


    const data = kitsofprac
    const mbkits = userinfo.practitioner.mbkits - parseInt(userinfo.kits.filter(kit => (kit.Kittype === "MicrobiomeAdvanced" || kit.Kittype === "MicrobiomePlus")).length); // Example: 2 microbiome kits

    // Aggregate the data
    const summary = data.reduce((acc, item) => {
      const { Kittype } = item;
      if (!acc[Kittype]) {
        acc[Kittype] = 0;
      }
      acc[Kittype]++;
      return acc;
    }, {});

    // Add mbkits data if mbkits > 0
    if (mbkits > 0) {
      summary["MICROBIOME"] = mbkits;
    }

    // Constants for percentage adjustment
    const totalPerson = 100; // Assuming total person weight is 100
    const minPercent = 4;    // Minimum 4%
    const maxPercent = 94;   // Maximum 94%

    // Calculate total and format the result
    const total = Object.values(summary).reduce((a, b) => a + b, 0);
    const result = Object.entries(summary).map(([kittype, count]) => {
      // Raw percentage
      const rawPercent = (count / total) * 100;

      // Adjusted percentage (linear mapping to 4%-94% range)
      const adjustedPercent = minPercent + (rawPercent / 100) * (maxPercent - minPercent);

      return {
        value: kittype,
        howmany: count,
        person: adjustedPercent
      };

    });


    const barssss = result













    return (<>

      <div className='kitsbarrr' style={{ width: '96%', alignItems: 'center', marginBottom: '50px' }}>



        {barssss.map((valueofbars) => (<>

          <div className='barmain'>
            <div className='barfill'><div className='barfill2' style={{ width: `${valueofbars.person}%` }}>      <h3>{valueofbars.howmany}</h3></div></div>
            <div className='barname'><h3> {valueofbars.value} KITS</h3></div>
          </div>

        </>))}
      </div> </>)
  }



  const makeactandinact = async (id, pracstatus) => {

    let data = JSON.stringify({
      "pagenumber": page,

      "id": id,
      "pracstatus": pracstatus,

    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiurlforbackend + '/changepracstatus',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios.request(config)
      .then((response) => {
        setpracbyres(response)


      })
      .catch((error) => {
        console.log(error);
      });


  }


  const [addnoteform, setaddnoteform] = useState(false)

  const [showwhiteboard, setshowwhiteboard] = useState(false)


  const [addnote, setaddnote] = useState("")
  const [addstickynotetitle, setaddstickynotetitle] = useState("")
  const [addstickynotecontent, setaddstickynotecontent] = useState("")
  const [loading6, setloading6] = useState(false)


  const [addstickynoteform, setaddstickynoteform] = useState(false)


  const DateComponent = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    const formattedDate = `${day}-${month}-${year} ${hours}-${minutes}`;


    return formattedDate


  };


  const [salsesmaneger, setsalsesmaneger] = useState("")
  const [salsesmanegerform, setsalsesmanegerform] = useState(false)
  const [loading7, setloading7] = useState(false)



  const [reportstyle, setreportstyle] = useState("")
  const [reportstyleform, setreportstyleform] = useState(false)
  const [loading8, setloading8] = useState(false)


  return (<>
    {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
      <Navbar />
      <div className='deshboardmain'>
        <Sidemanu />
        <div className='Practitionermainbody'>


          <div className='header'>
            <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><FaUserDoctor style={{ width: '35px', height: '35px' }} />All Practitioners</h1>



            {searchdone === false && <>
              <form onSubmit={handleInputChange}>
                <AutoComplete

                  style={{ width: 200 }}

                  options={options}
                  placeholder="Search by Email/Name"
                  filterOption={(inputValue, options) =>
                    options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1

                  }
                  onChange={(inputValue) => setSearchTerm(inputValue)}
                />
                <button>Search</button>
              </form>

            </>}
            {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
            </>}
          </div>





          <div className='addbutton'><button onClick={() => setpopup(true)}><HiUserAdd style={{ width: '20px', height: '20px' }} />Add Practitioner</button></div>


          {popup === true && <>
            <div onClick={() => {
              setpopup(false)
              setpractitioneremail('')
              setpractitionername('')
              setpractitionerpassword('')

            }
            } className='popupbg'></div>
            <div className='popup'>

              {loading ? (<>
                <img alt='' src='/empty.gif' width={"40%"} />
              </>) : (<>
                <form
                  onSubmit={addnewPractitioner}
                  className='addPractitioner'>
                  <input autoComplete="none" style={{ borderRadius: '5px' }} type='name' value={practitionername} onChange={(e) => setpractitionername(e.target.value)} required placeholder='Practitioner Name' />
                  <input autoComplete="none" style={{ borderRadius: '5px' }} onChange={(e) => setpractitioneremail(e.target.value)} type="email" name='new' value={practitioneremail} required placeholder='Practitioner Email' />
                  <input autoComplete="none" style={{ borderRadius: '5px' }} onChange={(e) => setpractitionerpassword(e.target.value)} type="password" name='nedfvw' value={practitionerpassword} required placeholder='Password for Practitioner' />

                  <div style={{ width: '85%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '20px' }}> <input value={setpractitionercard} style={{ width: '30px', marginLeft: '10px', height: '30px' }} type="checkbox" onChange={(e) => { setpractitionercard(e.target.checked) }} /> <lebal>Do not Ask for Card information</lebal></div>

                  <div className='bownpopupbutton' style={{ width: '85%' }}>

                    <button onClick={() => {
                      setpopup(false)
                      setpractitioneremail('')
                      setpractitionername('')
                      setpractitionerpassword('')
                    }
                    } style={{ border: '1px solid red', color: 'black', backgroundColor: '#fff' }} >cancel</button>
                    <button style={{ backgroundColor: '#4180b7' }}>Add Practitioner</button>

                  </div>

                </form>

              </>)}


            </div>
          </>}




          {popupdetails === true && <>
            <div onClick={() => {
              setpopupdetails(false)
              {
                setaddstickynoteform(false)
                setaddstickynotetitle("")
                setaddstickynotecontent("")
              }
              setshowwhiteboard(false)


            }
            } className='popupbg'></div>
            <div className='popup' style={{ height: '600px !important', backgroundColor: '#fff' }}>


              {showwhiteboard ? (<>

                {/* <div className='addbutton' style={{ width: '100%',color }}> */}



                <Button danger onClick={() => {
                  // setpopupdetails(false)
                  {
                    setaddstickynoteform(false)
                    setaddstickynotetitle("")
                    setaddstickynotecontent("")
                  }

                  setshowwhiteboard(false)
                }}><AiFillEdit style={{ width: '20px', height: '20px' }} />Close </Button>



                {/* <button onClick={() => {
    // setpopupdetails(false)
{setaddstickynoteform(false)
setaddstickynotetitle("")
setaddstickynotecontent("")}
  setshowwhiteboard(false)
    // setpopupedit(true)
  }}><AiFillEdit style={{ width: '20px', height: '20px' }} />Edit Profile </button> */}
                {/* </div> */}


                <div className='whiteboard'>


                  <ul>



                    {userinfo.practitioner.stickynotes.map((value, index) => {
                      return (<>

                        <li>
                          <a href="#" contenteditable>
                            <img width={"35px"} src="/pin.png" alt='' />
                            <h2>{value.title}</h2>
                            <p>{value.content}</p>
                            <div className='notesby'>By: {value.by}</div>
                            <div className='dateeeeeee'>Date:  {value.date}</div>

                          </a>


                        </li>
                      </>)
                    })}





                    <li>
                      <a contenteditable>
                        <img width={"35px"} src="/pin.png" alt='' />

                        {addstickynoteform ? (<>
                          {loading6 ? (<>

                            <h2>  <img alt='' src='/empty.gif' width={"100px"} /></h2>

                          </>) : (<>
                            <form onSubmit={async (e) => {

                              e.preventDefault()
                              await setloading6(true)
                              const note = {
                                title: addstickynotetitle,
                                content: addstickynotecontent,
                                date: DateComponent(),
                                by: loginname,
                              }


                              let data = JSON.stringify({
                                "_id": userinfo.practitioner._id,
                                "note": note,

                              });

                              let config = {
                                method: 'post',
                                maxBodyLength: Infinity,
                                url: apiurlforbackend + '/updatestickynote',
                                headers: {
                                  'Content-Type': 'application/json'
                                },
                                data: data
                              };

                              await axios.request(config)
                                .then((response) => {
                                  setuserinfo(response.data.practitioners[0])
                                  {
                                    setaddstickynoteform(false)
                                    setaddstickynotetitle("")
                                    setaddstickynotecontent("")
                                  }
                                  message.success(response.data.message)
                                })
                                .catch((error) => {
                                  console.log(error);
                                });


                              await setloading6(false)



                            }} style={{ display: 'block' }}>
                              <h2><input value={addstickynotetitle} onChange={(e) => setaddstickynotetitle(e.target.value)} required className='editfield2' placeholder='Enter note Title' /></h2>
                              <textarea value={addstickynotecontent} onChange={(e) => setaddstickynotecontent(e.target.value)} required placeholder='Enter note content' className='editfield' />

                              <div className='notesby'><button className='button' style={{ width: 'fit-content', color: "red", background: "none", border: "1px solid red" }} onClick={() => {
                                setaddstickynoteform(false)
                                setaddstickynotetitle("")
                                setaddstickynotecontent("")
                              }}>CANCEL</button> <button style={{ width: 'fit-content' }} className='button' type='submit'>ADD</button></div>
                            </form>
                          </>)}
                        </>) : (<>
                          <h2>ADD NOTE</h2>
                          <p><TiPlusOutline onClick={() => setaddstickynoteform(true)} style={{ width: "6em", height: '6em', opacity: "0.5", cursor: 'pointer' }} /></p>
                          <div className='notesby'>By: {loginname}</div>
                          <div className='dateeeeeee'>Date:  {DateComponent()}</div>

                        </>)}



                      </a>


                    </li>




                  </ul>

                </div>

              </>) : (<>

                {loading ? (<>
                  <img alt='' src='/empty.gif' width={"40%"} />
                </>) : (<>


                  <div className='profilemainbody' style={{ width: '95%' }}>







                    <div className='addbutton' style={{ width: '100%' }}>



                      <button onClick={() => {
                        // setpopupdetails(false)
                        {
                          setaddstickynoteform(false)
                          setaddstickynotetitle("")
                          setaddstickynotecontent("")
                        }
                        setshowwhiteboard(false)
                        setshowwhiteboard(true)
                      }}><AiFillEdit style={{ width: '20px', height: '20px' }} />White Board </button>



                      {/* <button onClick={() => {
                      // setpopupdetails(false)
{setaddstickynoteform(false)
setaddstickynotetitle("")
setaddstickynotecontent("")}
  setshowwhiteboard(false)
                      // setpopupedit(true)
                    }}><AiFillEdit style={{ width: '20px', height: '20px' }} />Edit Profile </button> */}
                    </div>






                    <div className='uperprofile'>

                      <div className='profilepicture'>

                        {popupdetails2 ? (<>


                          {loading3 ? (<>

                            <img alt='' src='/empty.gif' width={"60%"} />

                          </>) : (<>
                            <div>

                              <Image src={img} alt='' width={"30%"} />
                            </div>

                            <form className='popup2form' onSubmit={updateimage} style={{ width: '90%', marginTop: '50px' }}>
                              <input
                                type='file'
                                onChange={handleImgChange}
                                accept='image/*'
                                required
                              />
                              <button>Update</button>
                              <button type='button' onClick={() => setpopupdetails2(false)} style={{ marginLeft: '10px', border: "1px solid red", color: 'red', background: 'none' }}>Cancel</button>
                            </form>
                          </>)}

                        </>) : (<>
                          <Image alt='avatar' src={userinfo.practitioner.profilepic} width={"300px"} />
                          <div> <button className='button' style={{ marginTop: '50px' }} onClick={() => {
                            setpopupdetails2(true)
                            // setpopupdetails(false)
                            {
                              setaddstickynoteform(false)
                              setaddstickynotetitle("")
                              setaddstickynotecontent("")
                            }
                            setshowwhiteboard(false)

                          }}><AiFillEdit style={{ width: '20px', height: '20px' }} />Change Profile Picture</button></div>
                        </>)}
                      </div>
                      <div className='profileabot'>

                        <div ><h3 style={{ width: '200px' }}>Name : </h3> <p style={{ margin: '0' }}> {userinfo.practitioner.name ? (<>{userinfo.practitioner.name}</>) : (<a href='#' onClick={(e) => {
                          e.preventDefault()
                          setpopupdetails(false)
                          {
                            setaddstickynoteform(false)
                            setaddstickynotetitle("")
                            setaddstickynotecontent("")
                          }
                          setshowwhiteboard(false)
                          setpopupedit(true)
                        }}>Click to edit</a>)}</p></div>
                        <div ><h3 style={{ width: '200px' }}>Email : </h3> <p style={{ margin: '0' }}> {userinfo.practitioner.email ? (<>{userinfo.practitioner.email}</>) : (<a onClick={(e) => {
                          e.preventDefault()
                          setpopupdetails(false)
                          {
                            setaddstickynoteform(false)
                            setaddstickynotetitle("")
                            setaddstickynotecontent("")
                          }
                          setshowwhiteboard(false)
                          setpopupedit(true)
                        }} href='#'>Click to edit</a>)}</p></div>
                        <div ><h3 style={{ width: '200px' }}>phone : </h3> <p style={{ margin: '0' }}> {userinfo.practitioner.phone ? (<>{userinfo.practitioner.phone}</>) : (<a onClick={(e) => {
                          e.preventDefault()
                          setpopupdetails(false)
                          {
                            setaddstickynoteform(false)
                            setaddstickynotetitle("")
                            setaddstickynotecontent("")
                          }
                          setshowwhiteboard(false)
                          setpopupedit(true)
                        }} href='#'>Click to edit</a>)}</p></div>
                        <div ><h3 style={{ width: '200px' }}>address : </h3> <p style={{ margin: '0' }}> {userinfo.practitioner.address ? (<>{userinfo.practitioner.address}</>) : (<a onClick={(e) => {
                          e.preventDefault()
                          setpopupdetails(false)
                          {
                            setaddstickynoteform(false)
                            setaddstickynotetitle("")
                            setaddstickynotecontent("")
                          }
                          setshowwhiteboard(false)
                          setpopupedit(true)
                        }} href='#'>Click to edit</a>)}</p></div>
                        <div ><h3 style={{ width: '200px' }}>billing post code : </h3> <p style={{ margin: '0' }}> {userinfo.practitioner.billingpostcode ? (<>{userinfo.practitioner.billingpostcode}</>) : (<a onClick={(e) => {
                          e.preventDefault()
                          setpopupdetails(false)
                          {
                            setaddstickynoteform(false)
                            setaddstickynotetitle("")
                            setaddstickynotecontent("")
                          }
                          setshowwhiteboard(false)
                          setpopupedit(true)
                        }} href='#'>Click to edit</a>)}</p></div>
                        <div ><h3 style={{ width: '200px' }}>Registered On :   </h3> <p style={{ margin: '0' }}> {userinfo.practitioner.timestamp ? (<>{userinfo.practitioner.timestamp}</>) : (<a href='#'>Click to edit</a>)}</p></div>


                        <div ><h3 style={{ width: '200px' }}>Accounts Manager:   </h3>

                          {salsesmanegerform ? (<>

                            {loading8 ? (<>
                              <img alt='' src='/empty.gif' width={"10%"} />
                            </>) : (<>
                              <p style={{ margin: '0', display: 'flex' }}>
                                <form onSubmit={async (e) => {
                                  e.preventDefault()
                                  await setloading8(true)





                                  let data = JSON.stringify({
                                    "salseid": salsesmaneger,
                                    "_id": userinfo.practitioner._id,

                                  });

                                  let config = {
                                    method: 'POST',
                                    maxBodyLength: Infinity,
                                    url: apiurlforbackend + "/updatesalsesmanager",
                                    headers: {
                                      'Content-Type': 'application/json'
                                    },
                                    data: data
                                  };

                                  await axios.request(config)
                                    .then((response) => {
                                      setuserinfo(response.data.practitioners[0])
                                      console.log(response.data.practitioners[0])
                                      message.success(response.data.message)
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    });




                                  setsalsesmanegerform(false)
                                  setsalsesmaneger("")







                                  await setloading8(false)

                                }} style={{ padding: '0', margin: '0', width: '100%' }}>
                                <select required onChange={(e)=>setsalsesmaneger(e.target.value)} style={{ width: '70%', opacity: '0.5', height: '30px', border: "1px solid gray", borderRadius: '5px' }}>

                                  <option disabled selected >Please select salses maneger</option>
                                  {admin.map((value) => <>
                                    {value.designation === "Salse Person" && <>
                                      <option value={value._id}>{value.name} ({value.email})</option>
                                    </>}
                                  </>)}
                                </select><div style={{ display: "flex", width: '30%', flexDirection: "row", justifyContent: 'space-around' }}><button onClick={() => setsalsesmanegerform(false)} style={{ width: 'fit-content', color: 'red', border: "1px solid red", background: 'none' }}>Cancel</button> <button style={{ width: 'fit-content' }}>Change</button></div>
                              </form>
                            </p></>)}
                          </>) : (<>
                            <p style={{ margin: '0' }}> {admin.find(value => value._id === userinfo.practitioner.salsesmaneger).name} ({admin.find(value => value._id === userinfo.practitioner.salsesmaneger).email})  <AiFillEdit onClick={() => setsalsesmanegerform(true)} style={{ width: '20px', height: '20px', cursor: 'pointer' }} />

                            </p>
                          </>)}


                        </div>



                        <div ><h3 style={{ width: '200px' }}>Report Style:   </h3>

                          {reportstyleform ? (<>
                            {loading7 ? (<>
                              <img alt='' src='/empty.gif' width={"10%"} />
                            </>) : (<>
                              <p style={{ margin: '0', display: 'flex' }}>
                                <form onSubmit={async (e) => {
                                  e.preventDefault()
                                  await setloading7(true)





                                  let data = JSON.stringify({
                                    "style": reportstyle,
                                    "_id": userinfo.practitioner._id,

                                  });

                                  let config = {
                                    method: 'POST',
                                    maxBodyLength: Infinity,
                                    url: apiurlforbackend + "/updatereportstyle",
                                    headers: {
                                      'Content-Type': 'application/json'
                                    },
                                    data: data
                                  };

                                  await axios.request(config)
                                    .then((response) => {
                                      setuserinfo(response.data.practitioners[0])
                                      message.success(response.data.message)
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    });




                                  setreportstyleform(false)
                                  setreportstyle("")







                                  await setloading7(false)

                                }} style={{ padding: '0', margin: '0', width: '100%' }}>
                                  <select required onChange={(e) => setreportstyle(e.target.value)} style={{ width: '70%', opacity: '0.5', height: '30px', border: "1px solid gray", borderRadius: '5px' }}>

                                    <option disabled selected >Please select report style</option>
                                    <option value={"YourGutMap Report Style"} >YourGutMap Report style</option>
                                    <option value={"FeelGut Report Style"} >FeelGut Report style</option>

                                  </select><div style={{ display: "flex", width: '30%', flexDirection: "row", justifyContent: 'space-around' }}><button onClick={() => setreportstyleform(false)} style={{ width: 'fit-content', color: 'red', border: "1px solid red", background: 'none' }}>Cancel</button> <button style={{ width: 'fit-content' }}>Change</button></div>
                                </form>
                              </p>
                            </>)}
                          </>) : (<>
                            <p style={{ margin: '0' }}> {userinfo.practitioner.resultstyle}  <AiFillEdit onClick={() => setreportstyleform(true)} style={{ width: '20px', height: '20px', cursor: 'pointer' }} />

                            </p>
                          </>)}


                        </div>


                        <div ><h3 style={{ width: '200px' }}>Action   </h3>

                          <a onClick={(e) => {
                            e.preventDefault()
                            setchangepasswordshow(true)

                          }} href='/' style={{ display: 'flex', marginRight: '10px', alignItems: "center", color: 'red' }}> <AiFillEdit style={{ width: '20px', height: '20px' }} />change password</a>




                        </div>

                        {changepasswordshow === true && <>

                          {loading2 ? (<>

                            <img alt='' src='/empty.gif' width={"20%"} />
                          </>) : (<>

                            <form onSubmit={async (e) => {
                              e.preventDefault()


                              await setloading2(true)
                              let data = JSON.stringify({
                                "pagenumber": page,

                                "_id": userinfo.practitioner._id,
                                "newpassword": editpassword

                              });

                              let config = {
                                method: 'post',
                                maxBodyLength: Infinity,
                                url: apiurlforbackend + '/changepracpassbyadmin',
                                headers: {
                                  'Content-Type': 'application/json'
                                },
                                data: data
                              };

                              await axios.request(config)
                                .then((response) => {
                                  // setpracbyres(response)
                                  message.success(response.data.message)
                                  setchangepasswordshow(false)


                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                              await setloading2(false)



                            }} className='profileeditingform' >


                              <div className='editprofileinputs' style={{ gap: '10px' }}>
                                <input placeholder='New Password :' required type='password' value={editpassword} onChange={(e) => seteditpassword(e.target.value)} style={{ width: '92%' }} />
                                <input placeholder=' Confirm Password :' required type='password' title='Password does not Match' pattern={editpassword} style={{ width: '92%' }} />

                              </div>


                              <div className='buttonnnnn' style={{ display: 'flex', flexDirection: 'row', width: "100%", }}>

                                <button onClick={() => {

                                  setchangepasswordshow(false)


                                }
                                } style={{ border: '1px solid red', color: 'red', background: "none" }} >cancel</button>







                                <button style={{ backgroundColor: '#4180b7' }}>Update</button>
                              </div>

                            </form>


                          </>)}




                        </>}


                        <div style={{ width: '100%', margin: '30px 0' }}>
                          <h2>About Me</h2>
                          <p style={{ width: '100%', margin: '0' }}>{userinfo.practitioner.about ? (<>{userinfo.practitioner.about}</>) : (<a href='#'>Click to edit</a>)}</p>
                        </div>



                        {/* {userinfo.practitioner.CardID === "nocardneeded" ? (<> <button className='addbutton button'
                      // onClick={changecard}
                      >Consignment (Click to add Card)</button>
                      </>) : (<>
                        {userinfo.practitioner.CardID ? (<>
                          <button className='addbutton button'
                          //  onClick={changecard}
                          >Change payment Card</button>
                        </>) : (<>
                          <button className='addbutton button' disabled>The card has not been added yet.</button>

                        </>)}
                      </>)} */}



                      </div>

                    </div>


                    <div className='profileinfo'>

                      <div className='infoboxinside' style={{ width: '150px' }}>
                        <h2>
                          {userinfo.kits.length + Math.max(0, parseInt(userinfo.practitioner.mbkits) - parseInt(userinfo.kits.filter(kit => (kit.Kittype === "MicrobiomeAdvanced" || kit.Kittype === "MicrobiomePlus")).length))}
                        </h2>  {/* { userinfo.kits.filter(kit => kit.Kittype === "MicrobiomeAdvanced").length} */}


                        <p style={{ margin: '0' }}>TOTAL KITS</p>
                      </div>

                      <div className='infoboxinside' style={{ width: "200px" }}>

                        <h2 style={{ margin: '0' }}>{userinfo["analysiscompleted"]}</h2>
                        <p style={{ margin: '0' }}>ANALYSIS COMPLETED <br />(MB not included)</p>
                      </div>


                      <div className='infoboxinside' style={{ width: "200px" }}>

                        <h2>{userinfo["analysispending"]}</h2>
                        <p style={{ margin: '0' }}>ANALYSIS PENDING <br />(MB not included)
                        </p>
                      </div>





                    </div>

                    {/* <div className='profileinfo'> */}
                    {viewkitsbar(userinfo.kits)}
                    {/* </div> */}

                    <div className='imp' style={{ width: "90%" }}>
                      <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ width: '100%' }}>
                        <thead className='tablephead' >
                          <tr>
                            <th>S No.</th>
                            <th>KIT ID</th>
                            <th>Received Acknowledgment</th>
                            <th>Payment Acknowledgment</th>
                            <th>Result Acknowledgment</th>



                          </tr>
                        </thead>
                        <tbody>
                          {userinfo.kits.map((value, index) => {
                            let Received
                            let Payment
                            let Result


                            if (value.resultuploaded && value.resultack) {
                              Result = "Results are ready and sent"
                            } else if (value.resultuploaded && !value.resultack) {
                              Result = "Results are ready and need to sent"
                            } else {
                              Result = "Results are not ready"
                            }


                            if (value.Kitprice === "fdgbhjbdgfhjdfgsbf" && value.ackpayment) {
                              Payment = "Payment Done and Acknowledgment sent"
                            } else if (value.Kitprice === "fdgbhjbdgfhjdfgsbf" && !value.ackpayment) {
                              Payment = "Payment Done and need to sent Acknowledgment"
                            } else {
                              Payment = "Payment Pending"
                            }

                            if ((value.receiveddate || value.status === "KIT Received in Lab, Awaiting Results" || value.status === "Results Published") && value.ack) {
                              Received = "Received in lab and Acknowledgment sent"
                            } else if ((value.receiveddate || value.status === "KIT Received in Lab, Awaiting Results" || value.status === "Results Published") && !value.ack) {
                              Received = "Received in lab and need to sent Acknowledgment"
                            } else {
                              Received = "Haven't Received in lab yet"
                            }

                            return (<>
                              <tr>
                                <td>{sno1++}</td>
                                <td><h4>{value.kitid}</h4></td>
                                <td>{Received}</td>
                                <td>{Payment}</td>
                                <td>{Result}</td>
                              </tr>
                            </>)
                          })}
                        </tbody>
                      </table>
                    </div>


                    <div className='profileinfo2' style={{ marginTop: '0', textAlign: 'left', flexDirection: 'column', alignItems: 'flex-start', color: "#6E4E9F" }}>

                      {addnoteform ? (<>
                        {loading4 ? (<>
                          <img alt='' src='/empty.gif' width={"25%"} />

                        </>) : (<>
                          <form className='profileeditingform' onSubmit={async (e) => {
                            e.preventDefault()
                            await setloading4(true)


                            const note = {

                              content: addnote,
                              date: DateComponent(),
                              by: loginname,
                            }



                            let data = JSON.stringify({
                              "note": note,
                              "_id": userinfo.practitioner._id,

                            });

                            let config = {
                              method: 'POST',
                              maxBodyLength: Infinity,
                              url: apiurlforbackend + "/updatenote",
                              headers: {
                                'Content-Type': 'application/json'
                              },
                              data: data
                            };

                            axios.request(config)
                              .then((response) => {
                                setuserinfo(response.data.practitioners[0])
                                message.success(response.data.message)
                              })
                              .catch((error) => {
                                console.log(error);
                              });




                            setaddnoteform(false)
                            setaddnote("")







                            await setloading4(false)

                          }}>
                            <textarea style={{ width: '90%' }} value={addnote} onChange={(e) => setaddnote(e.target.value)} placeholder='Enter Logs to add' required />

                            <div className='buttonnnnn' style={{ display: 'flex', flexDirection: 'row', width: "100%", }}>
                              <button onClick={() => setaddnoteform(false)} type='button' style={{ marginLeft: "10px", color: "red", border: '1px solid red', background: 'none' }}>Cancel</button>
                              <button>ADD</button>

                            </div>
                          </form>

                        </>)}

                      </>) : (<>
                        <div className=''><button className='button' onClick={() => setaddnoteform(true)}><FaPen style={{ width: '10px', height: '10px' }} />Add Logs</button></div>
                      </>)}

                      <ul>
                        {console.log(userinfo.practitioner.Notes)}
                        {userinfo.practitioner.Notes.map((value) => (<>
                          <li>
                            {value.content === undefined && <>   {value}</>}

                            <p style={{ opacity: 1, margin: '0' }}>{value.content}</p>
                            <p style={{ opacity: 0.5, margin: '0' }}>{value.date} {" "}{value.by}</p>
                            <hr />

                          </li>

                        </>))}
                      </ul>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '92%', marginTop: '30px' }}>
                      <Popconfirm
                        title="Action Confirmation"
                        description="Are you sure to Delete this Practitioner?"
                        onConfirm={async () => {
                          await setloading(true)
                          let data = JSON.stringify({
                            "pagenumber": page,

                            "_id": userinfo.practitioner._id,


                          });

                          let config = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: apiurlforbackend + '/dltpractitioner',
                            headers: {
                              'Content-Type': 'application/json'
                            },
                            data: data
                          };

                          await axios.request(config)
                            .then((response) => {
                              setpracbyres(response)
                              setpopupdetails(false)
                              {
                                setaddstickynoteform(false)
                                setaddstickynotetitle("")
                                setaddstickynotecontent("")
                              }
                              setshowwhiteboard(false)

                            })
                            .catch((error) => {
                              console.log(error);
                            });
                          await setloading(false)

                        }}

                        okText="Yes"
                        cancelText="No"
                      >

                        <a onClick={async (e) => {
                          e.preventDefault()


                          // const hide = message.loading("Action in progress", 0)
                          // var myHeaders = new Headers();
                          // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                          // var urlencoded = new URLSearchParams();
                          // urlencoded.append("_id", userinfo._id);

                          // var requestOptions = {
                          //   method: 'POST',
                          //   headers: myHeaders,
                          //   body: urlencoded,
                          //   redirect: 'follow'
                          // };

                          // await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/dltpractitioner", requestOptions)
                          //   .then(response => response.json())
                          //   .then(result => {
                          //     setpopupdetails(false)
                          {
                            setaddstickynoteform(false)
                            setaddstickynotetitle("")
                            setaddstickynotecontent("")
                          }
                          setshowwhiteboard(false)
                          //     setpractitioner(result)
                          //   })
                          //   .catch(error => console.log('error', error));


                          // await setTimeout(() => {
                          //   hide(); // Call hide to stop the loading message
                          //   message.success("Action completed successfully");
                          // }, 2000);
                          // setpopupdetails(false)
                          {
                            setaddstickynoteform(false)
                            setaddstickynotetitle("")
                            setaddstickynotecontent("")
                          }
                          setshowwhiteboard(false)
                        }} href='/' style={{ display: 'flex', marginRight: '10px', alignItems: "center", color: 'red' }}> <AiFillDelete style={{ width: '20px', height: '20px' }} />Remove this Practitioner</a>

                      </Popconfirm>

                    </div>






                  </div>

                </>)}
              </>)}
            </div>
          </>}



          {/* {popupdetails2 === true && <>
            <div onClick={() => {
              setpopupdetails2(false)


            }
            } className='popupbg'></div>
            <div className='popup' style={{ height: '600px !important' }}>

              <div>

                <Image src={img} alt='' width={"30%"} />
              </div>

              <form onSubmit={updateimage}>
                <input
                  type='file'
                  onChange={handleImgChange}
                  accept='image/*'
                />
                <button>Update</button>
              </form>



            </div>
          </>} */}




          {/* {popupedit === true && <>
            <div onClick={() => {
              setpopupdetails(false)
{setaddstickynoteform(false)
setaddstickynotetitle("")
setaddstickynotecontent("")}
  setshowwhiteboard(false)
              setpopupedit(false)


            }
            } className='popupbg'></div>
            <div className='popup'>









              <div className='bownpopupbutton'>






                <form onSubmit={(e) => {
                  e.preventDefault()
                  setpopup2(true)
                  subminupdatedata()
                }} className='profileeditingform'>

                  <h3>Edit Profile</h3>
                  <div className='editprofileinputs'>
                    <label>Name :<input required value={editname} onChange={(e) => seteditname(e.target.value)} /></label>
                    <label>Email :<input required value={editemail} onChange={(e) => seteditemail(e.target.value)} /></label>
                    <label>Address :<input required value={editaddres} onChange={(e) => seteditaddres(e.target.value)} /></label>
                    <label>Phone :<input required value={editphone} onChange={(e) => seteditphone(e.target.value)} /></label>
                    <label>billing post code :<input required value={billingpostcode} onChange={(e) => setbillingpostcode(e.target.value)} style={{ width: '70%' }} /></label>

                    <label style={{ alignItems: 'baseline' }}>About :<textarea required onChange={(e) => seteditabout(e.target.value)} value={editabout} /></label>
                  </div>


                  <div className='buttonnnnn'>

                    <button onClick={() => {
                      setpopupedit(false)


                    }
                    } style={{ border: '1px solid red', color: 'black' }} >cancel</button>







                    <button style={{ backgroundColor: '#4180b7' }}>Update</button>
                  </div>

                </form>








              </div>

            </div>
          </>} */}









          {practitioner.length !== 0 && <>
            <table className='tablep' cellPadding={"10"} cellSpacing={"0"}>
              <thead className='tablephead'>
                <tr>
                  <th>Account Status</th>

                  <th>S NO.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Assigned Kits</th>


                  <th>Action</th>
                </tr>
              </thead>
              <tbody>






                {/* {changepasswordshow === true && <>
                  <div onClick={() => {
                    setchangepasswordshow(false)
                    count = 0

                  }
                  } className='popupbg2'></div>
                  <div className='popup'>

                    <div className='header' >
                      <h4>Change password of Practitioner</h4>

                    </div>






                    <form onSubmit={(e) => {
                      e.preventDefault()

                      var myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                      var urlencoded = new URLSearchParams();
                      urlencoded.append("_id", idtochangepass);

                      urlencoded.append("newpassword", editpassword);

                      var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: 'follow'
                      };

                      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileeditpassword2", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                          if (result === "Password has been changed") {

                            message.success("Password has been changed")
                            setchangepasswordshow(false)

                          }
                        })
                        .catch(error => console.log('error', error));











                    }} className='profileeditingform'>

                      <h3>Edit Profile</h3>
                      <div className='editprofileinputs' style={{ gap: '10px' }}>
                        <label>New Password :<input required type='password' value={editpassword} onChange={(e) => seteditpassword(e.target.value)} style={{ width: '70%' }} /></label>
                        <label>Confirm Password :<input required type='password' title='Password does not Match' pattern={editpassword} style={{ width: '70%' }} /></label>

                      </div>


                      <div className='buttonnnnn'>

                        <button onClick={() => {

                          setchangepasswordshow(false)


                        }
                        } style={{ border: '1px solid red', color: 'black' }} >cancel</button>







                        <button style={{ backgroundColor: '#4180b7' }}>Update</button>
                      </div>

                    </form>




                  </div>
                </>} */}











                {practitioner.map((item, index) => (<>



                  <tr>

                    {item.practitioner.status ? (<>


                      <Popconfirm
                        title="Action Confirmation"
                        description="Are you sure to Deactivate this Practitioner?"
                        onConfirm={() => makeactandinact(item.practitioner._id, item.practitioner.status)}

                        okText="Yes"
                        cancelText="No"
                      >
                        <td className='hovar' style={{ color: "green" }}><FaDotCircle /> Active</td>
                      </Popconfirm>

                    </>) : (<>

                      <Popconfirm
                        title="Action Confirmation"
                        description="Are you sure to Activate this Practitioner?"
                        onConfirm={() => makeactandinact(item.practitioner._id, item.practitioner.status)}

                        okText="Yes"
                        cancelText="No"
                      >

                        <td className='hovar' style={{ color: "red" }}><FaDotCircle /> Inactive</td>
                      </Popconfirm>
                    </>)}


                    <td>{sno1++}</td>
                    <td>{item.practitioner.name} </td>
                    <td>{item.practitioner.email}</td>
                    <td>
                      {item.kits.length + Math.max(0, parseInt(item.practitioner.mbkits) - parseInt(item.kits.filter(kit => (kit.Kittype === "MicrobiomeAdvanced" || kit.Kittype === "MicrobiomePlus")).length))}
                    </td>

                    <td>
                      <button className='button' onClick={async () => {

                        await setuserinfo(item)


                        await setpopupdetails(true)


                      }}>Detail</button>
                    </td>
                  </tr>

                </>))}







              </tbody>
            </table>


          </>}




          {practitioner.length === 0 && <>
            {totalPages === 0 ? (<>
              <img alt='' src='/empty2.gif' width={"35%"} />
            </>) : (<> <img alt='' src='/empty.gif' width={"40%"} /> </>)}
          </>}

          <div className="pagination">
            {page > 1 && (
              <span onClick={() => handlePageClick(page - 1)}>&lt;&lt;</span>
            )}
            {renderPageNumbers()}
            {page < totalPages && (
              <span onClick={() => handlePageClick(page + 1)}>&gt;&gt;</span>
            )}
          </div>




















        </div>


      </div>
    </>} </>)
}

export default Practitioner