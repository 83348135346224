import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from "axios"
// import 'antd/dist/reset.css'; // Import Ant Design styles



import Index from './pages';
import Desboard from './pages/desboard';
import Adminkit from './pages/adminkit';
import Adminreport from './pages/adminreport';
import Practitioner from './pages/Practitioner';
import Profile from './pages/Profile';
import Viewreport from './pages/viewreport';
import Viewreport2 from './pages/viewreport2';
import { useNavigate } from 'react-router-dom'


import Microkit from './pages/microkit';

import Micropre from './pages/Micropre';

import Pracstate from './pages/pracstates';
import Orderlables from './pages/orderlables';
import Ackpage from './pages/ackpage';
import Bucket from './pages/dropresult'
import Shippingform from './pages/shippingform';
import Shipping from './pages/shipping'
import KitAnalysis from './pages/KitAnalysis';
import Kitregistration from './pages/kitregistration';
import Helpcenter from './pages/helpcenter'
import Tickets from './pages/tickets';
import Ticketsid from './pages/ticketsid';
import Webinar from './pages/Webinar';
import Webinardetails from './pages/Webinardetails';
import Meeting from './pages/meeting';
import Eventinfoform from './pages/eventinfoform';
import Eventinfo from './pages/eventinfo';
import Maps from './pages/maps';
import Dnamapform from './pages/Dnamapform';
import Praform from './pages/praform';
import Leads from './pages/Leads';
import Orderstates from './pages/orderstates';
import Pararesults from './pages/pararesults';



import Meetings from './pages/meetings';
import Meetingmeet from './pages/meetingmeet';
import Fsform from './pages/fsform';
import Fsformdata from './pages/foodsform';
import Meetingsusers from './pages/meetingsusers';
import Sendemail from './pages/sendemail';



import Candidaform from './pages/candidaform';
import Candidaformdata from './pages/candidaformdata';
import Leadsstate from './pages/Leadsstates';
import Meetingsdetails from './pages/meetingsdetails';

import KITS from './components/kits';
import KitReport from './components/report';
import Practitionerpage from './components/practitioners';
import Fsformdatawl from './pages/foodsformwl';
import Teamticks from './pages/teams tickets/teamticks';
import Teamticketsdetails from './pages/teams tickets/teamticketsdetails';

const Fskits = () => <KITS kittype="Food Sensitivtiy 100" />;
const Fskits210 = () => <KITS kittype="Food Sensitivtiy 210" />;
const Fskitsmap = () => <KITS kittype="FoodSensitivityMap" />;
const Parasitologykit = () => <KITS kittype="Parasitology Test" />;
const Candidakit = () => <KITS kittype="CandidaProfile" />;
const DNAkit = () => <KITS kittype="DNAMap Genetic Test" />;
const MicrobiomeAdvanced = () => <KITS kittype="MicrobiomeAdvanced" />;
const Microbiomeplus = () => <KITS kittype="MicrobiomePlus" />;





const Fs100report = () => <KitReport kittype="Food Sensitivtiy 100" />;
const Fs210report = () => <KitReport kittype="Food Sensitivtiy 210" />;
const Fsmapreport = () => <KitReport kittype="FoodSensitivityMap" />;
const Parasitologykitreport = () => <KitReport kittype="Parasitology Test" />;
const Candidakitreport = () => <KitReport kittype="CandidaProfile" />;
const Microbiomeplusreport = () => <KitReport kittype="MicrobiomePlus" />;
const MicrobiomeAdvancedreport = () => <KitReport kittype="MicrobiomeAdvanced" />;
const DNAkitreport = () => <KitReport kittype="DNAMap Genetic Test" />;



const Fs100prac = () => <Practitionerpage kittype="Food Sensitivtiy 100" />;
const Fs210prac = () => <Practitionerpage kittype="Food Sensitivtiy 210" />;
const Fsmapprac = () => <Practitionerpage kittype="FoodSensitivityMap" />;
const Parasitologykitprac = () => <Practitionerpage kittype="Parasitology Test" />;
const Candidakitprac = () => <Practitionerpage kittype="CandidaProfile" />;
const Microbiomeplusprac = () => <Practitionerpage kittype="MicrobiomePlus" />;
const MicrobiomeAdvancedprac = () => <Practitionerpage kittype="MicrobiomeAdvanced" />;
const DNAkitprac = () => <Practitionerpage kittype="DNAMap Genetic Test" />;



function ScrollToTop() {
  const { pathname } = useLocation();
  const loginemail = Cookies.get("email")
  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const navigate = useNavigate()




  useEffect(() => {
    window.scrollTo(0, 0);

    if (token === "sdrfg&78967daghf#wedhjgasj(dlsh6kjsdg") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("_id", id);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/profileinfocheck", requestOptions)
        .then(response => response.text())
        .then(result => {
          if (result === "deactivated") {
            const allCookies = Cookies.get();
            for (const cookieName in allCookies) {
              Cookies.remove(cookieName);
            }
            navigate('/')
          }

        })
        .catch(error => console.log('error', error));
    }


  }, [pathname]);

  return null;
}

const Report = () => {
  const { id } = useParams()

  const getinfo = () => {



    let data = JSON.stringify({
      "id": id
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_APIURL + '/getidandtype',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {

        const CNAME = response.data.data.Kittype.toLowerCase().replace(/\s+/g, "");
        const urltothrough = `https://${CNAME}-report.yourgutmap.co.uk/${response.data.data._id}`
        window.location.href = urltothrough;



      })
      .catch((error) => {
        console.log(error);
      });



  }

  useEffect(() => {
    getinfo()
  }, [])




}

function App() {



  return (

    <div className="App">

      <BrowserRouter>
        <ScrollToTop />


        <Routes>
          <Route path='/' element={<Index />} />
          <Route path='/report/:id' element={<Report />} />
          <Route path='/kitack' element={<Ackpage />} />
          <Route path='/shipping' element={<Shippingform />} />
          <Route path='/leads' element={<Leads />} />
          <Route path='/leads-state' element={<Leadsstate />} />

          <Route path='/eventinfo' element={<Eventinfoform />} />
          <Route path='/bucket' element={<Bucket />} />
          <Route path='/dashboard' element={<Desboard />} />
          <Route path='/dashboard/analysis' element={<KitAnalysis />} />
          <Route path='/dashboard/webinar' element={<Webinar />} />
          <Route path='/dashboard/webinar/:id' element={<Webinardetails />} />
          <Route path='/dashboard/webinar/meeting/:id' element={<Meeting />} />
          <Route path='/dashboard/helpcenter' element={<Helpcenter />} />
          <Route path='/dashboard/tickets' element={<Tickets />} />
          <Route path='/dashboard/order-states' element={<Orderstates />} />
          <Route path='/dashboard/map' element={<Maps />} />
          <Route path='/dashboard/meet' element={<Meetings />} />
          <Route path='/dashboard/send-emails' element={<Sendemail />} />
          <Route path='/dashboard/meet-user' element={<Meetingsusers />} />
          <Route path='/dashboard/meet-user/:id' element={<Meetingsdetails />} />

          <Route path='/dashboard/meet/:id' element={<Meetingmeet />} />

          <Route path='/dashboard/tickets/:id' element={<Ticketsid />} />
          <Route path='/dashboard/kitregistration' element={<Kitregistration />} />
          <Route path='/dashboard/para-report/:id' element={<Pararesults />} />
          <Route path='/dashboard/view-report/:id' element={<Viewreport />} />
          <Route path='/dashboard/view-report100/:id' element={<Viewreport2 />} />


          {/* teamstickets */}
          <Route path='/dashboard/teams/tickets' element={<Teamticks />} />
          <Route path='/dashboard/teams/tickets/:id' element={<Teamticketsdetails />} />


        </Routes>

        <Routes>

          {/* fs/  */}
          <Route path='/dashboard/fs100/kit' element={<Fskits />} />
          <Route path='/dashboard/fs210/kit' element={<Fskits210 />} />

          <Route path='/dashboard/fs100/report' element={<Fs100report />} />
          <Route path='/dashboard/fs210/report' element={<Fs210report />} />

          <Route path='/dashboard/uploadreport' element={<Adminreport />} />
          <Route path='/dashboard/fs210/viewreport/:id' element={<Viewreport />} />

          <Route path='/dashboard/fs100/practitioner' element={<Fs100prac />} />
          <Route path='/dashboard/fs210/practitioner' element={<Fs210prac />} />

          <Route path='/foodsensitivityform' element={<Fsform />} />
          <Route path='/foodsensitivityform-data' element={<Fsformdata />} />
          <Route path='/fs-Form' element={<Fsformdatawl />} />


          {/* fsmap/  */}
          <Route path='/dashboard/fsmap/kit' element={<Fskitsmap />} />
          <Route path='/dashboard/fsmap/report' element={<Fsmapreport />} />
          <Route path='/dashboard/fsmap/practitioner' element={<Fsmapprac />} />


          {/* micro/     */}
          <Route path='/dashboard/microbiome/kit' element={<Microkit />} />
          {/* <Route path='/dashboard/micro/report' element={<Mircoreport />} /> */}
          <Route path='/dashboard/microbiome/practitioner' element={<Micropre />} />


          {/* micro plus/     */}
          <Route path='/dashboard/microbiomeplus/kit' element={<Microbiomeplus />} />
          <Route path='/dashboard/microbiomeplus/report' element={<Microbiomeplusreport />} />
          <Route path='/dashboard/microbiomeplus/practitioner' element={<Microbiomeplusprac />} />


          {/* micro adv/     */}
          <Route path='/dashboard/microbiomeadvanced/kit' element={<MicrobiomeAdvanced />} />
          <Route path='/dashboard/microbiomeadvanced/report' element={<MicrobiomeAdvancedreport />} />
          <Route path='/dashboard/microbiomeadvanced/practitioner' element={<MicrobiomeAdvancedprac />} />



          {/* dna/     */}
          <Route path='/dashboard/dna/kit' element={<DNAkit />} />
          <Route path='/dnamap-form' element={<Dnamapform />} />

          <Route path='/dashboard/dna/report' element={<DNAkitreport />} />
          <Route path='/dashboard/dna/practitioner' element={<DNAkitprac />} />

          {/* Parasitology */}
          <Route path='/dashboard/Parasitology/kit' element={<Parasitologykit />} />
          <Route path='/dashboard/Parasitology/report' element={<Parasitologykitreport />} />
          <Route path='/dashboard/Parasitology/practitioner' element={<Parasitologykitprac />} />
          <Route path='/Parasitology-Test-Form' element={<Praform />} />



          {/* condida */}
          <Route path='/dashboard/candidaprofile/kit' element={<Candidakit />} />
          <Route path='/dashboard/candidaprofile/report' element={<Candidakitreport />} />
          <Route path='/dashboard/candidaprofile/practitioner' element={<Candidakitprac />} />
          <Route path='/candidaform' element={<Candidaform />} />
          <Route path='/candidaform-data' element={<Candidaformdata />} />


          <Route path='/dashboard/kit' element={<Adminkit />} />
          <Route path='/dashboard/practitioner' element={<Practitioner />} />
          <Route path='/dashboard/practitionerstates' element={<Pracstate />} />
          <Route path='/dashboard/orders' element={<Orderlables />} />
          <Route path='/dashboard/shippingdata' element={<Shipping />} />
          <Route path='/dashboard/eventinfogdata' element={<Eventinfo />} />

          <Route path='/dashboard/profile' element={<Profile />} />
          {/* <Route path='*' element={<div><img src='/page not found.gif' width={"20%"}/></div>} /> */}



        </Routes>


      </BrowserRouter>

    </div>
  );
}

export default App;
