import React, { useState, useRef, useEffect } from 'react'

import "../css/adminreport.css"

import "../css/adminkit.css"
import { ImUpload2 } from "react-icons/im"

import { AiFillDelete, } from "react-icons/ai"
import Papa from 'papaparse';
import { Button } from 'antd';

import { FaUpload } from "react-icons/fa"

import Cookies from 'js-cookie';

import axios from 'axios'
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";


function Uploadresults() {


    const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const id = Cookies.get("id")
    const token = Cookies.get("Token")
    const token2 = Cookies.get("Token2")


    const apiurlforbackend = process.env.REACT_APP_APIURL
    const fileInputRef = useRef(null);

    const [files, setfiles] = useState([]);
    const [Message, setMessages] = useState([]);

    const [loading, setloading] = useState(false);
    const [loading2, setloading2] = useState(false);






    const handleFileUpload = (e) => {
        const fileList = e.target.files; // FileList object
        const filesArray = Array.from(fileList); // Convert to an array

        setfiles((prevFiles) => {
            // Filter files: only allow CSV and PDF, and avoid duplicates
            const newFiles = filesArray.filter(
                (newFile) =>
                    (newFile.type === "application/pdf" || newFile.type === "text/csv" ||
                        newFile.name.endsWith(".csv")) && // Ensure CSV and PDF files only
                    !prevFiles.some(
                        (existingFile) =>
                            existingFile.name === newFile.name && existingFile.size === newFile.size
                    )
            );

            // Return the updated files list
            return [...prevFiles, ...newFiles];
        });
    };



    // const handleFileUpload = async (e) => {
    //   const file = e.target.files;


    //   const filesArray = Array.from(file);


    //   setfiles((prevFiles) => [...prevFiles, ...filesArray]);

    //   // console.log(e.target.files)
    //   // // console.log(event.target.files)
    //   // // console.log(csvFile)
    //   // setCSVFile(file)

    //   // await Papa.parse(file, {
    //   //   complete: (result) => {





    //   //     setCsvData(result.data)

    //   //   },
    //   //   header: true,
    //   //   skipEmptyLines: true
    //   // });



    // };


    const handleFileDrop = (e) => {
        e.preventDefault();

        const fileList = e.dataTransfer.files; // FileList object
        const filesArray = Array.from(fileList); // Convert to an array

        setfiles((prevFiles) => {
            // Filter files: only allow CSV and PDF, and avoid duplicates
            const newFiles = filesArray.filter(
                (newFile) =>
                    (newFile.type === "application/pdf" || newFile.type === "text/csv" ||
                        newFile.name.endsWith(".csv")) && // Ensure CSV and PDF files only
                    !prevFiles.some(
                        (existingFile) =>
                            existingFile.name === newFile.name && existingFile.size === newFile.size
                    )
            );

            // Return the updated files list
            return [...prevFiles, ...newFiles];
        });
    };







    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


    const datauploaded = async () => {
        await setloading(true); // Set loading to true
        await setloading2(true); // Set loading to true

        for (const file of files) {
            if (file.type === "application/pdf") {


                await setMessages((prevMessages) => [...prevMessages, `<p style="color:#6E4E9F;">Uploading PDF file: ${file.name} to FIREBASE </p>`]);


                const ex = file.name.split('.')[1]
                const ex2 = file.name.split('.')[0]
                let newwkid = ex2.match(/RD00\d+/);


                var imgRef
                if (newwkid === null) {
                    newwkid = ex2
                    imgRef = ref(imageDb, `Parasitology result/Parasitology result ${newwkid}.${ex}`)
                }else{
                    imgRef = ref(imageDb, `DNAMap result/DNAMap result ${newwkid}.${ex}`)
                }

              
              
             

                await uploadBytes(imgRef, file)
                const filefirebaseurl = await getDownloadURL(imgRef);
                // const result = [{ type: "url", url: filefirebaseurl }]

                const kitID = newwkid;
                const resultof = "url";
                const resultvalue = filefirebaseurl;
                await setMessages((prevMessages) => [...prevMessages, `<p style="color:#6E4E9F;">Processing PDF file: ${file.name}, on portal </p>`]);

                await senttobackend(kitID, resultof, resultvalue); // Async call to backend




            } else if (file.type === "text/csv" || file.name.endsWith(".csv")) {



                await setMessages((prevMessages) => [...prevMessages, `<p style="color:#6E4E9F;">Processing CSV file: ${file.name} </p>`]);

                await new Promise((resolve, reject) => {
                    Papa.parse(file, {
                        header: true,
                        skipEmptyLines: true,
                        complete: async (result) => {
                            try {
                                for (const csvrow of result.data) {

                                    if (csvrow.JobNumber === undefined && csvrow.JobID && csvrow.JobID.length > 10) {

                                        const array = csvrow.JobID.split(",");

                                        const result = array[array.length - 1].replace(/["\,]/g, "");

                                        let resultofsend = array[array.length - 4].replace(/["\,]/g, "");

                                        if (resultofsend === "IV and V)") {

                                            resultofsend = "Sapovirus (I,II,IV and V)"
                                        }

                                        if (result.length > 4) {
                                            const kitID = array[5];

                                            const resultvalue = result;

                                            await senttobackend(kitID, resultofsend, resultvalue); // Async call to backend
                                        }




                                    }
                                    else if (
                                        csvrow.JobNumber !== undefined &&
                                        csvrow.Phrase !== undefined &&
                                        csvrow.Phrase.length > 3
                                    ) {


                                        const kitID = csvrow.SampleName;
                                        const resultof = csvrow.Parameter;
                                        const resultvalue = csvrow.Phrase;

                                        await senttobackend(kitID, resultof, resultvalue); // Async call to backend


                                    } else if (csvrow[' "Kit ID"'] && csvrow[' "Kit ID"'].length > 5) {






                                        const datacalculation = () => {


                                            const Berley = 1 * parseFloat(csvrow[' "AU - Barley Flour"'])
                                            const GlutenGliadin = 1 * parseFloat(csvrow[' "AU - Gluten"'])
                                            const Rye = 0.86 * parseFloat(csvrow[' "AU - Gluten"'])
                                            const Wheat = 1 * parseFloat(csvrow[' "AU - Wheat"'])
                                            const BrownRice = 1 * parseFloat(csvrow[' "AU - Rice"'])
                                            const Buckwheat = 0.94 * parseFloat(csvrow[' "AU - Wheat"'])
                                            const Oat = 1 * parseFloat(csvrow[' "AU - Oat"'])
                                            const Spelt = 0.74 * parseFloat(csvrow[' "AU - Wheat"'])
                                            const WhiteRice = 1.27 * parseFloat(csvrow[' "AU - Rice"'])
                                            const Quinoa = 0.66 * parseFloat(csvrow[' "AU - Spinach"'])
                                            const Apple = 1 * parseFloat(csvrow[' "AU - Apple"'])
                                            const Apricot = 1 * parseFloat(csvrow[' "AU - Apricot"'])
                                            const Banana = 1 * parseFloat(csvrow[' "AU - Banana"'])
                                            const Blackberry = 1 * parseFloat(csvrow[' "AU - Blackberry"'])
                                            const Blueberry = 1 * parseFloat(csvrow[' "AU - Blueberry"'])
                                            const BlackOlive = 1 * parseFloat(csvrow[' "AU - Olive"'])
                                            const CantaloupeMelon = 1 * parseFloat(csvrow[' "AU - Cantaloupe Melon"'])
                                            const Cherry = 1 * parseFloat(csvrow[' "AU - Cherry"'])
                                            const Cranberry = 1 * parseFloat(csvrow[' "AU - Cranberry"'])
                                            const Date = 0.71 * parseFloat(csvrow[' "AU - Apricot"'])
                                            const Fig = 0.34 * parseFloat(csvrow[' "AU - Apricot"'])
                                            const GrapeRed = 1 * parseFloat(csvrow[' "AU - Grape - Red"'])
                                            const GrapeWhite = 1 * parseFloat(csvrow[' "AU - Grape - white"'])
                                            const Grapefruit = 1 * parseFloat(csvrow[' "AU - Grapefruit"'])
                                            const HoneydewMelon = 0.88 * parseFloat(csvrow[' "AU - Cantaloupe Melon"'])
                                            const Lemon = 1 * parseFloat(csvrow[' "AU - Lemon"'])
                                            const Lime = 0.74 * parseFloat(csvrow[' "AU - Lemon"'])
                                            const Lychee = 0.21 * parseFloat(csvrow[' "AU - Mango"'])
                                            const Mango = 1 * parseFloat(csvrow[' "AU - Mango"'])
                                            const Nectarine = 0.89 * parseFloat(csvrow[' "AU - Orange"'])
                                            const GreenOlive = 0.72 * parseFloat(csvrow[' "AU - Olive"'])
                                            const Orange = 1 * parseFloat(csvrow[' "AU - Orange"'])
                                            const Peach = 1 * parseFloat(csvrow[' "AU - Peach"'])
                                            const Pear = 1 * parseFloat(csvrow[' "AU - Pear"'])
                                            const PersianMelon = 0.31 * parseFloat(csvrow[' "AU - Cantaloupe Melon"'])
                                            const Pineapple = 1 * parseFloat(csvrow[' "AU - Pineapple"'])
                                            const Raisins = 0.79 * parseFloat(csvrow[' "AU - Grape - Red"'])
                                            const Pomegranate = 0.46 * parseFloat(csvrow[' "AU - Apricot"'])
                                            const Raspberry = 1 * parseFloat(csvrow[' "AU - Raspberry"'])
                                            const Strawberry = 1 * parseFloat(csvrow[' "AU - Strawberry"'])
                                            const Sultana = 1.23 * parseFloat(csvrow[' "AU - Grape - white"'])
                                            const Tangerine = 0.9 * parseFloat(csvrow[' "AU - Orange"'])
                                            const Watermelon = 1 * parseFloat(csvrow[' "AU - Watermelon"'])
                                            const Asparagus = 0.83 * parseFloat(csvrow[' "AU - Garden Pea"'])
                                            const Avocado = 1 * parseFloat(csvrow[' "AU - Avocado"'])
                                            const Beetroot = 0.71 * parseFloat(csvrow[' "AU - Spinach"'])
                                            const BokChoy = 0.65 * parseFloat(csvrow[' "AU - Sheep Milk"'])
                                            const Broccoli = 1 * parseFloat(csvrow[' "AU - Broccoli"'])
                                            const BrusselSprouts = 0.61 * parseFloat(csvrow[' "AU - Mustard"'])
                                            const Carrot = 1 * parseFloat(csvrow[' "AU - Carrot"'])
                                            const Cassava = 0.47 * parseFloat(csvrow[' "AU - Sweet Potato"'])
                                            const Cauliflower = 1 * parseFloat(csvrow[' "AU - Cauliflower"'])
                                            const Celery = 0.51 * parseFloat(csvrow[' "AU - Carrot"'])
                                            const ChickPea = 1 * parseFloat(csvrow[' "AU - Chick Pea"'])
                                            const CowPea = 0.61 * parseFloat(csvrow[' "AU - Garden Pea"'])
                                            const Ccumber = 1 * parseFloat(csvrow[' "AU - Cucumber"'])
                                            const FeildMushroom = 1 * parseFloat(csvrow[' "AU - Mushroom"'])
                                            const GardenPea = 1 * parseFloat(csvrow[' "AU - Garden Pea"'])
                                            const GreenPepper = 1 * parseFloat(csvrow[' "AU - Green Pepper"'])
                                            const GreenCabbage = 1 * parseFloat(csvrow[' "AU - Cabbage"'])
                                            const IcebergLettuce = 1 * parseFloat(csvrow[' "AU - Lettuce"'])
                                            const Kale = 1.27 * parseFloat(csvrow[' "AU - Sheep Milk"'])
                                            const Maize = 1.36 * parseFloat(csvrow[' "AU - Black Tea"'])
                                            const OysterMushroom = 0.73 * parseFloat(csvrow[' "AU - Mushroom"'])
                                            const Parsnip = 0.74 * parseFloat(csvrow[' "AU - Carrot"'])
                                            const PortebelloMushroom = 0.66 * parseFloat(csvrow[' "AU - Mushroom"'])
                                            const Pumpkin = 0.67 * parseFloat(csvrow[' "AU - Cucumber"'])
                                            const Radish = 0.64 * parseFloat(csvrow[' "AU - Broccoli"'])
                                            const RedCabbage = 0.57 * parseFloat(csvrow[' "AU - Cabbage"'])
                                            const RedOnion = 1.26 * parseFloat(csvrow[' "AU - Onion"'])
                                            const RedPepper = 0.71 * parseFloat(csvrow[' "AU - Green Pepper"'])
                                            const Rocket = 0.68 * parseFloat(csvrow[' "AU - Sheep Milk"'])
                                            const ShiitakeMushroom = 0.71 * parseFloat(csvrow[' "AU - Mushroom"'])
                                            const Spinach = 1 * parseFloat(csvrow[' "AU - Spinach"'])
                                            const SpringOnion = 0.64 * parseFloat(csvrow[' "AU - Onion"'])
                                            const SugarSnapPeas = 1.27 * parseFloat(csvrow[' "AU - Chick Pea"'])
                                            const SwedishTurnipSwede = 1 * parseFloat(csvrow[' "AU - Turnip"'])
                                            const Sweetcorn = 1 * parseFloat(csvrow[' "AU - Corn"'])
                                            const SweetPotato = 1 * parseFloat(csvrow[' "AU - Sweet Potato"'])
                                            const Tomato = 1 * parseFloat(csvrow[' "AU - Tomato"'])
                                            const Turnip = 0.68 * parseFloat(csvrow[' "AU - Turnip"'])
                                            const WhiteCabbage = 0.94 * parseFloat(csvrow[' "AU - Cabbage"'])
                                            const WhiteOnion = 1 * parseFloat(csvrow[' "AU - Onion"'])
                                            const WhitePotato = 1 * parseFloat(csvrow[' "AU - White Potato"'])
                                            const YellowPepper = 0.42 * parseFloat(csvrow[' "AU - Green Pepper"'])
                                            const Almond = 1 * parseFloat(csvrow[' "AU - Almond"'])
                                            const BrazilNut = 1 * parseFloat(csvrow[' "AU - Brazil Nut"'])
                                            const CashewNut = 1 * parseFloat(csvrow[' "AU - Cashew Nut"'])
                                            const ChestNut = 0.64 * parseFloat(csvrow[' "AU - Hazelnut"'])
                                            const ChiaSeeds = 0.47 * parseFloat(csvrow[' "AU - Flax Seed"'])
                                            const CocoaBean = 1 * parseFloat(csvrow[' "AU - Chocolate"'])
                                            const Coconut = 1 * parseFloat(csvrow[' "AU - Coconut"'])
                                            const FlaxSeeds = 1 * parseFloat(csvrow[' "AU - Flax Seed"'])
                                            const GreenBean = 1 * parseFloat(csvrow[' "AU - Green Bean"'])
                                            const Hazlenut = 1 * parseFloat(csvrow[' "AU - Hazelnut"'])
                                            const HaricotBean = 1 * parseFloat(csvrow[' "AU - Haricot Bean"'])
                                            const KidneyBean = 1 * parseFloat(csvrow[' "AU - Coffee"'])
                                            const MungBean = 0.74 * parseFloat(csvrow[' "AU - Haricot Bean"'])
                                            const Peanut = 1 * parseFloat(csvrow[' "AU - Peanut"'])
                                            const Pistachio = 1 * parseFloat(csvrow[' "AU - Pistachio"'])
                                            const PumpkinSeed = 1.74 * parseFloat(csvrow[' "AU - Cucumber"'])
                                            const SesameSeed = 1 * parseFloat(csvrow[' "AU - Sesame Seed"'])
                                            const Soybean = 1 * parseFloat(csvrow[' "AU - Soybean"'])
                                            const SunflowerSeed = 1 * parseFloat(csvrow[' "AU - Sunflower Seed"'])
                                            const VanillaBean = 1 * parseFloat(csvrow[' "AU - Vanilla Bean"'])
                                            const Walnut = 1 * parseFloat(csvrow[' "AU - Walnut"'])
                                            const CaseinCow = 1 * parseFloat(csvrow[' "AU - Milk"'])
                                            const CheddarCheese = 0.95 * parseFloat(csvrow[' "AU - Milk"'])
                                            const CottageCheese = 0.76 * parseFloat(csvrow[' "AU - Milk"'])
                                            const EggWhite = 1 * parseFloat(csvrow[' "AU - Egg white"'])
                                            const EggYolk = 1 * parseFloat(csvrow[' "AU - Egg Yolk"'])
                                            const FetaCheese = 0.62 * parseFloat(csvrow[' "AU - Sheep Milk"'])
                                            const GoatCheese = 0.6 * parseFloat(csvrow[' "AU - Goat Milk"'])
                                            const GoatMilk = 1 * parseFloat(csvrow[' "AU - Goat Milk"'])
                                            const MozzarellaCheese = 0.37 * parseFloat(csvrow[' "AU - Milk"'])
                                            const SheepMilk = 1 * parseFloat(csvrow[' "AU - Sheep Milk"'])
                                            const WheyCow = 1 * parseFloat(csvrow[' "AU - whey"'])
                                            const WholeEgg = 1.15 * parseFloat(csvrow[' "AU - Egg white"'])
                                            const WholeMilkcow = (parseFloat(csvrow[' "AU - whey"']) + parseFloat(csvrow[' "AU - Milk"'])) / 1.84;
                                            const Peppercorn = 0.21 * parseFloat(csvrow[' "AU - Green Pepper"'])
                                            const CayennePepper = 0.44 * parseFloat(csvrow[' "AU - Green Pepper"'])
                                            const Cinnamon = 1 * parseFloat(csvrow[' "AU - Cinnamon"'])
                                            const Chives = 0.56 * parseFloat(csvrow[' "AU - Onion"'])
                                            const CoconutOil = 0.86 * parseFloat(csvrow[' "AU - Coconut"'])
                                            const CornOil = 0.65 * parseFloat(csvrow[' "AU - Corn"'])
                                            const FlaxseedOil = 0.75 * parseFloat(csvrow[' "AU - Flax Seed"'])
                                            const Garlic = 1 * parseFloat(csvrow[' "AU - Garlic"'])
                                            const Ginger = 1 * parseFloat(csvrow[' "AU - Ginger"'])
                                            const Leek = 0.73 * parseFloat(csvrow[' "AU - Garlic"'])
                                            const LemonGrass = 0.74 * parseFloat(csvrow[' "AU - Wheatgrass"'])
                                            const Mint = 0.81 * parseFloat(csvrow[' "AU - Spinach"'])
                                            const Oregano = 0.39 * parseFloat(csvrow[' "AU - Spinach"'])
                                            const OliveOil = 0.68 * parseFloat(csvrow[' "AU - Olive"'])
                                            const Paprika = 0.31 * parseFloat(csvrow[' "AU - Green Pepper"'])
                                            const Parsley = 0.32 * parseFloat(csvrow[' "AU - Carrot"'])
                                            const PeanutOil = 0.48 * parseFloat(csvrow[' "AU - Peanut"'])
                                            const Sage = 0.64 * parseFloat(csvrow[' "AU - Spinach"'])
                                            const SesameSeedOil = 0.69 * parseFloat(csvrow[' "AU - Sesame Seed"'])
                                            const SunflowerOil = 0.89 * parseFloat(csvrow[' "AU - Sunflower Seed"'])
                                            const Turmeric = 0.74 * parseFloat(csvrow[' "AU - Ginger"'])
                                            const WhitePepper = 0.21 * parseFloat(csvrow[' "AU - Green Pepper"'])
                                            const AlmondMilk = 0.88 * parseFloat(csvrow[' "AU - Almond"'])
                                            const Blacktea = 1 * parseFloat(csvrow[' "AU - Black Tea"'])
                                            const Cocoa = 0.76 * parseFloat(csvrow[' "AU - Chocolate"'])
                                            const CashewMilk = 0.79 * parseFloat(csvrow[' "AU - Cashew Nut"'])
                                            const CoconutMilk = 0.71 * parseFloat(csvrow[' "AU - Coconut"'])
                                            const Coffee = 1 * parseFloat(csvrow[' "AU - Coffee"'])
                                            const Greentea = 0.54 * parseFloat(csvrow[' "AU - Black Tea"'])
                                            const HazlenutMilk = 0.51 * parseFloat(csvrow[' "AU - Hazelnut"'])
                                            const HempMilk = 0.51 * parseFloat(csvrow[' "AU - Hemp"'])
                                            const Oatmilk = 0.83 * parseFloat(csvrow[' "AU - Oat"'])
                                            const Ricemilk = 0.64 * parseFloat(csvrow[' "AU - Rice"'])
                                            const Soyamilk = 1.16 * parseFloat(csvrow[' "AU - Soybean"'])
                                            const Whitetea = 0.41 * parseFloat(csvrow[' "AU - Black Tea"'])
                                            const Beef = 1 * parseFloat(csvrow[' "AU - Beef"'])
                                            const Chicken = 1 * parseFloat(csvrow[' "AU - Chicken"'])
                                            const Duck = 1 * parseFloat(csvrow[' "AU - Duck"'])
                                            const Goat = 0.36 * parseFloat(csvrow[' "AU - Lamb"'])
                                            const Lamb = 1 * parseFloat(csvrow[' "AU - Lamb"'])
                                            const Pork = 1 * parseFloat(csvrow[' "AU - Pork "'])
                                            const Mutton = 0.75 * parseFloat(csvrow[' "AU - Lamb"'])
                                            const Turkey = 1 * parseFloat(csvrow[' "AU - Turkey"'])
                                            const Pheasant = 0.17 * parseFloat(csvrow[' "AU - Duck"'])
                                            const Veal = 0.86 * parseFloat(csvrow[' "AU - Beef"'])
                                            const Venison = 0.87 * parseFloat(csvrow[' "AU - Beef"'])
                                            const Anchovy = 0.65 * parseFloat(csvrow[' "AU - Sardine"'])
                                            const Cod = 1 * parseFloat(csvrow[' "AU - Codfish"'])
                                            const Crab = 1 * parseFloat(csvrow[' "AU - Crab"'])
                                            const Crayfish = 0.7 * parseFloat(csvrow[' "AU - Lobster"'])
                                            const Haddock = 1.18 * parseFloat(csvrow[' "AU - Codfish"'])
                                            const Lobster = 1 * parseFloat(csvrow[' "AU - Lobster"'])
                                            const Mackerel = 1 * parseFloat(csvrow[' "AU - Mackerel"'])
                                            const Prawn = 0.64 * parseFloat(csvrow[' "AU - Lobster"'])
                                            const Salmon = 1 * parseFloat(csvrow[' "AU - Salmon"'])
                                            const Sardine = 1 * parseFloat(csvrow[' "AU - Sardine"'])
                                            const Shrimp = 1 * parseFloat(csvrow[' "AU - Shrimp"'])
                                            const Squid = 0.57 * parseFloat(csvrow[' "AU - Shrimp"'])
                                            const TigerPrawn = 0.71 * parseFloat(csvrow[' "AU - Lobster"'])
                                            const Trout = 0.31 * parseFloat(csvrow[' "AU - Salmon"'])
                                            const Tuna = 1 * parseFloat(csvrow[' "AU - Tuna"'])
                                            const BakersYeast = 1.49 * parseFloat(csvrow[' "AU - Bak / Brew Yeast"'])
                                            const BarleyFlower = 1.36 * parseFloat(csvrow[' "AU - Barley Flour"'])
                                            const BalsamicVinager = 0.43 * parseFloat(csvrow[' "AU - Grape - white"'])
                                            const BrownLentils = 0.78 * parseFloat(csvrow[' "AU - Lentils"'])
                                            const BrewersYeast = 1 * parseFloat(csvrow[' "AU - Bak / Brew Yeast"'])
                                            const Citricacid = 0.64 * parseFloat(csvrow[' "AU - Lemon"'])
                                            const Cornflour = 1.97 * parseFloat(csvrow[' "AU - Corn"'])
                                            const Hemp = 1 * parseFloat(csvrow[' "AU - Hemp"'])
                                            const Hops = 1 * parseFloat(csvrow[' "AU - Hops"'])
                                            const Greenlentils = 0.85 * parseFloat(csvrow[' "AU - Lentils"'])
                                            const Horseradish = 1.26 * parseFloat(csvrow[' "AU - Cauliflower"'])
                                            const Maltvinegar = 0.41 * parseFloat(csvrow[' "AU - Barley Flour"'])
                                            const Mustard = 1 * parseFloat(csvrow[' "AU - Mustard"'])
                                            const Oatflour = 1.32 * parseFloat(csvrow[' "AU - Oat"'])
                                            const Riceflour = 1.37 * parseFloat(csvrow[' "AU - Rice"'])
                                            const Sugarcane = 0.76 * parseFloat(csvrow[' "AU - Wheatgrass"'])
                                            const VanillaEssence = 1.56 * parseFloat(csvrow[' "AU - Vanilla Bean"'])
                                            const Wasabi = 0.86 * parseFloat(csvrow[' "AU - Mustard"'])
                                            const Wheatgrass = 1 * parseFloat(csvrow[' "AU - Wheatgrass"'])
                                            const Wheatflour = 1.45 * parseFloat(csvrow[' "AU - Wheat"'])
                                            const BeefproteinIsolate = 1.36 * parseFloat(csvrow[' "AU - Beef"'])
                                            const CaseinproteinIsolate = 0.87 * parseFloat(csvrow[' "AU - Milk"'])
                                            const HempproteinIsolate = 0.87 * parseFloat(csvrow[' "AU - Hemp"'])
                                            const CollagenBovine = 0.56 * parseFloat(csvrow[' "AU - Beef"'])
                                            const MarineCollagen = 0.79 * parseFloat(csvrow[' "AU - Salmon"'])
                                            const PeaProteinIsolate = 0.89 * parseFloat(csvrow[' "AU - Garden Pea"'])
                                            const RiceProteinIsolate = 0.74 * parseFloat(csvrow[' "AU - Rice"'])
                                            const SoyProteinIsolate = 1.19 * parseFloat(csvrow[' "AU - Soybean"'])
                                            const Spirulina = 0.21 * parseFloat(csvrow[' "AU - Spinach"'])
                                            const WheyProteinIsolate = 0.84 * parseFloat(csvrow[' "AU - whey"'])


                                            const desiredVariables = [
                                                Berley,
                                                GlutenGliadin,
                                                Rye,
                                                Wheat,
                                                Spelt,
                                                BrownRice,
                                                Buckwheat,
                                                Oat,

                                                WhiteRice,
                                                Quinoa,
                                                Apple,
                                                Apricot,
                                                Banana,
                                                Blackberry,
                                                Blueberry,
                                                BlackOlive,
                                                CantaloupeMelon,
                                                Cherry,
                                                Cranberry,
                                                Date,
                                                Fig,
                                                GrapeRed,
                                                GrapeWhite,
                                                Grapefruit,
                                                HoneydewMelon,
                                                Lemon,
                                                Lime,
                                                Lychee,
                                                Mango,
                                                Nectarine,
                                                GreenOlive,
                                                Orange,
                                                Peach,
                                                Pear,
                                                PersianMelon,
                                                Pineapple,
                                                Raisins,
                                                Pomegranate,
                                                Raspberry,
                                                Strawberry,
                                                Sultana,
                                                Tangerine,
                                                Watermelon,
                                                Asparagus,
                                                Avocado,
                                                Beetroot,
                                                BokChoy,
                                                Broccoli,
                                                BrusselSprouts,
                                                Carrot,
                                                Cassava,
                                                Cauliflower,
                                                Celery,
                                                ChickPea,
                                                CowPea,
                                                Ccumber,
                                                FeildMushroom,
                                                GardenPea,
                                                GreenPepper,
                                                GreenCabbage,
                                                IcebergLettuce,
                                                Kale,
                                                Maize,
                                                OysterMushroom,
                                                Parsnip,
                                                PortebelloMushroom,
                                                Pumpkin,
                                                Radish,
                                                RedCabbage,
                                                RedOnion,
                                                RedPepper,
                                                Rocket,
                                                ShiitakeMushroom,
                                                Spinach,
                                                SpringOnion,
                                                SugarSnapPeas,
                                                SwedishTurnipSwede,
                                                Sweetcorn,
                                                SweetPotato,
                                                Tomato,
                                                Turnip,
                                                WhiteCabbage,
                                                WhiteOnion,
                                                WhitePotato,
                                                YellowPepper,
                                                Almond,
                                                BrazilNut,
                                                CashewNut,
                                                ChestNut,
                                                ChiaSeeds,
                                                CocoaBean,
                                                Coconut,
                                                FlaxSeeds,
                                                GreenBean,
                                                Hazlenut,
                                                HaricotBean,
                                                KidneyBean,
                                                MungBean,
                                                Peanut,
                                                Pistachio,
                                                PumpkinSeed,
                                                SesameSeed,
                                                Soybean,
                                                SunflowerSeed,
                                                VanillaBean,
                                                Walnut,
                                                CaseinCow,
                                                CheddarCheese,
                                                CottageCheese,
                                                EggWhite,
                                                EggYolk,
                                                FetaCheese,
                                                GoatCheese,
                                                GoatMilk,
                                                MozzarellaCheese,
                                                SheepMilk,
                                                WheyCow,
                                                WholeEgg,
                                                WholeMilkcow,
                                                Peppercorn,
                                                CayennePepper,
                                                Cinnamon,
                                                Chives,
                                                CoconutOil,
                                                CornOil,
                                                FlaxseedOil,
                                                Garlic,
                                                Ginger,
                                                Leek,
                                                LemonGrass,
                                                Mint,
                                                Oregano,
                                                OliveOil,
                                                Paprika,
                                                Parsley,
                                                PeanutOil,
                                                Sage,
                                                SesameSeedOil,
                                                SunflowerOil,
                                                Turmeric,
                                                WhitePepper,
                                                AlmondMilk,
                                                Blacktea,
                                                Cocoa,
                                                CashewMilk,
                                                CoconutMilk,
                                                Coffee,
                                                Greentea,
                                                HazlenutMilk,
                                                Oatmilk,
                                                HempMilk,
                                                Ricemilk,
                                                Soyamilk,
                                                Whitetea,
                                                Beef,
                                                Chicken,
                                                Duck,
                                                Goat,
                                                Lamb,
                                                Pork,
                                                Mutton,
                                                Turkey,
                                                Pheasant,
                                                Veal,
                                                Venison,
                                                Anchovy,
                                                Cod,
                                                Crab,
                                                Crayfish,
                                                Haddock,
                                                Lobster,
                                                Mackerel,
                                                Prawn,
                                                Salmon,
                                                Sardine,
                                                Shrimp,
                                                Squid,
                                                TigerPrawn,
                                                Trout,
                                                Tuna,
                                                BakersYeast,
                                                BarleyFlower,
                                                BalsamicVinager,
                                                BrownLentils,
                                                BrewersYeast,
                                                Citricacid,
                                                Cornflour,
                                                Hemp,
                                                Hops,
                                                Greenlentils,
                                                Horseradish,
                                                Maltvinegar,
                                                Mustard,
                                                Oatflour,
                                                Riceflour,
                                                Sugarcane,
                                                VanillaEssence,
                                                Wasabi,
                                                Wheatgrass,
                                                Wheatflour,
                                                BeefproteinIsolate,
                                                CaseinproteinIsolate,
                                                HempproteinIsolate,
                                                CollagenBovine,
                                                MarineCollagen,
                                                PeaProteinIsolate,
                                                RiceProteinIsolate,
                                                SoyProteinIsolate,
                                                Spirulina,
                                                WheyProteinIsolate,

                                            ];






                                            return desiredVariables;
                                        }


                                        const datacalculation2 = () => {


                                            const Barley = 1 * parseFloat(csvrow[' "AU - Barley Flour"'])
                                            const GlutenGliadin = 1 * parseFloat(csvrow[' "AU - Gluten"'])
                                            const Wheat = 1 * parseFloat(csvrow[' "AU - Wheat"'])
                                            const WhiteRice = 1 * parseFloat(csvrow[' "AU - Rice"'])
                                            const Oat = 1 * parseFloat(csvrow[' "AU - Oat"'])
                                            const Apple = 1 * parseFloat(csvrow[' "AU - Apple"'])
                                            const Apricot = 1 * parseFloat(csvrow[' "AU - Apricot"'])
                                            const Banana = 1 * parseFloat(csvrow[' "AU - Banana"'])
                                            const Blackberry = 1 * parseFloat(csvrow[' "AU - Blackberry"'])
                                            const Blueberry = 1 * parseFloat(csvrow[' "AU - Blueberry"'])
                                            const BlackOlive = 1 * parseFloat(csvrow[' "AU - Olive"'])
                                            const CantaloupeMelon = 1 * parseFloat(csvrow[' "AU - Cantaloupe Melon"'])
                                            const Cherry = 1 * parseFloat(csvrow[' "AU - Cherry"'])
                                            const Cranberry = 1 * parseFloat(csvrow[' "AU - Cranberry"'])
                                            const GrapeRed = 1 * parseFloat(csvrow[' "AU - Grape - Red"'])
                                            const GrapeWhite = 1 * parseFloat(csvrow[' "AU - Grape - white"'])
                                            const Grapefruit = 1 * parseFloat(csvrow[' "AU - Grapefruit"'])
                                            const HoneydewMelon = 0.88 * parseFloat(csvrow[' "AU - Cantaloupe Melon"'])
                                            const Lemon = 1 * parseFloat(csvrow[' "AU - Lemon"'])
                                            const Lime = 0.74 * parseFloat(csvrow[' "AU - Lemon"'])
                                            const Lychee = 0.21 * parseFloat(csvrow[' "AU - Mango"'])
                                            const Mango = 1 * parseFloat(csvrow[' "AU - Mango"'])
                                            const Nectarine = 0.89 * parseFloat(csvrow[' "AU - Orange"'])
                                            const GreenOlive = 0.72 * parseFloat(csvrow[' "AU - Olive"'])
                                            const Orange = 1 * parseFloat(csvrow[' "AU - Orange"'])
                                            const Peach = 1 * parseFloat(csvrow[' "AU - Peach"'])
                                            const Pear = 1 * parseFloat(csvrow[' "AU - Pear"'])
                                            const PersianMelon = 0.31 * parseFloat(csvrow[' "AU - Cantaloupe Melon"'])
                                            const Pineapple = 1 * parseFloat(csvrow[' "AU - Pineapple"'])
                                            const Raspberry = 1 * parseFloat(csvrow[' "AU - Raspberry"'])
                                            const Strawberry = 1 * parseFloat(csvrow[' "AU - Strawberry"'])
                                            const Tangerine = 0.9 * parseFloat(csvrow[' "AU - Orange"'])
                                            const Watermelon = 1 * parseFloat(csvrow[' "AU - Watermelon"'])
                                            const Asparagus = 0.83 * parseFloat(csvrow[' "AU - Garden Pea"'])
                                            const Avocado = 1 * parseFloat(csvrow[' "AU - Avocado"'])
                                            const Beetroot = 0.71 * parseFloat(csvrow[' "AU - Spinach"'])
                                            const Broccoli = 1 * parseFloat(csvrow[' "AU - Broccoli"'])
                                            const BrusselSprouts = 0.61 * parseFloat(csvrow[' "AU - Mustard"'])
                                            const Carrot = 1 * parseFloat(csvrow[' "AU - Carrot"'])
                                            const Cauliflower = 1 * parseFloat(csvrow[' "AU - Cauliflower"'])
                                            const Celery = 0.51 * parseFloat(csvrow[' "AU - Carrot"'])
                                            const ChickPea = 1 * parseFloat(csvrow[' "AU - Chick Pea"'])
                                            const CowPea = 0.61 * parseFloat(csvrow[' "AU - Garden Pea"'])
                                            const Cucumber = 1 * parseFloat(csvrow[' "AU - Cucumber"'])
                                            const Mushroom = 1 * parseFloat(csvrow[' "AU - Mushroom"'])
                                            const GardenPea = 1 * parseFloat(csvrow[' "AU - Garden Pea"'])
                                            const Peppers = 1 * parseFloat(csvrow[' "AU - Green Pepper"'])
                                            const GreenCabbage = 1 * parseFloat(csvrow[' "AU - Cabbage"'])
                                            const IcebergLettuce = 1 * parseFloat(csvrow[' "AU - Lettuce"'])
                                            const Kale = 1.27 * parseFloat(csvrow[' "AU - Sheep Milk"'])
                                            const Maize = 1.36 * parseFloat(csvrow[' "AU - Black Tea"'])
                                            const Pumpkin = 0.67 * parseFloat(csvrow[' "AU - Mushroom"'])
                                            const Rocket = 0.68 * parseFloat(csvrow[' "AU - Sheep Milk"'])
                                            const Spinach = 1 * parseFloat(csvrow[' "AU - Spinach"'])
                                            const SwedishTurnipSwede = 1 * parseFloat(csvrow[' "AU - Turnip"'])
                                            const Sweetcorn = 1 * parseFloat(csvrow[' "AU - Corn"'])
                                            const SweetPotato = 1 * parseFloat(csvrow[' "AU - Sweet Potato"'])
                                            const Tomato = 1 * parseFloat(csvrow[' "AU - Tomato"'])
                                            const WhiteOnion = 1 * parseFloat(csvrow[' "AU - Onion"'])
                                            const WhitePotato = 1 * parseFloat(csvrow[' "AU - White Potato"'])
                                            const Almond = 1 * parseFloat(csvrow[' "AU - Almond"'])
                                            const BrazilNut = 1 * parseFloat(csvrow[' "AU - Brazil Nut"'])
                                            const CashewNut = 1 * parseFloat(csvrow[' "AU - Cashew Nut"'])
                                            const CocoaBean = 1 * parseFloat(csvrow[' "AU - Chocolate"'])
                                            const Coconut = 1 * parseFloat(csvrow[' "AU - Coconut"'])
                                            const FlaxSeed = 1 * parseFloat(csvrow[' "AU - Flax Seed"'])
                                            const GreenBean = 1 * parseFloat(csvrow[' "AU - Green Bean"'])
                                            const Hazlenut = 1 * parseFloat(csvrow[' "AU - Hazelnut"'])
                                            const HaricotBean = 1 * parseFloat(csvrow[' "AU - Haricot Bean"'])
                                            const KidneyBean = 1 * parseFloat(csvrow[' "AU - Coffee"'])
                                            const Peanut = 1 * parseFloat(csvrow[' "AU - Peanut"'])
                                            const Pistachio = 1 * parseFloat(csvrow[' "AU - Pistachio"'])
                                            const SesameSeed = 1 * parseFloat(csvrow[' "AU - Sesame Seed"'])
                                            const Soybean = 1 * parseFloat(csvrow[' "AU - Soybean"'])
                                            const SunflowerSeed = 1 * parseFloat(csvrow[' "AU - Sunflower Seed"'])
                                            const VanillaBean = 1 * parseFloat(csvrow[' "AU - Vanilla Bean"'])
                                            const Walnut = 1 * parseFloat(csvrow[' "AU - Walnut"'])
                                            const EggWhite = 1 * parseFloat(csvrow[' "AU - Egg white"'])
                                            const EggYolk = 1 * parseFloat(csvrow[' "AU - Egg Yolk"'])
                                            const GoatMilk = 1 * parseFloat(csvrow[' "AU - Goat Milk"'])
                                            const WholeEgg = 1.15 * parseFloat(csvrow[' "AU - Egg white"'])
                                            const WholeMilkcow = (parseFloat(csvrow[' "AU - whey"']) + parseFloat(csvrow[' "AU - Milk"'])) / 1.84;
                                            const Cinnamon = 1 * parseFloat(csvrow[' "AU - Cinnamon"'])
                                            const Garlic = 1 * parseFloat(csvrow[' "AU - Garlic"'])
                                            const Ginger = 1 * parseFloat(csvrow[' "AU - Ginger"'])
                                            const Beef = 1 * parseFloat(csvrow[' "AU - Beef"'])
                                            const Chicken = 1 * parseFloat(csvrow[' "AU - Chicken"'])
                                            const Lamb = 1 * parseFloat(csvrow[' "AU - Lamb"'])
                                            const Pork = 1 * parseFloat(csvrow[' "AU - Pork "'])
                                            const Turkey = 1 * parseFloat(csvrow[' "AU - Turkey"'])
                                            const Cod = 1 * parseFloat(csvrow[' "AU - Codfish"'])
                                            const Crab = 1 * parseFloat(csvrow[' "AU - Crab"'])
                                            const Lobster = 1 * parseFloat(csvrow[' "AU - Lobster"'])
                                            const Mackerel = 1 * parseFloat(csvrow[' "AU - Mackerel"'])
                                            const Salmon = 1 * parseFloat(csvrow[' "AU - Salmon"'])
                                            const Sardine = 1 * parseFloat(csvrow[' "AU - Sardine"'])
                                            const Shrimp = 1 * parseFloat(csvrow[' "AU - Shrimp"'])
                                            const Tuna = 1 * parseFloat(csvrow[' "AU - Tuna"'])
                                            const BakersYeast = 1.49 * parseFloat(csvrow[' "AU - Bak / Brew Yeast"'])
                                            const BrewersYeast = 1 * parseFloat(csvrow[' "AU - Bak / Brew Yeast"'])



                                            const desiredVariables = [
                                                Barley,
                                                GlutenGliadin,
                                                Wheat,
                                                WhiteRice,
                                                Oat,
                                                Apple,
                                                Apricot,
                                                Banana,
                                                Blackberry,
                                                Blueberry,
                                                BlackOlive,
                                                CantaloupeMelon,
                                                Cherry,
                                                Cranberry,
                                                GrapeRed,
                                                GrapeWhite,
                                                Grapefruit,
                                                HoneydewMelon,
                                                Lemon,
                                                Lime,
                                                Lychee,
                                                Mango,
                                                Nectarine,
                                                GreenOlive,
                                                Orange,
                                                Peach,
                                                Pear,
                                                PersianMelon,
                                                Pineapple,
                                                Raspberry,
                                                Strawberry,
                                                Tangerine,
                                                Watermelon,
                                                Asparagus,
                                                Avocado,
                                                Beetroot,
                                                Broccoli,
                                                BrusselSprouts,
                                                Carrot,
                                                Cauliflower,
                                                Celery,
                                                ChickPea,
                                                CowPea,
                                                Cucumber,
                                                Mushroom,
                                                GardenPea,
                                                Peppers,
                                                GreenCabbage,
                                                IcebergLettuce,
                                                Kale,
                                                Maize,
                                                Pumpkin,
                                                Rocket,
                                                Spinach,
                                                SwedishTurnipSwede,
                                                Sweetcorn,
                                                SweetPotato,
                                                Tomato,
                                                WhiteOnion,
                                                WhitePotato,
                                                Almond,
                                                BrazilNut,
                                                CashewNut,
                                                CocoaBean,
                                                Coconut,
                                                FlaxSeed,
                                                GreenBean,
                                                Hazlenut,
                                                HaricotBean,
                                                KidneyBean,
                                                Peanut,
                                                Pistachio,
                                                SesameSeed,
                                                Soybean,
                                                SunflowerSeed,
                                                VanillaBean,
                                                Walnut,
                                                EggWhite,
                                                EggYolk,
                                                GoatMilk,
                                                WholeEgg,
                                                WholeMilkcow,
                                                Cinnamon,
                                                Garlic,
                                                Ginger,
                                                Beef,
                                                Chicken,
                                                Lamb,
                                                Pork,
                                                Turkey,
                                                Cod,
                                                Crab,
                                                Lobster,
                                                Mackerel,
                                                Salmon,
                                                Sardine,
                                                Shrimp,
                                                Tuna,
                                                BakersYeast,
                                                BrewersYeast,
                                            ];


                                            // console.log(desiredVariables);



                                            return desiredVariables;
                                        }



                                        const today = new Date();
                                        const dd = String(today.getDate()).padStart(2, '0');
                                        const mm = String(today.getMonth() + 1).padStart(2, '0');
                                        const yyyy = today.getFullYear();
                                        const formattedDate = `${dd}/${mm}/${yyyy}`;


                                        const patientName = csvrow[' "First Name"'] + " " + csvrow[' "Last Name"']
                                        const SampleDate = csvrow[' "Sample Date"']
                                        const DOB = csvrow[' "Date of birth"']



                                        const result = datacalculation()
                                        const result2 = datacalculation2()

                                        const kitID = parseFloat(csvrow[' "Kit ID"']);
                                        const resultof = { DOB: DOB, resultDate: formattedDate, patientName: patientName, SampleDate: SampleDate };
                                        const resultvalue = { resultfor210: result, resultfor100: result2, };


                                        await senttobackend(kitID, resultof, resultvalue); // Async call to backend

                                    }
                                }
                                resolve(); // Resolve the Promise after processing is complete
                            } catch (error) {
                                reject(error); // Reject the Promise if any error occurs
                            }
                        },
                        error: (error) => {
                            reject(error); // Reject if parsing fails
                        },
                    });
                });
            }
        }



        await setMessages((prevMessages) => [...prevMessages, `<p style="color:#6E4E9F;">All Results has been uploaded </p>`]);



        await setloading(false)
    }


    const senttobackend = async (kitID, resultof, resultvalue) => {



        let data = JSON.stringify({
            "kitID": kitID,
            "resultof": resultof,
            "resultvalue": resultvalue,
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${apiurlforbackend}/uploadkitresults`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                setMessages((prevMessages) => [...prevMessages, response.data]);
            })
            .catch((error) => {
                console.log(error);
            });

        return 1

    }



    let sno1 = 1



    const messagesEndRef = useRef(null); // Reference to the bottom of the message list

    // Scroll to the bottom when the Message array changes
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [Message]);

    return (<>


        <div className='adminkitmainbody'>
            <div className='header'>
                <h1><FaUpload /> Upload Results</h1>

            </div>


            {loading2 ? (<>
                {/* <img alt='' src='/empty.gif' width={"20%"} /> */}
                <h3>Logs</h3>
                <div className='showreportlogs'>
                    {Message.map((value, index) => (<>
                        <div
                            dangerouslySetInnerHTML={{ __html: value }}
                        />



                    </>))}
                    {loading ? (<>
                        <img alt='' src='/empty.gif' width={"5%"} />
                    </>) : (<>
                        <Button onClick={() => {
                            setMessages([])
                            setloading2(false)
                            setfiles([])
                        }} danger>Clear</Button >
                    </>)}
                    <div ref={messagesEndRef} />
                </div>
            </>) : (<>

                <div className="file-drop-zone"


                    onDrop={handleFileDrop}
                    onDragOver={(e) => e.preventDefault()}

                >

                    <label className="custom-file-upload" >

                        <div className='clickablediv'>


                            <ImUpload2 style={{ width: '100px', height: '100px' }} />
                            <h4>Click or drag file to this area to upload</h4>


                        </div>
                        <input
                            multiple="on"
                            ref={fileInputRef}
                            className='inputbuttontouploadfile'
                            type="file"
                            accept=".csv,.pdf"
                            onChange={handleFileUpload}
                        />

                    </label>

                </div>


                {/* <p style={{ color: "red" }}>{Message}</p> */}




                {files.map((value, index2) => {

                    return (<>
                        <div className='filename'>  <p>{sno1++})- {value.name}</p><AiFillDelete onClick={() => { setfiles((prevFiles) => prevFiles.filter((_, index) => index !== index2)); }} className='hovar' style={{ width: '30px', height: '30px', color: 'red' }} /></div>
                    </>)
                })}




                <div className='bownpopupbutton'>
                    <button onClick={() => {
                        setfiles([])
                    }
                    } style={{ border: '1px solid red', color: 'red' }} type='button' >CLEAR</button>






                    {files.length > 0 && <>
                        <button onClick={datauploaded} style={{ backgroundColor: '#4180b7' }}>Upload</button>
                    </>}



                </div>
            </>)}





        </div>






    </>)
}









export default Uploadresults
