
import React, { useState } from 'react'
import Cookies from 'js-cookie';

import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import "../css/meeting.css"
import { useEffect } from 'react';
import { AutoComplete, Button, Descriptions, Image, Input, message, Popconfirm, Select, TimePicker } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import 'react-quill/dist/quill.snow.css';
import { ImCross } from 'react-icons/im';


import { SiGooglemeet } from 'react-icons/si';
import { IoSettings } from 'react-icons/io5';

import { DatePicker, Space } from 'antd';

import dayjs from 'dayjs';
import { FaPlus } from 'react-icons/fa';


const { RangePicker } = DatePicker;



function Meetingsdetails() {
    // const loginemail = Cookies.get("email")
    const loginname = Cookies.get("Name")
    const { id } = useParams();
    const token = Cookies.get("Token")

    const [searchParams] = useSearchParams();

    const loginemail = searchParams.get('email')


    const [question, setquestion] = useState([])
    const navigate = useNavigate()

    const [popup, setpopup] = useState(false)
    const [popup2, setpopup2] = useState(false)
    const [popup3, setpopup3] = useState(false)

    const [addfield, setaddfield] = useState(false)
    const [addfield2, setaddfield2] = useState(false)






    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("id", id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getbookingmeetbyuserid", requestOptions)
            .then((response) => response.json())
            .then((result) => setquestion(result))
            .catch((error) => console.error(error))




    }, [])





    const [Fields, setFields] = useState([
        { type: "text", title: "Name", require: true },
        { type: "email", title: "Email", require: true },

    ])

    const [Fieldstitle, setFieldstitle] = useState("")
    const [Fieldreq, setFieldreq] = useState(false)



    const [rangedate, setrangedate] = useState([])

    const [Monday, setMonday] = useState(false)
    const [Tuesday, setTuesday] = useState(false)
    const [Wednesday, setWednesday] = useState(false)
    const [Thursday, setThursday] = useState(false)
    const [Friday, setFriday] = useState(false)
    const [Saturday, setSaturday] = useState(false)
    const [Sunday, setSunday] = useState(false)


    const [Category, setCategory] = useState('');

    const [Mondaytime, setMondaytime] = useState([])
    const [Tuesdaytime, setTuesdaytime] = useState([])
    const [Wednesdaytime, setWednesdaytime] = useState([])
    const [Thursdaytime, setThursdaytime] = useState([])
    const [Fridaytime, setFridaytime] = useState([])
    const [Saturdaytime, setSaturdaytime] = useState([])
    const [Sundaytime, setSundaytime] = useState([])
    const [duration, setduration] = useState("60")
    const [discribtion, setdiscribtion] = useState("")
    const [rebin, setrebin] = useState([])

    const [Spacificday, setSpacificday] = useState([])
    const [Dateexception, setDateexception] = useState([])


    // const [Schedule, setSchedule] = useState("")
    // const [Schedule, setSchedule] = useState("")

    const firstform = (e) => {





        setpopup2(true)
        setpopup(false)








    }


    const [type, settype] = useState("")

    const submitsecontform = async (e) => {
        e.preventDefault()


        await setFields(prevFields => [
            ...prevFields,
            { type: type, title: Fieldstitle, require: Fieldreq, option: option }
        ]);
        setoption([{ value: "", placeholder: "Option 1" }, { value: "", placeholder: "Option 2" }])

        setFieldreq(false)
        console.log(Fields)

        setaddfield(false)
        setaddfield2(false)
    }

    const [option, setoption] = useState([{ value: "", placeholder: "Option 1" }, { value: "", placeholder: "Option 2" }])
    // const [option2, setoption2] = useState([])


    const editField = (index, newValue) => {
        // Create a new array with updated value at the specified index
        const updatedOptions = option.map((opt, i) =>
            i === index ? { ...opt, value: newValue } : opt
        );
        // Update the state with the new array
        setoption(updatedOptions);
    };








    const sentdatatobackend = async () => {

        const hide = message.loading("Action in progress", 0)




        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();



        urlencoded.append("rangedate", JSON.stringify(rangedate));


        urlencoded.append("id", id);
        urlencoded.append("Category", Category);
        urlencoded.append("Monday", Monday);
        urlencoded.append("Tuesday", Tuesday);
        urlencoded.append("Wednesday", Wednesday);
        urlencoded.append("Thursday", Thursday);
        urlencoded.append("Friday", Friday);
        urlencoded.append("Saturday", Saturday);
        urlencoded.append("Sunday", Sunday);


        urlencoded.append("Sundaytime", JSON.stringify(Sundaytime));
        urlencoded.append("Saturdaytime", JSON.stringify(Saturdaytime));
        urlencoded.append("Fridaytime", JSON.stringify(Fridaytime));
        urlencoded.append("Thursdaytime", JSON.stringify(Thursdaytime));
        urlencoded.append("Wednesdaytime", JSON.stringify(Wednesdaytime));
        urlencoded.append("Tuesdaytime", JSON.stringify(Tuesdaytime));
        urlencoded.append("Mondaytime", JSON.stringify(Mondaytime));
        urlencoded.append("duration", duration);
        urlencoded.append("discribtion", discribtion);
        urlencoded.append("rebin", JSON.stringify(rebin));

        urlencoded.append("Spacificday", JSON.stringify(Spacificday));
        urlencoded.append("Fields", JSON.stringify(Fields));
        urlencoded.append("Dateexception", JSON.stringify(Dateexception));

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/meetscheduleedit", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                setmeet(result)
                setpopup(false)
                setpopup2(false)
                setpopup3(false)

            })
            .catch((error) => console.error(error));

        await setTimeout(() => {
            hide(); // Call hide to stop the loading message
            message.success("Action completed successfully");
        }, 2000);

    }

    const [meet, setmeet] = useState([])

    const updatefields = async () => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("email", loginemail);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getmeetschudulebyemail", requestOptions)
            .then((response) => response.json())
            .then(async (result) => {

                // await setmeet(result)

                setFields(result.Fields)


                setrangedate(result.rangedate)

                setMonday(result.Monday)
                setTuesday(result.Tuesday)
                setWednesday(result.Wednesday)
                setThursday(result.Thursday)
                setFriday(result.Friday)
                setSaturday(result.Saturday)
                setSunday(result.Sunday)


                setMondaytime(result.Mondaytime);

                setTuesdaytime(result.Tuesdaytime);

                setWednesdaytime(result.Wednesdaytime);

                setThursdaytime(result.Thursdaytime);

                setFridaytime(result.Fridaytime);

                setSaturdaytime(result.Saturdaytime);

                setSundaytime(result.Sundaytime);

                setDateexception(result.Dateexception)
                setdiscribtion(result.discribtion)
                setCategory(result.Category)
                setrebin(result.rebin)

                setduration(parseInt(result.duration))

                setSpacificday(result.Spacificday)

            })



            .catch((error) => console.error(error));

    }

    useEffect(() => {
        updatefields()
    }, [meet])

    const [seequetion, setseequetion] = useState(true)

    var sno1 = 1
    var sno2 = 1












    const [show, setshow] = useState(false)



    useEffect(() => {



        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("email", loginemail);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };


        fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getmeetschudulebyemail", requestOptions)
            .then((response) => response.json())
            .then(async (result) => {

                if (id === result.id) {
                    setshow(true)

                }

            })



            .catch((error) => console.error(error));



    }, [])






 

    return (
        <div>
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>
                <Navbar />
                <div className='deshboardmain'>
                    <Sidemanu />
                    <div className='adminkitmainbody'>
                        {show ? (<>
                            <div className='header'>

                                <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><SiGooglemeet style={{ width: '50px', height: '50px' }} />Meetings Details for {loginemail}</h1>

                            </div>



                            <div style={{ display: 'flex' }}>
                                {seequetion ? (<>

                                    <h4 className='qqqqqqq qqqqqqqqqqq' style={{ display: 'flex', margin: "0" }}> Upcoming Meetings</h4>
                                    <h4 className='qqqqqqq ' onClick={() => setseequetion(!seequetion)} style={{ display: 'flex', margin: "0" }}> Past Meetings</h4>

                                </>) : (<>


                                    <h4 className='qqqqqqq ' onClick={() => setseequetion(!seequetion)} style={{ display: 'flex', margin: "0" }}> Upcoming Meetings</h4>
                                    <h4 className='qqqqqqq qqqqqqqqqqq' style={{ display: 'flex', margin: "0" }}> Past Meetings</h4>

                                </>)}

                            </div>
                            <div className='addbutton'>


                                <button onClick={() => {
                                    setpopup(true)
                                }}><IoSettings style={{ width: '20px', height: '20px' }} /> Schedule Settings</button></div>





                            {popup === true && <>
                                <div onClick={() => {
                                    setpopup(false)
                                    setpopup2(false)

                                }
                                } className='popupbg'></div>
                                <div className='popup'>


                                    <h2 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}>Setting Schedule</h2>

                                    <form className='schduleform' onSubmit={firstform}>

                                        <div className='settingschule'>
                                            <div className='settingschuleitem'>  <label>Date Range</label>
                                                <RangePicker value={[dayjs(rangedate[0], "YYYY-MM-DD"), dayjs(rangedate[1], "YYYY-MM-DD"),]} required onChange={(value, e) => setrangedate(e)} />


                                            </div>

                                            <div className='settingschuleitem'>
                                                <label>Time Range</label>
                                                <div className='timepickerr'>
                                                    <div className='timepickerr2'>
                                                        <input checked={Monday} onChange={(e) => {
                                                            setMonday(e.target.checked)


                                                        }} style={{ width: '20px', height: "20px" }} type='checkbox' />
                                                        <label>Monday</label>
                                                    </div>

                                                    {Monday ? (<>

                                                        <div style={{ display: "flex", flexDirection: 'column' }}>

                                                            <div style={{ display: 'flex', alignItems: "center" }}>
                                                                <TimePicker.RangePicker
                                                                    value={(Mondaytime[0] !== "" && Mondaytime.length > 0 && Mondaytime[0] !== undefined) ? [dayjs(Mondaytime[0], "h:mm a"), dayjs(Mondaytime[1], "h:mm a")] : []}
                                                                    format="h:mm a" use12Hours required
                                                                    onChange={(e, value) => {

                                                                        const addfeild = value; // Values to be added

                                                                        setMondaytime(prevState => {
                                                                            const newState = [...prevState]; // Clone the previous state
                                                                            newState[0] = addfeild[0]; // Add to index 2
                                                                            newState[1] = addfeild[1]; // Add to index 3
                                                                            return newState; // Return the updated state
                                                                        });

                                                                    }}

                                                                />

                                                                {Mondaytime.length < 3 && <>
                                                                    <FaPlus
                                                                        onClick={() => {
                                                                            const addfeild = ["", ""]; // Values to be added

                                                                            setMondaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[2] = addfeild[0]; // Add to index 2
                                                                                newState[3] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                    />
                                                                </>}


                                                            </div>


                                                            {Mondaytime.length > 3 && <>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={Mondaytime[2] !== "" ? [dayjs(Mondaytime[2], "h:mm a"), dayjs(Mondaytime[3], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value) => {

                                                                            const addfeild = value; // Values to be added

                                                                            setMondaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[2] = addfeild[0]; // Add to index 2
                                                                                newState[3] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });

                                                                        }}

                                                                    />


                                                                    <ImCross
                                                                        onClick={() => {
                                                                            setMondaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState.splice(2, 2); // Remove 2 elements starting from index 2
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                    />

                                                                    {(Mondaytime.length > 3 && Mondaytime.length < 5) && <>
                                                                        <FaPlus
                                                                            onClick={() => {
                                                                                const addfeild = ["", ""]; // Values to be added

                                                                                setMondaytime(prevState => {
                                                                                    const newState = [...prevState]; // Clone the previous state
                                                                                    newState[4] = addfeild[0]; // Add to index 2
                                                                                    newState[5] = addfeild[1]; // Add to index 3
                                                                                    return newState; // Return the updated state
                                                                                });
                                                                            }}
                                                                            style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                        />
                                                                    </>}
                                                                </div>
                                                            </>}

                                                            {Mondaytime.length > 5 && <>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={Mondaytime[4] !== "" ? [dayjs(Mondaytime[4], "h:mm a"), dayjs(Mondaytime[5], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value) => {

                                                                            const addfeild = value; // Values to be added

                                                                            setMondaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[4] = addfeild[0]; // Add to index 2
                                                                                newState[5] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });

                                                                        }}

                                                                    />

                                                                    <ImCross
                                                                        onClick={() => {
                                                                            setMondaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState.splice(2, 2); // Remove 2 elements starting from index 2
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                    />
                                                                </div>
                                                            </>}
                                                        </div>

                                                    </>) : (<>
                                                        <p>NOT SCHEDULE YET</p>
                                                    </>)}
                                                </div>

                                                <div className='timepickerr'>
                                                    <div className='timepickerr2'>
                                                        <input checked={Tuesday} onChange={(e) => { setTuesday(e.target.checked) }} style={{ width: '20px', height: "20px" }} type='checkbox' />
                                                        <label>Tuesday</label>
                                                    </div>
                                                    {Tuesday ? (<>

                                                        <div style={{ display: "flex", flexDirection: 'column' }}>

                                                            <div style={{ display: 'flex', alignItems: "center" }}>
                                                                <TimePicker.RangePicker
                                                                    value={(Tuesdaytime[0] !== "" && Tuesdaytime.length > 0 && Tuesdaytime[0] !== undefined) ? [dayjs(Tuesdaytime[0], "h:mm a"), dayjs(Tuesdaytime[1], "h:mm a")] : []}
                                                                    format="h:mm a" use12Hours required
                                                                    onChange={(e, value) => {

                                                                        const addfeild = value; // Values to be added

                                                                        setTuesdaytime(prevState => {
                                                                            const newState = [...prevState]; // Clone the previous state
                                                                            newState[0] = addfeild[0]; // Add to index 2
                                                                            newState[1] = addfeild[1]; // Add to index 3
                                                                            return newState; // Return the updated state
                                                                        });

                                                                    }}

                                                                />

                                                                {Tuesdaytime.length < 3 && <>
                                                                    <FaPlus
                                                                        onClick={() => {
                                                                            const addfeild = ["", ""]; // Values to be added

                                                                            setTuesdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[2] = addfeild[0]; // Add to index 2
                                                                                newState[3] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                    />
                                                                </>}


                                                            </div>


                                                            {Tuesdaytime.length > 3 && <>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={Tuesdaytime[2] !== "" ? [dayjs(Tuesdaytime[2], "h:mm a"), dayjs(Tuesdaytime[3], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value) => {

                                                                            const addfeild = value; // Values to be added

                                                                            setTuesdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[2] = addfeild[0]; // Add to index 2
                                                                                newState[3] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });

                                                                        }}

                                                                    />


                                                                    <ImCross
                                                                        onClick={() => {
                                                                            setTuesdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState.splice(2, 2); // Remove 2 elements starting from index 2
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                    />

                                                                    {(Tuesdaytime.length > 3 && Tuesdaytime.length < 5) && <>
                                                                        <FaPlus
                                                                            onClick={() => {
                                                                                const addfeild = ["", ""]; // Values to be added

                                                                                setTuesdaytime(prevState => {
                                                                                    const newState = [...prevState]; // Clone the previous state
                                                                                    newState[4] = addfeild[0]; // Add to index 2
                                                                                    newState[5] = addfeild[1]; // Add to index 3
                                                                                    return newState; // Return the updated state
                                                                                });
                                                                            }}
                                                                            style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                        />
                                                                    </>}
                                                                </div>
                                                            </>}

                                                            {Tuesdaytime.length > 5 && <>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={Tuesdaytime[4] !== "" ? [dayjs(Tuesdaytime[4], "h:mm a"), dayjs(Tuesdaytime[5], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value) => {

                                                                            const addfeild = value; // Values to be added

                                                                            setTuesdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[4] = addfeild[0]; // Add to index 2
                                                                                newState[5] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });

                                                                        }}

                                                                    />

                                                                    <ImCross
                                                                        onClick={() => {
                                                                            setTuesdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState.splice(2, 2); // Remove 2 elements starting from index 2
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                    />
                                                                </div>
                                                            </>}
                                                        </div>


                                                    </>) : (<>
                                                        <p>NOT SCHEDULE YET</p>
                                                    </>)}
                                                </div>

                                                <div className='timepickerr'>
                                                    <div className='timepickerr2'>
                                                        <input checked={Wednesday} onChange={(e) => { setWednesday(e.target.checked) }} style={{ width: '20px', height: "20px" }} type='checkbox' />
                                                        <label>Wednesday</label>
                                                    </div>
                                                    {Wednesday ? (<>

                                                        <div style={{ display: "flex", flexDirection: 'column' }}>

                                                            <div style={{ display: 'flex', alignItems: "center" }}>
                                                                <TimePicker.RangePicker
                                                                    value={(Wednesdaytime[0] !== "" && Wednesdaytime.length > 0 && Wednesdaytime[0] !== undefined) ? [dayjs(Wednesdaytime[0], "h:mm a"), dayjs(Wednesdaytime[1], "h:mm a")] : []}
                                                                    format="h:mm a" use12Hours required
                                                                    onChange={(e, value) => {

                                                                        const addfeild = value; // Values to be added

                                                                        setWednesdaytime(prevState => {
                                                                            const newState = [...prevState]; // Clone the previous state
                                                                            newState[0] = addfeild[0]; // Add to index 2
                                                                            newState[1] = addfeild[1]; // Add to index 3
                                                                            return newState; // Return the updated state
                                                                        });

                                                                    }}

                                                                />

                                                                {Wednesdaytime.length < 3 && <>
                                                                    <FaPlus
                                                                        onClick={() => {
                                                                            const addfeild = ["", ""]; // Values to be added

                                                                            setWednesdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[2] = addfeild[0]; // Add to index 2
                                                                                newState[3] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                    />
                                                                </>}


                                                            </div>


                                                            {Wednesdaytime.length > 3 && <>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={Wednesdaytime[2] !== "" ? [dayjs(Wednesdaytime[2], "h:mm a"), dayjs(Wednesdaytime[3], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value) => {

                                                                            const addfeild = value; // Values to be added

                                                                            setWednesdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[2] = addfeild[0]; // Add to index 2
                                                                                newState[3] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });

                                                                        }}

                                                                    />


                                                                    <ImCross
                                                                        onClick={() => {
                                                                            setWednesdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState.splice(2, 2); // Remove 2 elements starting from index 2
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                    />

                                                                    {(Wednesdaytime.length > 3 && Wednesdaytime.length < 5) && <>
                                                                        <FaPlus
                                                                            onClick={() => {
                                                                                const addfeild = ["", ""]; // Values to be added

                                                                                setWednesdaytime(prevState => {
                                                                                    const newState = [...prevState]; // Clone the previous state
                                                                                    newState[4] = addfeild[0]; // Add to index 2
                                                                                    newState[5] = addfeild[1]; // Add to index 3
                                                                                    return newState; // Return the updated state
                                                                                });
                                                                            }}
                                                                            style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                        />
                                                                    </>}
                                                                </div>
                                                            </>}

                                                            {Wednesdaytime.length > 5 && <>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={Wednesdaytime[4] !== "" ? [dayjs(Wednesdaytime[4], "h:mm a"), dayjs(Wednesdaytime[5], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value) => {

                                                                            const addfeild = value; // Values to be added

                                                                            setWednesdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[4] = addfeild[0]; // Add to index 2
                                                                                newState[5] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });

                                                                        }}

                                                                    />

                                                                    <ImCross
                                                                        onClick={() => {
                                                                            setWednesdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState.splice(2, 2); // Remove 2 elements starting from index 2
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                    />
                                                                </div>
                                                            </>}
                                                        </div>
                                                    </>) : (<>
                                                        <p>NOT SCHEDULE YET</p>
                                                    </>)}
                                                </div>

                                                <div className='timepickerr'>
                                                    <div className='timepickerr2'>
                                                        <input checked={Thursday} onChange={(e) => { setThursday(e.target.checked) }} style={{ width: '20px', height: "20px" }} type='checkbox' />
                                                        <label>Thursday</label>
                                                    </div>
                                                    {Thursday ? (<>

                                                        <div style={{ display: "flex", flexDirection: 'column' }}>

                                                            <div style={{ display: 'flex', alignItems: "center" }}>
                                                                <TimePicker.RangePicker
                                                                    value={(Thursdaytime[0] !== "" && Thursdaytime.length > 0 && Thursdaytime[0] !== undefined) ? [dayjs(Thursdaytime[0], "h:mm a"), dayjs(Thursdaytime[1], "h:mm a")] : []}
                                                                    format="h:mm a" use12Hours required
                                                                    onChange={(e, value) => {

                                                                        const addfeild = value; // Values to be added

                                                                        setThursdaytime(prevState => {
                                                                            const newState = [...prevState]; // Clone the previous state
                                                                            newState[0] = addfeild[0]; // Add to index 2
                                                                            newState[1] = addfeild[1]; // Add to index 3
                                                                            return newState; // Return the updated state
                                                                        });

                                                                    }}

                                                                />

                                                                {Thursdaytime.length < 3 && <>
                                                                    <FaPlus
                                                                        onClick={() => {
                                                                            const addfeild = ["", ""]; // Values to be added

                                                                            setThursdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[2] = addfeild[0]; // Add to index 2
                                                                                newState[3] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                    />
                                                                </>}


                                                            </div>


                                                            {Thursdaytime.length > 3 && <>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={Thursdaytime[2] !== "" ? [dayjs(Thursdaytime[2], "h:mm a"), dayjs(Thursdaytime[3], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value) => {

                                                                            const addfeild = value; // Values to be added

                                                                            setThursdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[2] = addfeild[0]; // Add to index 2
                                                                                newState[3] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });

                                                                        }}

                                                                    />


                                                                    <ImCross
                                                                        onClick={() => {
                                                                            setThursdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState.splice(2, 2); // Remove 2 elements starting from index 2
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                    />

                                                                    {(Thursdaytime.length > 3 && Thursdaytime.length < 5) && <>
                                                                        <FaPlus
                                                                            onClick={() => {
                                                                                const addfeild = ["", ""]; // Values to be added

                                                                                setThursdaytime(prevState => {
                                                                                    const newState = [...prevState]; // Clone the previous state
                                                                                    newState[4] = addfeild[0]; // Add to index 2
                                                                                    newState[5] = addfeild[1]; // Add to index 3
                                                                                    return newState; // Return the updated state
                                                                                });
                                                                            }}
                                                                            style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                        />
                                                                    </>}
                                                                </div>
                                                            </>}

                                                            {Thursdaytime.length > 5 && <>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={Thursdaytime[4] !== "" ? [dayjs(Thursdaytime[4], "h:mm a"), dayjs(Thursdaytime[5], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value) => {

                                                                            const addfeild = value; // Values to be added

                                                                            setThursdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[4] = addfeild[0]; // Add to index 2
                                                                                newState[5] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });

                                                                        }}

                                                                    />

                                                                    <ImCross
                                                                        onClick={() => {
                                                                            setThursdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState.splice(2, 2); // Remove 2 elements starting from index 2
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                    />
                                                                </div>
                                                            </>}
                                                        </div>

                                                    </>) : (<>
                                                        <p>NOT SCHEDULE YET</p>
                                                    </>)}
                                                </div>

                                                <div className='timepickerr'>
                                                    <div className='timepickerr2'>
                                                        <input checked={Friday} onChange={(e) => { setFriday(e.target.checked) }} style={{ width: '20px', height: "20px" }} type='checkbox' />
                                                        <label>Friday</label>
                                                    </div>
                                                    {Friday ? (<>
                                                        <div style={{ display: "flex", flexDirection: 'column' }}>

                                                            <div style={{ display: 'flex', alignItems: "center" }}>
                                                                <TimePicker.RangePicker
                                                                    value={(Fridaytime[0] !== "" && Fridaytime.length > 0 && Fridaytime[0] !== undefined) ? [dayjs(Fridaytime[0], "h:mm a"), dayjs(Fridaytime[1], "h:mm a")] : []}
                                                                    format="h:mm a" use12Hours required
                                                                    onChange={(e, value) => {

                                                                        const addfeild = value; // Values to be added

                                                                        setFridaytime(prevState => {
                                                                            const newState = [...prevState]; // Clone the previous state
                                                                            newState[0] = addfeild[0]; // Add to index 2
                                                                            newState[1] = addfeild[1]; // Add to index 3
                                                                            return newState; // Return the updated state
                                                                        });

                                                                    }}

                                                                />

                                                                {Fridaytime.length < 3 && <>
                                                                    <FaPlus
                                                                        onClick={() => {
                                                                            const addfeild = ["", ""]; // Values to be added

                                                                            setFridaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[2] = addfeild[0]; // Add to index 2
                                                                                newState[3] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                    />
                                                                </>}


                                                            </div>


                                                            {Fridaytime.length > 3 && <>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={Fridaytime[2] !== "" ? [dayjs(Fridaytime[2], "h:mm a"), dayjs(Fridaytime[3], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value) => {

                                                                            const addfeild = value; // Values to be added

                                                                            setFridaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[2] = addfeild[0]; // Add to index 2
                                                                                newState[3] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });

                                                                        }}

                                                                    />


                                                                    <ImCross
                                                                        onClick={() => {
                                                                            setFridaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState.splice(2, 2); // Remove 2 elements starting from index 2
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                    />

                                                                    {(Fridaytime.length > 3 && Fridaytime.length < 5) && <>
                                                                        <FaPlus
                                                                            onClick={() => {
                                                                                const addfeild = ["", ""]; // Values to be added

                                                                                setFridaytime(prevState => {
                                                                                    const newState = [...prevState]; // Clone the previous state
                                                                                    newState[4] = addfeild[0]; // Add to index 2
                                                                                    newState[5] = addfeild[1]; // Add to index 3
                                                                                    return newState; // Return the updated state
                                                                                });
                                                                            }}
                                                                            style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                        />
                                                                    </>}
                                                                </div>
                                                            </>}

                                                            {Fridaytime.length > 5 && <>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={Fridaytime[4] !== "" ? [dayjs(Fridaytime[4], "h:mm a"), dayjs(Fridaytime[5], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value) => {

                                                                            const addfeild = value; // Values to be added

                                                                            setFridaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[4] = addfeild[0]; // Add to index 2
                                                                                newState[5] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });

                                                                        }}

                                                                    />

                                                                    <ImCross
                                                                        onClick={() => {
                                                                            setFridaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState.splice(2, 2); // Remove 2 elements starting from index 2
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                    />
                                                                </div>
                                                            </>}
                                                        </div>
                                                    </>) : (<>
                                                        <p>NOT SCHEDULE YET</p>
                                                    </>)}
                                                </div>

                                                <div className='timepickerr'>
                                                    <div className='timepickerr2'>
                                                        <input checked={Saturday} onChange={(e) => { setSaturday(e.target.checked) }} style={{ width: '20px', height: "20px" }} type='checkbox' />
                                                        <label>Saturday</label>
                                                    </div>
                                                    {Saturday ? (<>

                                                        <div style={{ display: "flex", flexDirection: 'column' }}>

                                                            <div style={{ display: 'flex', alignItems: "center" }}>
                                                                <TimePicker.RangePicker
                                                                    value={(Saturdaytime[0] !== "" && Saturdaytime.length > 0 && Saturdaytime[0] !== undefined) ? [dayjs(Saturdaytime[0], "h:mm a"), dayjs(Saturdaytime[1], "h:mm a")] : []}
                                                                    format="h:mm a" use12Hours required
                                                                    onChange={(e, value) => {

                                                                        const addfeild = value; // Values to be added

                                                                        setSaturdaytime(prevState => {
                                                                            const newState = [...prevState]; // Clone the previous state
                                                                            newState[0] = addfeild[0]; // Add to index 2
                                                                            newState[1] = addfeild[1]; // Add to index 3
                                                                            return newState; // Return the updated state
                                                                        });

                                                                    }}

                                                                />

                                                                {Saturdaytime.length < 3 && <>
                                                                    <FaPlus
                                                                        onClick={() => {
                                                                            const addfeild = ["", ""]; // Values to be added

                                                                            setSaturdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[2] = addfeild[0]; // Add to index 2
                                                                                newState[3] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                    />
                                                                </>}


                                                            </div>


                                                            {Saturdaytime.length > 3 && <>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={Saturdaytime[2] !== "" ? [dayjs(Saturdaytime[2], "h:mm a"), dayjs(Saturdaytime[3], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value) => {

                                                                            const addfeild = value; // Values to be added

                                                                            setSaturdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[2] = addfeild[0]; // Add to index 2
                                                                                newState[3] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });

                                                                        }}

                                                                    />


                                                                    <ImCross
                                                                        onClick={() => {
                                                                            setSaturdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState.splice(2, 2); // Remove 2 elements starting from index 2
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                    />

                                                                    {(Saturdaytime.length > 3 && Saturdaytime.length < 5) && <>
                                                                        <FaPlus
                                                                            onClick={() => {
                                                                                const addfeild = ["", ""]; // Values to be added

                                                                                setSaturdaytime(prevState => {
                                                                                    const newState = [...prevState]; // Clone the previous state
                                                                                    newState[4] = addfeild[0]; // Add to index 2
                                                                                    newState[5] = addfeild[1]; // Add to index 3
                                                                                    return newState; // Return the updated state
                                                                                });
                                                                            }}
                                                                            style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                        />
                                                                    </>}
                                                                </div>
                                                            </>}

                                                            {Saturdaytime.length > 5 && <>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={Saturdaytime[4] !== "" ? [dayjs(Saturdaytime[4], "h:mm a"), dayjs(Saturdaytime[5], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value) => {

                                                                            const addfeild = value; // Values to be added

                                                                            setSaturdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[4] = addfeild[0]; // Add to index 2
                                                                                newState[5] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });

                                                                        }}

                                                                    />

                                                                    <ImCross
                                                                        onClick={() => {
                                                                            setSaturdaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState.splice(2, 2); // Remove 2 elements starting from index 2
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                    />
                                                                </div>
                                                            </>}
                                                        </div>
                                                    </>) : (<>
                                                        <p>NOT SCHEDULE YET</p>
                                                    </>)}
                                                </div>

                                                <div className='timepickerr'>
                                                    <div className='timepickerr2'>
                                                        <input checked={Sunday} onChange={(e) => { setSunday(e.target.checked) }} style={{ width: '20px', height: "20px" }} type='checkbox' />
                                                        <label>Sunday</label>
                                                    </div>
                                                    {Sunday ? (<>

                                                        <div style={{ display: "flex", flexDirection: 'column' }}>

                                                            <div style={{ display: 'flex', alignItems: "center" }}>
                                                                <TimePicker.RangePicker
                                                                    value={(Sundaytime[0] !== "" && Sundaytime.length > 0 && Sundaytime[0] !== undefined) ? [dayjs(Sundaytime[0], "h:mm a"), dayjs(Sundaytime[1], "h:mm a")] : []}
                                                                    format="h:mm a" use12Hours required
                                                                    onChange={(e, value) => {

                                                                        const addfeild = value; // Values to be added

                                                                        setSundaytime(prevState => {
                                                                            const newState = [...prevState]; // Clone the previous state
                                                                            newState[0] = addfeild[0]; // Add to index 2
                                                                            newState[1] = addfeild[1]; // Add to index 3
                                                                            return newState; // Return the updated state
                                                                        });

                                                                    }}

                                                                />

                                                                {Sundaytime.length < 3 && <>
                                                                    <FaPlus
                                                                        onClick={() => {
                                                                            const addfeild = ["", ""]; // Values to be added

                                                                            setSundaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[2] = addfeild[0]; // Add to index 2
                                                                                newState[3] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                    />
                                                                </>}


                                                            </div>


                                                            {Sundaytime.length > 3 && <>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={Sundaytime[2] !== "" ? [dayjs(Sundaytime[2], "h:mm a"), dayjs(Sundaytime[3], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value) => {

                                                                            const addfeild = value; // Values to be added

                                                                            setSundaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[2] = addfeild[0]; // Add to index 2
                                                                                newState[3] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });

                                                                        }}

                                                                    />


                                                                    <ImCross
                                                                        onClick={() => {
                                                                            setSundaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState.splice(2, 2); // Remove 2 elements starting from index 2
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                    />

                                                                    {(Sundaytime.length > 3 && Sundaytime.length < 5) && <>
                                                                        <FaPlus
                                                                            onClick={() => {
                                                                                const addfeild = ["", ""]; // Values to be added

                                                                                setSundaytime(prevState => {
                                                                                    const newState = [...prevState]; // Clone the previous state
                                                                                    newState[4] = addfeild[0]; // Add to index 2
                                                                                    newState[5] = addfeild[1]; // Add to index 3
                                                                                    return newState; // Return the updated state
                                                                                });
                                                                            }}
                                                                            style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                        />
                                                                    </>}
                                                                </div>
                                                            </>}

                                                            {Sundaytime.length > 5 && <>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={Sundaytime[4] !== "" ? [dayjs(Sundaytime[4], "h:mm a"), dayjs(Sundaytime[5], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value) => {

                                                                            const addfeild = value; // Values to be added

                                                                            setSundaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState[4] = addfeild[0]; // Add to index 2
                                                                                newState[5] = addfeild[1]; // Add to index 3
                                                                                return newState; // Return the updated state
                                                                            });

                                                                        }}

                                                                    />

                                                                    <ImCross
                                                                        onClick={() => {
                                                                            setSundaytime(prevState => {
                                                                                const newState = [...prevState]; // Clone the previous state
                                                                                newState.splice(2, 2); // Remove 2 elements starting from index 2
                                                                                return newState; // Return the updated state
                                                                            });
                                                                        }}
                                                                        style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                    />
                                                                </div>
                                                            </>}
                                                        </div>

                                                    </>) : (<>
                                                        <p>NOT SCHEDULE YET</p>
                                                    </>)}
                                                </div>
                                            </div>

                                            <div className='settingschuleitem'>  <label>Modify your availability for a specific day. Mark which hours you ARE available </label>



                                                {Spacificday.map((value, index) => {
                                                    return (
                                                        <div className='timepickerr' key={index}>



                                                            <div className='timepickerr2'>


                                                                <DatePicker
                                                                    required
                                                                    value={value.date.length > 0 ? [dayjs(value.date, "YYYY-MM-DD")] : []}
                                                                    onChange={(date, dateString) => {
                                                                        setSpacificday(prevState => {
                                                                            const updatedArray = [...prevState];
                                                                            updatedArray[index] = { ...updatedArray[index], date: dateString }; // Store the date object itself
                                                                            return updatedArray;
                                                                        });
                                                                    }}
                                                                />

                                                                <ImCross
                                                                    style={{ color: "red", marginLeft: '10px', cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSpacificday(prevState => prevState.filter((_, i) => i !== index));

                                                                    }}
                                                                />
                                                            </div>














                                                            <div style={{ display: "flex", flexDirection: 'column' }}>

                                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                                    <TimePicker.RangePicker
                                                                        value={(value.time[0] !== "" && value.time.length > 0 && value.time[0] !== undefined) ? [dayjs(value.time[0], "h:mm a"), dayjs(value.time[1], "h:mm a")] : []}
                                                                        format="h:mm a" use12Hours required
                                                                        onChange={(e, value2) => {

                                                                            const addfeild = value2; // Values to be added




                                                                            const updatedTime = [...value.time]; // Clone the existing time array


                                                                            updatedTime[0] = addfeild[0]; // Add to index 2
                                                                            updatedTime[1] = addfeild[1];




                                                                            setSpacificday(prevState => {
                                                                                const updatedArray = [...prevState];


                                                                                updatedArray[index] = { ...updatedArray[index], time: updatedTime }; // Store time strings
                                                                                return updatedArray;


                                                                            });


                                                                        }}

                                                                    />

                                                                    {value.time.length < 3 && <>
                                                                        <FaPlus
                                                                            onClick={() => {
                                                                                const addfeild = ["", ""]; // Values to be added



                                                                                const updatedTime = [...value.time]; // Clone the existing time array
                                                                                updatedTime[2] = addfeild[0]; // Add to index 2
                                                                                updatedTime[3] = addfeild[1];


                                                                                console.log(updatedTime)

                                                                                setSpacificday(prevState => {
                                                                                    const updatedArray = [...prevState];


                                                                                    updatedArray[index] = { ...updatedArray[index], time: updatedTime }; // Store time strings
                                                                                    return updatedArray;


                                                                                });

                                                                            }}
                                                                            style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                        />
                                                                    </>}


                                                                </div>


                                                                {value.time.length > 3 && <>

                                                                    <div style={{ display: 'flex', alignItems: "center" }}>
                                                                        <TimePicker.RangePicker
                                                                            value={value.time[2] !== "" ? [dayjs(value.time[2], "h:mm a"), dayjs(value.time[3], "h:mm a")] : []}
                                                                            format="h:mm a" use12Hours required
                                                                            onChange={(e, value2) => {

                                                                                const addfeild = value2; // Values to be added




                                                                                const updatedTime = [...value.time]; // Clone the existing time array


                                                                                updatedTime[2] = addfeild[0]; // Add to index 2
                                                                                updatedTime[3] = addfeild[1];




                                                                                setSpacificday(prevState => {
                                                                                    const updatedArray = [...prevState];


                                                                                    updatedArray[index] = { ...updatedArray[index], time: updatedTime }; // Store time strings
                                                                                    return updatedArray;


                                                                                });

                                                                            }}

                                                                        />


                                                                        <ImCross
                                                                            onClick={() => {
                                                                                const updatedTime = [...value.time]; // Clone the existing time array
                                                                                updatedTime.splice(2, 2);




                                                                                setSpacificday(prevState => {
                                                                                    const updatedArray = [...prevState];


                                                                                    updatedArray[index] = { ...updatedArray[index], time: updatedTime }; // Store time strings
                                                                                    return updatedArray;


                                                                                });
                                                                            }}
                                                                            style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                        />

                                                                        {(value.time.length > 3 && value.time.length < 5) && <>
                                                                            <FaPlus
                                                                                onClick={() => {
                                                                                    const addfeild = ["", ""]; // Values to be added

                                                                                    const time = value.time

                                                                                    const updatedTime = [...value.time]; // Clone the existing time array
                                                                                    updatedTime[4] = addfeild[0]; // Add to index 2
                                                                                    updatedTime[5] = addfeild[1];


                                                                                    console.log(updatedTime)

                                                                                    setSpacificday(prevState => {
                                                                                        const updatedArray = [...prevState];


                                                                                        updatedArray[index] = { ...updatedArray[index], time: updatedTime }; // Store time strings
                                                                                        return updatedArray;


                                                                                    });
                                                                                }}
                                                                                style={{ width: '20px', height: "20px", marginLeft: "10px", color: '#6E4E9F', cursor: 'pointer' }}
                                                                            />
                                                                        </>}
                                                                    </div>
                                                                </>}

                                                                {value.time.length > 5 && <>

                                                                    <div style={{ display: 'flex', alignItems: "center" }}>
                                                                        <TimePicker.RangePicker
                                                                            // value={value.time[4] !== "" ? [dayjs(value.time[4], "h:mm a"), dayjs(value.time[5], "h:mm a")] : []}
                                                                            format="h:mm a" use12Hours required
                                                                            onChange={(e, value2) => {

                                                                                const addfeild = value2; // Values to be added




                                                                                const updatedTime = [...value.time]; // Clone the existing time array


                                                                                updatedTime[4] = addfeild[0]; // Add to index 2
                                                                                updatedTime[5] = addfeild[1];




                                                                                setSpacificday(prevState => {
                                                                                    const updatedArray = [...prevState];


                                                                                    updatedArray[index] = { ...updatedArray[index], time: updatedTime }; // Store time strings
                                                                                    return updatedArray;


                                                                                });

                                                                            }}

                                                                        />

                                                                        <ImCross
                                                                            onClick={() => {
                                                                                const updatedTime = [...value.time]; // Clone the existing time array
                                                                                updatedTime.splice(4, 4);



                                                                                setSpacificday(prevState => {
                                                                                    const updatedArray = [...prevState];


                                                                                    updatedArray[index] = { ...updatedArray[index], time: updatedTime }; // Store time strings
                                                                                    return updatedArray;


                                                                                });
                                                                            }}
                                                                            style={{ width: '20px', height: "20px", marginLeft: "10px", color: 'red', cursor: 'pointer' }}
                                                                        />
                                                                    </div>
                                                                </>}
                                                            </div>

                                                        </div>
                                                    );
                                                })}






                                                <button className='button' style={{ width: "fit-content" }} type='button'

                                                    onClick={() => {
                                                        const fielddd = { date: "", time: ["", ""] };
                                                        setSpacificday(prevState => [...prevState, fielddd]); // Add the new object to the existing array

                                                    }}

                                                >Add Date and Time</button>
                                            </div>





                                            <div className='settingschuleitem'>  <label>Offline Days </label>



                                                {Dateexception.map((value, index) => {
                                                    return (
                                                        <div className='timepickerr' key={index}>
                                                            <div className='timepickerr2'>
                                                                <DatePicker
                                                                    required
                                                                    style={{ width: "100%" }}
                                                                    value={value.length > 0 ? [dayjs(value, "YYYY-MM-DD")] : []}
                                                                    onChange={(date, dateString) => {
                                                                        setDateexception(prevState => {
                                                                            const updatedArray = [...prevState];
                                                                            updatedArray[index] = dateString; // Store the date object itself
                                                                            return updatedArray;
                                                                        });
                                                                    }}
                                                                />
                                                            </div>


                                                            <ImCross
                                                                style={{ color: "red", marginLeft: '10px', cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setDateexception(prevState => prevState.filter((_, i) => i !== index));

                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                })}






                                                <button className='button' style={{ width: "fit-content" }} type='button'

                                                    onClick={() => {
                                                        const fielddd = "";
                                                        setDateexception(prevState => [...prevState, fielddd]); // Add the new object to the existing array
                                                        console.log(Dateexception)
                                                    }}

                                                >Add Date</button>
                                            </div>


                                            <div className='settingschuleitem'>  <label>Description</label>
                                                <input required value={discribtion} onChange={(e) => setdiscribtion(e.target.value)} placeholder='' style={{ width: '96.5%' }} type='text' />
                                            </div>

                                            <div className='settingschuleitem'>  <label>Team Category</label>
                                                <select value={Category} style={{ width: '100%' }} required onChange={(e) => setCategory(e.target.value)}>
                                                    <option selected value={""} disabled>Select Team Category</option>
                                                    <option value={"nutrition"}>Nutrition Team</option>
                                                    <option value={"sales"}>Sales Team</option>
                                                    <option value={"it"}>IT Team</option>
                                                </select>
                                            </div>

                                            {Category === "nutrition" && <>
                                                <div className='settingschuleitem'>  <label>Test to Interp.</label>
                                                    {/* <input required value={rebin} onChange={(e) => setrebin(e.target.value)} placeholder='' style={{ width: '96.5%' }} type='text' /> */}

                                                    <Select
                                                        mode="multiple"
                                                        defaultValue={rebin}
                                                        placeholder="Please Select"
                                                        style={{
                                                            flex: 1,
                                                            backgroundColor: "#fff"
                                                        }}
                                                        onChange={(value) => setrebin(value)}
                                                        options={[
                                                            {
                                                                value: 'MicrobiomeMap',
                                                                label: 'MicrobiomeMap',
                                                            },
                                                            {
                                                                value: 'MicrobiomeAdvanced',
                                                                label: 'MicrobiomeAdvanced',
                                                            },
                                                            {
                                                                value: 'MicrobiomePlus',
                                                                label: 'MicrobiomePlus',
                                                            },
                                                            {
                                                                value: 'FoodSensitivityMap',
                                                                label: 'FoodSensitivityMap',
                                                            },
                                                            {
                                                                value: 'CandidaProfile',
                                                                label: 'CandidaProfile',
                                                            },
                                                            {
                                                                value: 'DNAMap',
                                                                label: 'DNAMap',
                                                            },
                                                            {
                                                                value: 'ParasitologyTest',
                                                                label: 'ParasitologyTest',
                                                            },
                                                        ]}
                                                    />

                                                </div>
                                            </>}


                                        </div>

                                       


                                        <div className='bownpopupbutton'>
                                            <button onClick={() => {
                                                setpopup(false)

                                            }
                                            } style={{ border: '1px solid red', color: 'black' }} >cancel</button>


                                            <button className='button' >NEXT</button>

                                        </div>
                                    </form>



                                </div>
                            </>}


                            {popup2 === true && <>
                                <div onClick={() => {
                                    setpopup(false)
                                    setpopup2(false)

                                }
                                } className='popupbg'></div>
                                <div className='popup'>


                                    <h2 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}>Custom Fields</h2>



                                    <form className='eventform' onSubmit={submitsecontform}>
                                        <div>
                                            <p>
                                                Tailor the data collected during registration to your needs. Seamlessly integrate attendee information with your CRM or Email Marketing platform.

                                            </p>

                                            {addfield === true ? (<>



                                                {addfield2 === false ? (<>
                                                    <div className='fleidbox'>
                                                        <h3>What kind of field would you like to add?</h3>
                                                        <div >
                                                            <div onClick={() => {
                                                                setaddfield2(!addfield2)
                                                                settype("text")
                                                            }} className='hovarrrr'>Text</div>
                                                            <div onClick={() => {
                                                                setaddfield2(!addfield2)
                                                                settype("phone")
                                                            }} className='hovarrrr'>Phone</div>
                                                            <div onClick={() => {
                                                                setaddfield2(!addfield2)
                                                                settype("url")
                                                            }} className='hovarrrr'>URL</div>

                                                        </div>
                                                        <div >
                                                            <div onClick={() => {
                                                                setaddfield2(!addfield2)
                                                                settype("date")
                                                            }} className='hovarrrr'>Date</div>
                                                            <div onClick={() => {
                                                                setaddfield2(!addfield2)
                                                                settype("number")
                                                            }} className='hovarrrr'>number</div>
                                                            <div onClick={() => {
                                                                setaddfield2(!addfield2)
                                                                settype("checkbox")
                                                            }} className='hovarrrr'> Checkbox</div>

                                                        </div>
                                                        <div >
                                                            <div onClick={() => {
                                                                setaddfield2(!addfield2)
                                                                settype("select")
                                                            }} className='hovarrrr'>Multiple choice</div>
                                                            <div onClick={() => {
                                                                setaddfield2(!addfield2)
                                                                settype("textarea")
                                                            }} className='hovarrrr'>Message</div>


                                                        </div>


                                                    </div>

                                                </>) : (<>



                                                    <div className='fleidbox'>
                                                        <h3>{type}</h3>
                                                        <div>

                                                            <input required placeholder='What Should be the Title' onChange={(e) => setFieldstitle(e.target.value)} />
                                                        </div>


                                                        {type === "select" && <>


                                                            <h5>Options</h5>

                                                            {option.map((option, i) => (
                                                                <div key={i}>
                                                                    <input required placeholder={`Option ${i + 1}`} value={option.value}
                                                                        onChange={(e) => editField(i, e.target.value)} />
                                                                </div>
                                                            ))}


                                                            <div>


                                                                <button onClick={() => {
                                                                    setoption(prevFields => [
                                                                        ...prevFields,
                                                                        { value: "", placeholder: `Option ${option.length + 1}` }
                                                                    ]);
                                                                }} type='button'>Add Option</button>
                                                            </div>

                                                        </>}


                                                        <div style={{ height: '40px', alignItems: 'center' }}>

                                                            <input onChange={(e) => setFieldreq(e.target.checked)} type='checkbox' style={{ width: '30px', height: '40px' }} />
                                                            <label>Required Field</label>
                                                        </div>

                                                        <button className='button2' style={{ borderRadius: '5px', width: '100%' }}>Add Custom Fields</button>


                                                        <button onClick={() => {
                                                            setaddfield(false)
                                                            setaddfield2(false)
                                                        }} className='dltbtn' style={{ backgroundColor: '#fff', border: "1px solid red", color: "black", borderRadius: '5px', width: '100%' }}>Cenel</button>

                                                    </div>

                                                </>)}



                                            </>) : (<>
                                                <div className='fleidbox'>
                                                    <h3>Included</h3>
                                                    <div style={{ display: "flex", flexWrap: 'wrap' }}>
                                                        {Fields.map((value, index) => {


                                                            return (<>

                                                                <Popconfirm
                                                                    title="Delete the Field"
                                                                    description="Are you sure to delete this Field"
                                                                    onConfirm={() => {
                                                                        setFields(prevFields => prevFields.filter((_, i) => i !== index));
                                                                    }}

                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <div style={{ minWidth: '100px', cursor: 'pointer', maxWidth: 'fit-content' }}>{value.title}</div>
                                                                </Popconfirm>
                                                            </>)
                                                        })}

                                                    </div>

                                                    <button
                                                        type="button"
                                                        onClick={() => setaddfield(true)}
                                                        className='button2'
                                                        style={{ borderRadius: '5px', width: '100%' }}
                                                    >
                                                        Add Custom Fields
                                                    </button>

                                                </div>


                                            </>)}






                                        </div>







                                    </form>





                                    <div className='bownpopupbutton'>
                                        <button onClick={() => {
                                            setpopup(false)
                                            setpopup2(false)

                                        }
                                        } style={{ border: '1px solid red', color: 'black' }} >Cancel</button>





                                        <button onClick={sentdatatobackend} style={{ backgroundColor: '#4180b7' }} > ADD </button>


                                    </div>

                                </div>
                            </>}







                            {seequetion ? (<>

                                <div className='questionbox'>
                                    {question.map((value, index) => {

                                        return (<>

                                            {value.status !== true && <>
                                                <div className='question'>

                                                    <h4 style={{ display: 'flex', margin: "0" }}><h4 style={{ width: '30px' }}>{sno1++} -</h4> {value.details[0].Name}</h4>
                                                    <h6 style={{ display: 'flex', margin: "0", color: 'gray' }}><h4 style={{ width: '30px' }}></h4> {value.details[0].Email}</h6>

                                                    <h5 style={{ display: 'flex', margin: "0" }}><h4 style={{ width: '30px' }}></h4> Date: {value.date}</h5>
                                                    <h5 style={{ display: 'flex', margin: "0" }}><h4 style={{ width: '30px' }}></h4> Time: {value.time}</h5>






                                                    {value.details.map((detail, index) => (
                                                        <>
                                                            {Object.entries(detail)
                                                                .filter(([key]) => key !== "Name" && key !== "Email") // Exclude Name and Email
                                                                .map(([key, value], subIndex) => (<>
                                                                    <h5 style={{ display: 'flex', margin: "0", marginTop: "10px" }}><h4 style={{ width: '30px' }}></h4>{key}:</h5>

                                                                    <p style={{ display: 'flex', textAlign: 'left', padding: "0", margin: "0" }}>
                                                                        <h4 style={{ width: '30px' }}></h4>
                                                                        <span dangerouslySetInnerHTML={{ __html: value }} />
                                                                    </p>
                                                                </>))}
                                                        </>
                                                    ))}




                                                    {value.Logs.length === 0? (<>No one join the meet </>):(<>

                                                    <h4 style={{marginTop:"50px"}}>Meeting Logs</h4>
                                                    <table  className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ width: '100%',marginBottom:'10px' }}>
                                                        <thead className='tablephead' >
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Action</th>
                                                                <th>Time</th>
                                                                <th>Date</th>




                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {value.Logs.map((value2, index) => {


                                                                return (<>
                                                                    <tr>
                                                                        <td>{value2.name}</td>
                                                                        <td>{value2.action}</td>
                                                                        <td>{value2.time}</td>
                                                                        <td>{value2.date}</td>

                                                                    </tr>
                                                                </>)
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    </>)}
                                                </div>
                                            </>}
                                        </>)
                                    })}
                                </div>

                            </>) : (<>

                                <div className='questionbox'>
                                    {question.map((value, index) => {

                                        return (<>

                                            {value.status === true && <>
                                                <div className='question'>

                                                    <h4 style={{ display: 'flex', margin: "0" }}><h4 style={{ width: '30px' }}>{sno1++} -</h4> {value.details[0].Name}</h4>
                                                    <h6 style={{ display: 'flex', margin: "0", color: 'gray' }}><h4 style={{ width: '30px' }}></h4> {value.details[0].Email}</h6>

                                                    <h5 style={{ display: 'flex', margin: "0" }}><h4 style={{ width: '30px' }}></h4> Date: {value.date}</h5>
                                                    <h5 style={{ display: 'flex', margin: "0" }}><h4 style={{ width: '30px' }}></h4> Time: {value.time}</h5>






                                                    {value.details.map((detail, index) => (
                                                        <>
                                                            {Object.entries(detail)
                                                                .filter(([key]) => key !== "Name" && key !== "Email") // Exclude Name and Email
                                                                .map(([key, value], subIndex) => (<>
                                                                    <h5 style={{ display: 'flex', margin: "0", marginTop: "10px" }}><h4 style={{ width: '30px' }}></h4>{key}:</h5>

                                                                    <p style={{ display: 'flex', textAlign: 'left', padding: "0", margin: "0" }}>
                                                                        <h4 style={{ width: '30px' }}></h4>
                                                                        <span dangerouslySetInnerHTML={{ __html: value }} />
                                                                    </p>
                                                                </>))}
                                                        </>
                                                    ))}




                                                    {value.Logs.length === 0? (<>No one join the meet </>):(<>

                                                    <h4 style={{marginTop:"50px"}}>Meeting Logs</h4>
                                                    <table  className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ width: '100%',marginBottom:'10px' }}>
                                                        <thead className='tablephead' >
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Action</th>
                                                                <th>Time</th>
                                                                <th>Date</th>




                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {value.Logs.map((value2, index) => {


                                                                return (<>
                                                                    <tr>
                                                                        <td>{value2.name}</td>
                                                                        <td>{value2.action}</td>
                                                                        <td>{value2.time}</td>
                                                                        <td>{value2.date}</td>

                                                                    </tr>
                                                                </>)
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    </>)}
                                                </div>
                                            </>}
                                        </>)
                                    })}
                                </div>
                              
                            </>)}

                        </>) : (<>Loading...</>)}


                    </div>
                </div>
            </>}
        </div>
    )
}

export default Meetingsdetails
