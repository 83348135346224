import React, { useState, useEffect, useRef } from 'react'
import Navbar from '../components/navbar';
import Sidemanu from '../components/sidemanu';
import Cookies from 'js-cookie';
import { CgReorder } from 'react-icons/cg';
import axios from 'axios';
import { AutoComplete, Checkbox, Dropdown, Image, message, Popconfirm } from 'antd';
import { AiFillDelete, AiOutlineClose } from 'react-icons/ai';
import "../css/orderlables.css"
import html2pdf from 'html2pdf.js';
import { FaBarcode, FaPlaceOfWorship, FaRegUser, FaShippingFast, FaWpforms } from 'react-icons/fa';
import { TbGitBranchDeleted } from 'react-icons/tb';
import { MdEmail, MdOutlineFormatListNumbered, MdOutlineNumbers, MdSpeakerNotes } from 'react-icons/md';
import { BsCalendar2EventFill, BsCalendarDate, BsFillCalendar2DateFill } from "react-icons/bs";
import { FaArrowRightToCity, FaEarthAfrica, FaFileArrowDown, FaUserGroup } from 'react-icons/fa6';
import { SiGoogleadsense } from 'react-icons/si';
import "../css/leads.css"
import { IoIosArrowDropdown } from 'react-icons/io';
import { IoCall } from 'react-icons/io5';
import { ImUpload2 } from 'react-icons/im';
import Papa from 'papaparse';

function Leadsstate() {

  var sno1 = 1
  var sno2 = 1
  var sno2a = 1
  var sno2b = 1
  var sno2c = 1

  const loginname = Cookies.get("Name")
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")


  const [popup, setpopup] = useState(false)

  const [practitioner, setpractitioner] = useState([])
  const [practitionerselected, setpractitionerselected] = useState([])

  const [leads, setleads] = useState([])
  const [leadsinfo, setleadsinfo] = useState([])

  const getleads = async () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions = {
      method: 'GET',
      headers: myHeaders,

      redirect: 'follow'
    };

    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getleads", requestOptions)
      .then(response => response.json())
      .then(result => setleads(result))


    fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/getallpractitioner", requestOptions)
      .then(response => response.json())
      .then(result => {











        setpractitioner(result)

      })

  };

  useEffect(() => {

    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") { getleads() }


  }, [])




  const [orders, setOrders] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false); // To show loading state


  const existsIn = (list, email) => list.some(item => item.email === email);



  const filter = () => {



    // setleads(leads.filter(lead => existsIn(practitioner, lead.email)))

    // Remove practitioners not in leads
    setpractitioner(practitioner.filter(prac => existsIn(leads, prac.email)));

    console.log("Filtered Leads:", leads.length);
    console.log("Filtered Practitioners:", practitioner.length);

  }


  useEffect(() => {
    filter()
  }, [orders])





  const [searchTerm, setSearchTerm] = useState('');
  var options = [];

  const dooption = () => {
    leads.map((value, index) => {

      // options = [
      //   { value: 'Burns Bay Road' },

      // ];
      // const newvalue = toString(value.kitid)
      options.push({ value: value.Name })
      options.push({ value: value.Email })
      options.push({ value: value.src })


    })


  }



  const handleInputChange = (e) => {
    e.preventDefault()






    const intttt = (searchTerm)

    // Filter the Kit array based on the user's input
    const filteredSuggestions = leads.filter((item) =>

      item.src.toLowerCase().includes(intttt.toLowerCase()) || item.Name.toLowerCase().includes(intttt.toLowerCase()) || item.Email.toLowerCase().includes(intttt.toLowerCase())



    );


    setleads(filteredSuggestions)
    setsearchdone(true)

  };




  const [searchdone, setsearchdone] = useState(false)

  const clearsearch = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const hide = message.loading("Action in progress", 0)

    getleads()

    setsearchdone(false)
    await setTimeout(() => {
      hide(); // Call hide to stop the loading message
      message.success("Action completed successfully");
    }, 2000);
  }



  const getOrders = async () => {
    // List of API URLs and their respective credentials
    const woocommerceSites = [
      {
        url: 'https://yourgutmap.co.uk/wp-json/wc/v3/',
        consumerKey: 'ck_3baf06e2c37cf5491ac07bc50bd423ac32d2e0f2',
        consumerSecret: 'cs_93c79b982026b949b8cb7c8a820ca3d4fcb460e8'
      },
      {
        url: 'https://ireland.yourgutmap.co.uk/wp-json/wc/v3/',
        consumerKey: 'ck_3baf06e2c37cf5491ac07bc50bd423ac32d2e0f2',
        consumerSecret: 'cs_93c79b982026b949b8cb7c8a820ca3d4fcb460e8'
      },
      {
        url: 'https://yourgutmap.com/wp-json/wc/v3/',
        consumerKey: 'ck_a67ddeb758c057f37432faf75948fc4c4e93f61a',
        consumerSecret: 'cs_dd2175ec24c3117de578bf1e042628368b9e21a4'
      },
      // {
      //     url: 'https://uk.yourgutmap.com/wp-json/wc/v3/',
      //     consumerKey: 'ck_a67ddeb758c057f37432faf75948fc4c4e93f61a',
      //     consumerSecret: 'cs_dd2175ec24c3117de578bf1e042628368b9e21a4'
      // }
    ];

    let allOrders = [];  // Store orders from all websites
    let totalPages = 2;  // Initial total pages (this will be updated dynamically)

    try {
      setLoading(true);

      // Loop through each site
      for (let site of woocommerceSites) {
        const { url, consumerKey, consumerSecret } = site;
        const encodedCredentials = btoa(`${consumerKey}:${consumerSecret}`);
        const headers = {
          'Authorization': `Basic ${encodedCredentials}`,
        };

        let oders = [];
        // Fetch orders with pagination for the current site
        for (let i = 1; i <= totalPages; i++) {
          const response = await axios.get(`${url}orders`, {
            headers,
            params: {
              per_page: 100, // Number of orders per page
              page: i,
            },
          });

          const fetchedOrders = response.data;
          oders.push(...fetchedOrders);
          totalPages = parseInt(response.headers['x-wp-totalpages']);  // Update totalPages based on the response
        }

        // Filter out orders where line_items[0]?.name is "Seminar Ticket"
        oders = oders.filter(order => order.line_items[0]?.name !== "Seminar Ticket");
        allOrders.push(...oders);  // Combine orders from all websites
      }

      // Set the combined and filtered orders
      await setOrders(allOrders);
      setLoading(false);

    } catch (error) {
      console.error('Error fetching orders:', error);
      setLoading(false);
    }
  };




  useEffect(() => {
    getOrders();
  }, []);


  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>




        <Navbar />

        <div className='deshboardmain'>
          <Sidemanu />

          <div className='mainbody'>



            <div className='header'>
              <h1 style={{ color: '#6E4E9F', display: 'flex', alignItems: 'center' }}><SiGoogleadsense style={{ width: '50px', height: '50px' }} />Converted Leads States </h1>


              {dooption()}

              {searchdone === false && <>
                <form onSubmit={handleInputChange}>
                  <AutoComplete

                    style={{ width: 200 }}
                    options={options}
                    placeholder="Search by Email/Name"
                    filterOption={(inputValue, options) =>
                      options.value.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      //  console.log(kitss) 
                    }
                    onChange={(inputValue) => setSearchTerm(inputValue)}
                  />
                  <button>Search</button>
                </form>

              </>}
              {searchdone === true && <>     <div className='clearsearch'  ><h3>search: {searchTerm}</h3> <button onClick={clearsearch}><AiOutlineClose /> Clear</button>  </div>
              </>}

            </div>
            <h1>Total Orders:   {loading ? (<>Loading...</>) : (<>{orders.length}</>)}</h1>

            {popup === true && <>
              <div onClick={() => {
                setpopup(false)


              }
              } className='popupbg'></div>
              <div className='popup'>


                <div className='header' >
                  <h2>Lead Info</h2>
                </div>

                <div className='kitdetailsmain' >

                  {/* <div className='kitdetails'>
                    <div className='leadinforhead'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Name  :  </h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>   {leadsinfo.Name} </h3>
                    </div>
                    <div className='leadinforhead'>
                      <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Email : </h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>   {leadsinfo.Email} </h3>
                    </div>

                  </div>
                  {(leadsinfo.Phone && leadsinfo.Address) && <>
                    <div className='kitdetails'>

                      {leadsinfo.Phone && <>
                        <div className='leadinforhead'>
                          <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Phone Number  :  </h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>   {leadsinfo.Phone} </h3>
                        </div>
                      </>}
                      {leadsinfo.Address && <>
                        <div className='leadinforhead'>
                          <h3 style={{ display: "flex", color: '#4180b7', alignItems: 'center' }}> <FaRegUser style={{ width: '20px', height: '20px', margin: "0 10px" }} /> Address : </h3><h3 style={{ display: "flex", color: '#6E4E9F', alignItems: 'center' }}>   {leadsinfo.Address} </h3>
                        </div>
                      </>}
                    </div>

                  </>} */}





                  <div className='uperprofile'>

                    <div className='profilepicture'>
                      <Image alt='avatar' src={practitionerselected.profilepic} width={"300px"} />


                    </div>
                    <div className='profileabot'>

                      <div ><h3 style={{ width: '200px' }}>Name : </h3> <p style={{ margin: '0' }}> {practitionerselected.name ? (<>{practitionerselected.name}</>) : (<a href='#'>not available</a>)}</p></div>
                      <div ><h3 style={{ width: '200px' }}>Email : </h3> <p style={{ margin: '0' }}> {practitionerselected.email ? (<>{practitionerselected.email}</>) : (<a href='#'>not available</a>)}</p></div>
                      <div ><h3 style={{ width: '200px' }}>phone : </h3> <p style={{ margin: '0' }}> {practitionerselected.phone ? (<>{practitionerselected.phone}</>) : (<a href='#'>not available</a>)}</p></div>
                      <div ><h3 style={{ width: '200px' }}>address : </h3> <p style={{ margin: '0' }}> {practitionerselected.address ? (<>{practitionerselected.address}</>) : (<a href='#'>not available</a>)}</p></div>
                      <div ><h3 style={{ width: '200px' }}>billing post code : </h3> <p style={{ margin: '0' }}> {practitionerselected.billingpostcode ? (<>{practitionerselected.billingpostcode}</>) : (<a href='#'>not available</a>)}</p></div>
                      <div ><h3 style={{ width: '200px' }}>Registered On :   </h3> <p style={{ margin: '0' }}> {practitionerselected.timestamp ? (<>{practitionerselected.timestamp}</>) : (<a href='#'>not available</a>)}</p></div>






                      <div style={{ width: '100%', margin: '30px 0' }}>
                        <h2>About Me</h2>
                        <p style={{ width: '100%', margin: '0' }}>{practitionerselected.about ? (<>{practitionerselected.about}</>) : (<a href='#'>not available</a>)}</p>
                      </div>






                    </div>

                  </div>



                  <div style={{ display: 'flex', justifyContent: 'flex-end', width: '98.5%', marginTop: '30px' }}>

                    <Popconfirm
                      title="Delete this Lead"
                      description="Are you sure to delete this Lead?"
                      onConfirm={async (e) => {
                        e.preventDefault()



                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                        var urlencoded = new URLSearchParams();
                        urlencoded.append("_id", leadsinfo._id);

                        var requestOptions = {
                          method: 'POST',
                          headers: myHeaders,
                          body: urlencoded,
                          redirect: 'follow'
                        };

                        await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/dltleas", requestOptions)
                          .then(response => response.json())
                          .then(result => {
                            setleads(result)

                          })
                          .catch(error => console.log('error', error));



                        setpopup(false)
                      }}

                      okText="Yes"
                      cancelText="No"
                    >

                      <a onClick={async (e) => {
                        e.preventDefault()


                      }} href='/' style={{ display: 'flex', marginRight: '10px', alignItems: "center", color: 'red' }}> <AiFillDelete style={{ width: '20px', height: '20px' }} />Remove this Lead</a>

                    </Popconfirm>
                  </div>
















                </div>
                {(leadsinfo.logs && leadsinfo.logs.length > 0) ? (<>
                  <div className='header' style={{ marginTop: '20px' }}>
                    <h3>Lead Logs</h3>
                  </div>
                  <table className='tablep' cellPadding={"10"} cellSpacing={"0"} >

                    <thead className='tablephead'>
                      <tr>
                        <th>Action</th>

                        <th>Subject</th>
                        <th>Date and Time </th>




                      </tr>
                    </thead>
                    <tbody>
                      {leadsinfo.logs.map((value) => {


                        return (<>
                          <tr>

                            <td>{value.action}</td>
                            <td>{value.subject}</td>
                            <td>{value.date} {value.time}</td>

                          </tr>
                        </>)
                      })}
                    </tbody>
                  </table>

                </>) : (<><p>No Logs</p></>)}

                <div className='bownpopupbutton'>
                  <button onClick={() => {
                    setpopup(false)

                  }
                  } style={{ border: '1px solid red', color: 'black' }} >Close</button>






                </div>

              </div>
            </>}


















            <h2 style={{ color: '#4180B7' }}> Converted Leads Within the Last 7 Days </h2>
            <div className='imp'>


              <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ marginTop: '20px' }}>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>

                    <th>Name</th>
                    <th>Email </th>

                    <th>Source</th>


                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>




                  {leads.map((value) => {
                    let show = false
                    const today = new Date(); // Get the current date
                    const sevenDaysAgo = new Date(today);
                    sevenDaysAgo.setDate(today.getDate() - 7); // Calculate the date 7 days ago

                    // Check if there's a practitioner with the matching email and recent timestamp
                    const practitionerWithMatch = practitioner.find(prac => {
                      return prac.email.toLowerCase() === value.Email.toLowerCase() &&
                        new Date(prac.timestamp) >= sevenDaysAgo &&
                        new Date(prac.timestamp) <= today;
                    });

                    if (practitionerWithMatch) {
                      show = true; // Set 'show' to true if a practitioner matches both conditions
                    }




                    return (<>
                      {show && <>
                        <tr>

                          <td>{sno2++}</td>
                          <td>{value.Name}</td>
                          <td>{value.Email}</td>

                          <td>{value.src}</td>



                          <td style={{ display: "flex", gap: '20px', justifyContent: 'center', height: '100%' }}>
                            <button className='button'
                              onClick={() => {
                                setpopup(true)
                                setleadsinfo(value)
                                setpractitionerselected(practitionerWithMatch)

                              }}

                            > Details</button>



                          </td>


                        </tr>

                      </>}

                    </>)
                  })}












                </tbody>
              </table>
            </div>



            <h2 style={{ color: '#4180B7' }}>Converted Leads Between 7 Days and 30 Days  </h2>
            <div className='imp'>


              <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ marginTop: '20px' }}>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>

                    <th>Name</th>
                    <th>Email </th>

                    <th>Source</th>


                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>




                  {leads.map((value) => {
                    let show = false
                    const today = new Date(); // Get the current date
                    const sevenDaysAgo = new Date(today);
                    sevenDaysAgo.setDate(today.getDate() - 7); // Calculate the date 7 days ago
                    const thirtyDaysAgo = new Date(today);
                    thirtyDaysAgo.setDate(today.getDate() - 30); // Calculate the date 30 days ago

                    // Check if there's a practitioner with the matching email and a timestamp between 7 to 30 days ago
                    const practitionerWithMatch = practitioner.find(prac => {
                      const timestampDate = new Date(prac.timestamp); // Assuming 'timestamp' is in a valid date format
                      return (
                        prac.email.toLowerCase() === value.Email.toLowerCase() &&
                        timestampDate >= thirtyDaysAgo &&
                        timestampDate < sevenDaysAgo
                      );
                    });

                    if (practitionerWithMatch) {
                      show = true; // Set 'show' to true if a practitioner matches both conditions
                    }



                    return (<>
                      {show && <>
                        <tr>

                          <td>{sno2a++}</td>
                          <td>{value.Name}</td>
                          <td>{value.Email}</td>

                          <td>{value.src}</td>



                          <td style={{ display: "flex", gap: '20px', justifyContent: 'center', height: '100%' }}>
                            <button className='button'
                              onClick={() => {
                                setpopup(true)
                                setleadsinfo(value)

                              }}

                            > Details</button>



                          </td>


                        </tr>

                      </>}

                    </>)
                  })}












                </tbody>
              </table>
            </div>



            <h2 style={{ color: '#4180B7' }}>Converted Leads Between 30 Days and 3 Months  </h2>
            <div className='imp'>


              <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ marginTop: '20px' }}>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>

                    <th>Name</th>
                    <th>Email </th>

                    <th>Source</th>


                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>




                  {leads.map((value) => {
                    let show = false
                    const today = new Date(); // Get the current date
                    const thirtyDaysAgo = new Date(today);
                    thirtyDaysAgo.setDate(today.getDate() - 30); // Calculate the date 30 days ago
                    const threeMonthsAgo = new Date(today);
                    threeMonthsAgo.setMonth(today.getMonth() - 3); // Calculate the date 3 months ago

                    // Check if there's a practitioner with the matching email and a timestamp between 30 days to 3 months ago
                    const practitionerWithMatch = practitioner.find(prac => {
                      const timestampDate = new Date(prac.timestamp); // Assuming 'timestamp' is in a valid date format
                      return (
                        prac.email.toLowerCase() === value.Email.toLowerCase() &&
                        timestampDate >= threeMonthsAgo &&
                        timestampDate < thirtyDaysAgo
                      );
                    });

                    if (practitionerWithMatch) {
                      show = true; // Set 'show' to true if a practitioner matches both conditions
                    }



                    return (<>
                      {show && <>
                        <tr>

                          <td>{sno2b++}</td>
                          <td>{value.Name}</td>
                          <td>{value.Email}</td>

                          <td>{value.src}</td>



                          <td style={{ display: "flex", gap: '20px', justifyContent: 'center', height: '100%' }}>
                            <button className='button'
                              onClick={() => {
                                setpopup(true)
                                setleadsinfo(value)

                              }}

                            > Details</button>



                          </td>


                        </tr>

                      </>}

                    </>)
                  })}












                </tbody>
              </table>
            </div>



            <h2 style={{ color: '#4180B7' }}>Converted  Leads Older Than 3 Months  </h2>
            <div className='imp'>


              <table className='tablep' cellPadding={"10"} cellSpacing={"0"} style={{ marginTop: '20px' }}>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>

                    <th>Name</th>
                    <th>Email </th>

                    <th>Source</th>


                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>




                  {leads.map((value) => {
                    let show = false
                    const today = new Date(); // Get the current date
                    const threeMonthsAgo = new Date(today);
                    threeMonthsAgo.setMonth(today.getMonth() - 3); // Calculate the date 3 months ago

                    // Check if there's a practitioner with the matching email and a timestamp older than 3 months
                    const practitionerWithMatch = practitioner.find(prac => {
                      const timestampDate = new Date(prac.timestamp); // Assuming 'timestamp' is in a valid date format
                      return (
                        prac.email.toLowerCase() === value.Email.toLowerCase() &&
                        timestampDate < threeMonthsAgo
                      );
                    });

                    if (practitionerWithMatch) {
                      show = true; // Set 'show' to true if a practitioner matches both conditions
                    }




                    return (<>
                      {show && <>
                        <tr>

                          <td>{sno2c++}</td>
                          <td>{value.Name}</td>
                          <td>{value.Email}</td>

                          <td>{value.src}</td>



                          <td style={{ display: "flex", gap: '20px', justifyContent: 'center', height: '100%' }}>
                            <button className='button'
                              onClick={() => {
                                setpopup(true)
                                setleadsinfo(value)

                              }}

                            > Details</button>



                          </td>


                        </tr>

                      </>}

                    </>)
                  })}












                </tbody>
              </table>
            </div>





          </div>

        </div>


      </>}
    </div>
  )
}

export default Leadsstate
